.all-projects {
	.all-projects__header {
		display:none;
	}
}

@media only screen and (min-width: $mobileMinWidth) and (max-width: $mobileMaxWidth) {
	.all-projects {
		height:calc(100% - 1px);
		overflow:hidden;
		padding:12px 1px 12px 12px;

		.all-projects__header {
			position:relative;
			display:inline-block;
			width:100%;
			margin-bottom:30px;
			padding:10px 0 0 0;

			h1 {
				font-size:$fontSize24;
				font-weight:normal;
				font-stretch:normal;
				font-style:normal;
				line-height:normal;
				letter-spacing:0.92px;
				text-align:center;
				color:$greyishBrownColor;
			}
		}

		.all-projects__nav {
			padding-bottom:15px;
			justify-content:space-evenly;

			.all-projects__nav__item {
				margin-right:0;
			}
		}

		.all-projects__actions {
			display:none;

			&.is-mobile {
				display:inline-block;
				text-align:left;

				.all-projects__action {
					position:absolute;
					top:50%;
					transform:translate(-50%, 0);

					&.filters {
						left:10px;
					}

					&.search {
						right:10px;
						margin-top:0;
						margin-left:0;
					}
				}
			}
		}

		.all-projects__list {
			height:calc(100vh - 60px - 80px - 50px - 90px - 60px);
			margin-top:12px;
			padding-right:12px;
		}

		.OfferIndexList {
			padding-right:12px;

			.Offer__list {
				.folder__breadcrumb {
					.folder__name {
						width:100%;

						.folder__arrow--filter-down {
							margin-left:50%;
							margin-right:5%;
						}
					}

					.folder__actions {
						display:none;
					}
				}

				.folder__line {

				}

				.folder__date, .folder__pending {
					display:none;
				}
			}
		}
	}
}