@media only screen and (min-width: $mobileMinWidth) and (max-width: $mobileMaxWidth) {
	header.header {
		margin-bottom:10px;
		padding:0 18px;

		.header__logo {
			width:auto;
			height:30px;
		}

		.header__welcome {
			display:none;
		}

		.header__dashboard {
			.header__dashboard__login, .header__separator {
				display:none;
			}

			.header__dashboard__profile {
				width:30px;
				height:30px;
				margin-right:0;
			}

			.header__dashboard__infos {
				margin-left:25px;
				margin-right:25px;
			}
		}

		.NotifierHeader {
			position:unset;

			.Notifications__Dropdown {
				width:100%;
				top:90px;

				.Notifications__Dropdown_List {
					.Notifications__Dropdown_List_Item {
						margin:0 0 12px 0;
						padding:12px;

						.Notification__title {
							width:50%;
						}

						.Notification__user {
							width:20%;

							p {
								display:none;
							}
						}
					}
				}
			}
		}
	}
}