.ProjectDetail {
	height:calc(100vh - 60px - 80px - 20px);
	overflow-y:scroll;
	overflow-x:hidden;

	@include customScrollbar();

	.ProjectDetail__header__infos {
		position:relative;
		width:70%;
		margin:0 auto;

		.ProjectDetail__title {
			width:100%;
			margin:0 0 25px 0;
			padding:0;
			font-size:$fontSize36;
			text-align:center;
			color:white;
		}

		.ProjectDetail__location_wrapper {
			display:inline-block;
			width:100%;
			vertical-align:middle;
			line-height:8px;
			text-align:center;

			.ProjectDetail__location {
				display:inline-block;
				margin:0;
				padding:0;
				color:white;
				font-size:$fontSize13;
				text-align:right;
			}
		}
	}

	.ProjectPipeline {
		margin-top:20px;
	}

	.ProjectDetail__header__separator {
		width:100%;
		height:20px;
		background-color:$bodyBackgroundColor;
	}

	.ProjectDetailTabs {
		width:100%;
		padding:10px 30px 30px 30px;
		/*
		border:$defaultBorder;
		border-radius:$defaultBorderRadius;
		*/
	}

	.CreateProjectBucket {
		display:inline-block;
		vertical-align:middle;
		width:100%;
		margin:0;
		padding:50px 30px 100px 30px;
		text-align:left;
		background-color:white;

		.Col__left {
			display:inline-block;
			vertical-align:middle;
			width:65%;

			h3 {
				margin:0 0 20px 0;
				padding:0;
				font-size:$fontSize24;
				color:$greyishBrownColor;
			}

			p {
				margin-top:40px;
				line-height:24px;
				color:$brownishGrey;
			}
		}

		.Col__right {
			display:inline-block;
			width:35%;
			text-align:right;

			.SubmitButton {
				padding:15px 30px;
				font-size:$fontSize13;
				text-transform:uppercase;

				&:hover {
					padding:14px 29px;
					border-style:solid;
					border-width:1px;
					border-color:$ubikonBlue;
					background-color:white;
					color:$ubikonBlue;
				}

				&--loading {
					@include submitButton();
					padding:14px 29px;
					border-style:solid;
					border-width:1px;
					border-color:$ubikonBlue;
					background-color:white;
					color:$ubikonBlue;
					font-size:$fontSize13;
					text-transform:uppercase;

					&:before {
						top:25%;
						left:5px;
					}
				}
			}
		}
	}

	.Offer__directory__popin {
		label, input {
			display:inline-block;
			width:100%;
		}

		input {
			margin:20px 0;
		}
	}
}

.Project__form {
	padding:30px;
}

.ProjectDetail__bucket__wrapper {
	position:relative;
	display:inline-block;

	.ProjectDetail__selected__bucket {
		display:inline-block;
		margin:0;
		padding:0;
		font-size:$fontSize13;
		color:white;
		text-align:left;

		&--opened {
			@extend .ProjectDetail__selected__bucket;

			&:after {
				display:inline-block;
				content:"^";
				margin:0 0 0 10px;
				transform:rotate(0deg);
				color:white;
			}
		}

		&--closed {
			@extend .ProjectDetail__selected__bucket;

			&:after {
				display:inline-block;
				content:"^";
				margin:0 0 0 10px;
				transform:rotate(180deg);
				color:white;
			}
		}
	}

	.ProjectDetail__bucket__list {
		position:absolute;
		padding-top:0;
		margin-top:5px;
		text-align:left;

		li {
			list-style-type:none;
			line-height:20px;

			.TextButton {
				font-size:$fontSize13;
				color:white;

				&:hover {
					color:white;
				}
			}
		}
	}
}

.Complete__project__step {
	display:inline-block;
	vertical-align:middle;
	width:100%;
	margin:0 0 50px 0;

	.Complete__title {
		display:inline-block;
		vertical-align:middle;
		width:70%;
		font-size:$fontSize24;
		line-height:38px;
		color:$brownishGrey;
	}

	.Complete__button {
		display:inline-block;
		vertical-align:middle;
		width:30%;
		text-align:right;

		.TextButton {
			padding:20px 30px;
			font-size:$fontSize13;

			&:hover {
				padding:19px 29px;
			}
		}
	}

	.Spinner {
		margin-top:0;
		padding-bottom:0;
	}
}