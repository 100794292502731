.NavSection {
	width:80%;
	margin:20px auto;

	.NavSection__title {

	}

	.NavSection__list {
		list-style-type:none;
		margin:50px 0;
		padding:0;

		.NavSection__item {
			margin:0 0 20px 0;
			cursor:pointer;

			a {
				display:inline-block;
				width:100%;
				text-decoration:none;
			}

			.NavSection__icon__wrapper {
				display:inline-block;
				width:60px;

				img {
					width:auto;
				}
			}

			.NavSection__item__icon {
				margin:0 20px 0 0;
			}

			.NavSection__item__label {
				color:$blackColor;
			}

			&--active {
				@extend .NavSection__item;

				.NavSection__item__label {
					color:$ubikonBlue;
				}
			}
		}
	}
}