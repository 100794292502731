.Carousel {
	position:relative;
	display:inline-block;
	width:100%;
	height:100%;
	overflow:hidden;

	.CarouselImage {
		display:none;
		position:absolute;
		top:0;
		bottom:0;
		left:0;
		width:100%;
		max-height:100%;
		background-repeat:no-repeat;
		background-position:center;
		background-size:cover;
		transition:all 0.8s ease;

		&--active {
			@extend .CarouselImage;
			display:block;
		}
	}

	.Carousel__nav {
		position:absolute;
		bottom:10px;
		display:inline-block;
		width:100%;
		margin:0;
		padding:0;
		text-align:center;

		.Carousel__nav__item {
			list-style-type:none;
			display:inline-block;
			width:6px;
			height:6px;
			margin:0 0 0 10px;
			border-radius:50%;
			background-color:white;
			opacity:0.5;
			cursor:pointer;

			&--active {
				@extend .Carousel__nav__item;
				opacity:1;
			}

			&:hover {
				opacity:1;
			}
		}
	}

	.Carousel__fullscreen__button {
		position:absolute;
		bottom:30px;
		right:20px;
	}

	.Carousel__fullscreen__image {
		position:relative;
		width:100%;

		img {
			width:100%;
			height:auto;
		}

		.Carousel__fullscreen__close {
			position:absolute;
			top:10px;
			right:10px;
		}
	}
}