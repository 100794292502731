.OfferIndexList {
	width:100%;
	height:calc(100vh - 60px - 80px - 51px - 115px);
	margin:25px 0 0 0;
	padding:30px 20px 30px 0;
	overflow-y:scroll;
	overflow-x:hidden;

	@include customScrollbar();

	.Offer__list {
		width:100%;
		margin:0 0 60px 0;

		.Offer__list__title {
			margin:0 0 15px 0;
			font-size:$fontSizeMedium;
			font-weight:normal;
			letter-spacing:1.39px;
			color:$greyishBrownColor;
		}
	}
}