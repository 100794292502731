.ProjectDocuments {
	.ProjectDocuments__tasks_list {
		ul {
			list-style-type:none;

			li {
				p {
					margin:0 0 5px 0;

					&.status {
						font-size:0.8em;
					}
				}
			}
		}
	}

	.ProjectDocument {
		display:flex;
		width:100%;

		.ProjectDocument__details {
			width:80%;
		}

		.ProjectDocument__actions {
			width:20%;
		}
	}
}