* {
	box-sizing:border-box;
}

html {
	height:100%;
	margin:0;
	padding:0;
	background-color:$bodyBackgroundColor;
}

body {
	overflow:hidden;
	height:100%;
	font-family:$fontFamilyIBMPlexSans;
	background-color:$bodyBackgroundColor !important;
}

a, h1, h2, h3, h4, h5 {
	margin:0;
	padding:0;
	font-weight:initial;
}

#root {
	height:100%;
}

.light-blue {
	color:$ubikonLightBlue;
}

.blue {
	color:$ubikonBlue;
}

.black {
	color:$blackColor;
}

.light-gray {
	color:$lightGray;
}

.medium-gray {
	color:$mediumGray;
}

.upper {
	text-transform:uppercase;
}

.small {
	font-size:$fontSizeSmall;
}

.medium {
	font-size:$fontSizeMedium;
}

.big {
	font-size:$fontSizeBig;
}

.float-right {
	float:right;
}

.no-margin {
	margin:0;
}

.no-margin-top {
	margin-top:0;
}

.no-margin-bottom {
	margin-bottom:0;
}