.Popin__overlay {
	display:none;
	position:fixed;
	top:0;
	left:0;
	right:0;
	bottom:0;
	width:100%;
	height:100%;
	background-color:$popinBackgroundColor;
	overflow:hidden;
	z-index:9;
	cursor:pointer;

	&--visible {
		@extend .Popin__overlay;
		display:block;
	}

	.Popin__center {
		position:absolute;
		width:$popinWidth;
		padding:30px;
		background-color:white;
		top:50%;
		left:50%;
		transform:translate(-50%, -50%);
		max-height:80vh;
		overflow-y:scroll;
		border:$defaultBorder;
		border-radius:15px;
		cursor:auto;

		&::-webkit-scrollbar {
			display: none;
		}

		&.fourty {
			width:40%;
		}

		&.Preview__popin {
			min-height:250px;

			.Preview__missing {
				width:100%;
				padding-top:30px;
				text-align:center;

				p {
					width:100%;
					color:$greyishBrownColor;
				}

				.Preview__download {
					display:inline-block;
					width:100%;
					margin-top:35px;

					.TextButton {
						font-size:$fontSizeMedium;

						&:hover {
							color:$ubikonBlue;
						}
					}
				}
			}

			.Preview__image {
				width:100%;
				height:auto;
			}

			.Preview__pdf {
				position:relative;
				max-height:450px;

				canvas {
					margin-top:15px;
				}

				.mgrpdf-navigation__controls--wrapper {
					position:absolute;
					top:0;
					left:0;
					padding:0 0 15px 0;
					border-style:dashed;
					border-width:0 0 1px 0;
					border-color:$ubikonLightBlue;
					color:$ubikonBlue !important;
					background-color:white !important;
					z-index:9;

					.mgrpdf-navigation__controls--pages {
						letter-spacing:2px;
					}

					.mgrpdf-navigation__controls--previous, .mgrpdf-navigation__controls--next {
						&:hover {
							color:$ubikonLightBlue !important;
						}
					}
				}
			}
		}

		.Close__btn {
			position:absolute;
			top:40px;
			right:40px;

			&:hover {
				svg {
					g {
						fill:$greyishBrownColor;
					}
				}
			}

			svg {
				g {
					fill:$brownishGrey;
				}
			}
		}

		.Popin__title {
			padding:5px 20px 10px 0;
			width:100%;
			margin:0;
			font-size:$fontSizeBig;
			text-align:center;

			h4 {
				margin:0;
				padding:0;
			}
		}

		.Popin__content {
			max-height:calc(100vh - 250px);
			margin:$defaultMargin 0 0 0;
			padding:$defaultPadding 30px $defaultPadding $defaultPadding;
			overflow-y:scroll;
			overflow-x:hidden;

			@include customScrollbar();
		}
	}
}