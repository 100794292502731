.app {
	height:calc(100vh - 60px);
	overflow-y:scroll;
	overflow-x:hidden;

	@include customScrollbar();
}

.middle-block {
	width:calc(100% - 580px);
}

section.middle-block {
	
}

header.header {
	&.hidden {
		z-index:1;
	}

	.header__welcome {
		span {
			color:$electric-blue;
		}
	}
}

.header__logo {
	cursor:pointer;
}

.x-hellosign-embedded--in-modal {
	z-index:99999;
}

.middle-block.App__content__center.full-width.no-padding.no-margin {
	width:100% !important;
}

.App__content__center {
	position:relative;
}

.App__content__center--large {
	width:calc(100% - 275px);
}

.CalendarDay {
	vertical-align:middle !important;
}

.ProjectSection--offers {
	min-height:250px;
}

.Planning__placeholder {
	width:100%;
}

.folder__group {
	cursor:pointer;
}

.folder__group .folder__title {
	margin-right:8px;

	svg {
		margin-right:8px;

		&.is-opened {
			transform:rotate(90deg);

			.Arrow__small {
				stroke:$ubikonBlue;
			}
		}
	}
}

.folder__line .folder__name,
.folder__line .folder__date,
.folder__line .folder__pending,
.folder__line .folder__refused,
.folder__line .folder__interested {
	cursor:pointer;
}

.folder__line.is-empty p {
	width:100%;
	text-align:center;
}

.folder__line {
	.folder__name {
		display:inline-block;
		vertical-align:middle;

		.folder__file__icon {
			display:inline-block;
			vertical-align:middle;
			margin-right:8px;
		}
	}

	&.is-empty {
		&:hover {
			box-shadow:none;
			cursor:default;
		}
	}

	&:hover {
		box-shadow:0 4px 11px 0 rgba(0, 75, 255, 0.1);
		cursor:pointer;

		.folder__name {
			.folder__file__icon {
				g {
					stroke:$ubikonBlue;
				}
			}
		}
	}
}

.folder__actions__download {
	&:hover {
		svg {
			polygon {
				fill:$ubikonBlue;
			}

			#Line, #Line-2, #Line-Copy {
				stroke:$ubikonBlue;
			}
		}
	}
}

.app__decorator {
	z-index:3 !important;

	&.app__decorator--top, &.app__decorator--left, &.app__decorator--right {
		z-index:5 !important;
	}

	&.app__decorator--sidebar-left, &.app__decorator--middleblock-left {
		z-index:2 !important;
		left:280px;
	}

	&.app__decorator--middleblock-left {
		left:304px;
	}

	&.app__decorator--sidebar-right {
		right:335px;
	}

	&.app__decorator--middleblock-right {
		right:310px;
	}
}

.App.is-collapsed {
	.app__decorator.app__decorator--sidebar-left {
		left:130px;
	}

	.app__decorator.app__decorator--middleblock-left {
		left:157px;
	}

	.app__decorator--middleblock-right {
		right:310px;
	}

	.App__content__center--large {
		width:calc(100% - 129px);
	}

	section.middle-block {
		width:calc(100% - 435px);
		height:calc(100vh - 60px - 80px - 20px);

		&.App__content__center--large {
			width:calc(100% - 129px);
		}
	}

	.LeftMenu__collapse__btn {
		width:auto;
	}
}

.catalogue {
	height:100%;

	.Lands__empty__list {
		display:inline-block;
		width:100%;
		margin-top:100px;
		text-align:center;

		p {
			margin:25px 0;
			color:$brownGrey;
		}
	}
}

.catalogue__header__filter {
	position:relative;

	.FilterButton__button {
		width:100%;
		height:100%;
		border:none;
		font-size:$fontSize13;

		&:hover {
			font-size:$fontSize13;
		}
	}

	.FilterButton__content {
		top:calc(100% + 15px);
		padding:50px 20px;

		&.bedrooms {
			min-width:310px;
		}
	}
}

.catalogue__header__filters {
	.catalogue__header__filter--icon {
		&:hover, &.is-selected {
			svg {
				path {
					fill:$ubikonBlue;
				}

				.icon__vertical__line {
					stroke:$ubikonBlue;
				}
			}
		}
	}
}

.catalogue__wrapper {
	height:calc(100vh - 60px - 80px - 50px - 66px - 55px);
	overflow-y:scroll;
	overflow-x:hidden;

	@include customScrollbar();
}

.catalogue__property__description {
	cursor:pointer;
}

.catalogue__map {
	height:100%;
	overflow:hidden;
}

.catalogue__properties {
	width:calc(55% - 18px);
	height:100%;
	overflow-y:scroll;
	overflow-x:hidden;
	padding-right:10px;

	@include customScrollbar();
}

.catalogue__map {
	width:calc(45% - 18px);
}

.catalogue__properties__nav__page {
	&:hover {
		color:$ubikonBlue;
	}
}

.catalogue__header__search__input {
	outline:0;
}

.FilterMultiBox {
	margin:0 0 30px 0;
}

.Apply__btn__wrapper {
	width:100%;
	text-align:center;

	.TextButton {
		padding:20px 30px;

		&:hover {
			padding:19px 29px;
		}
	}
}

.LandDetail__wrapper {
	.LandDetail__infos {
		.catalogue__property__attribute {
			padding:13px 40px;
			font-size:$fontSize13;
			letter-spacing:0.5px;
			color:$greyishBrownColor;
		}
	}
}

.partner__profile__tasks {
	display:inline-block;
}

.folder__actions__download {
	margin-right:15px;
}

.TaskFileFolder {
	margin:0 0 50px 0;
}

.project__bg {
	&.default__project__bg {
		background-image:url('assets/png/default-project-background.jpg');
	}
}

.project__head__back, .project__head__edit {
	z-index:1;
	cursor:pointer;
}

.project__steps__buttons {
	z-index:1;

	button {
		cursor:pointer;
	}
}

.project {
	min-height:308px;
}

.project__bg {
	background-position:center;
	background-size:cover;
	background-repeat:no-repeat;

	&.has-image {
		opacity:0.1;
	}
}

.nav__item {
	&.nav__item__project {
		display:inline-block;
		color:$greyishBrownColor;

		.nav__item__text, .nav__item__location {
			display:inline-block;
			width:100%;
			margin-left:0;
			font-size:$fontSize12;
		}

		.nav__item__location {
			margin-top:2px;
			color:$brownGrey;
		}

		.nav__item__status {
			margin-top:2px;
			color:$redOrange;
			font-size:$fontSizeSmall;
			font-style:italic;
		}
	}
}

.nav__item.is-active:after {
	z-index:4;
}

.nav__item:hover {
	color:$ubikonBlue;
}

.nav__item.is-active, .nav__item:hover {
	.nav__item__icon g {
		fill:$ubikonBlue;
	}

	.nav__item__icon--organization g {
		fill:white;
	}
}

.nav__item.is-active, .nav__item:hover {
	.nav__item__icon--project {
		g {
			fill:$redOrange;
		}
	}

	.nav__item__icon--foncier, .nav__item__icon--properties {
		path {
			fill:$redOrange;
		}
	}

	.nav__item__icon--collection {
		path {
			stroke:$redOrange;
		}
	}

	.nav__item__icon--organization {
		path {
			stroke:$redOrange;
		}
	}

	.nav__item__icon--administration {
		path {
			stroke:$redOrange;
		}
	}
}

.catalogue__property__like__icon {
	&:hover, &.is-selected {
		stroke:$redOrange;
		fill:$redOrange;
	}
}

.project-views__header__nav {
	padding:23px 0;
	width:80%;
	padding-right:25px;
	overflow-y:scroll;
	overflow-y:hidden;

	@include customScrollbar();
}

.project-views__header__nav__item {
	&.is-active, &:hover {
		.project-views__header__nav__item__stroke {
			path:first-child, path:nth-child(3) {
				stroke:$ubikonBlue;
			}
		}
	}

	&:hover {
		color:$ubikonBlue;

		.project-views__header__nav__item__stroke {
			path:nth-child(2), path:nth-child(4) {
				stroke:$ubikonBlue;
			}
		}
	}

	svg {
		min-width:15px;
	}
}

.project-views__header__nav__item.is-active:after {
	width:calc(100% - 25px);
}

.text--blue-carolina {
	&:hover {
		color:$ubikonBlue;
	}
}

.profile.has-texted .profile__picture:after {
    content:'';
    position:absolute;
    top:0;
    right:-15px;
    width:6px;
    height:6px;
    background:#f63c2c;
}

.profile.is-active .profile__picture:after {
	content:none;
}

.profile.is-active {
	.profile__name {
		color:$ubikonBlue;
	}

	.group__icon {
		svg g {
			fill:$ubikonBlue;
		}
	}
}

.profile:hover {
	.profile__name {
		color:$ubikonBlue;
	}

	.group__icon {
		svg g {
			fill:$ubikonBlue;
		}
	}
}

.profile .profile__picture {
	@include avatar(29px);
}

.all-projects {
	height:100%;
}

.all-projects__list {
	height:calc(100vh - 60px - 80px - 50px - 90px - 71px - 30px);
	padding-right:35px;
	overflow-y:scroll;
	overflow-x:hidden;

	@include customScrollbar();

	&:after {
		content:'';
		min-width:30%;
	}
}

.search__filters {
	&:hover {
		.icon__filters__square {
			fill:$ubikonBlue;
		}

		.icon__filters__line {
			stroke:$ubikonBlue;
		}
	}
}

input[type="text"], input[type="email"], input[type="password"] {
	outline:none;
}

.all-projects__project__infos {
	position:relative;
}

.all-projects__project {
	&:hover {
		box-shadow:0 4px 11px 0 rgba(0, 75, 255, 0.1);
	}

	&.is-pending {
		.all-projects__project__title {
			margin-top:40px;
		}

		.all-projects__project__type {
			position:absolute;
			top:0;
			left:0;
			padding:15px 20px;
			color:white !important;
			background-color:$redOrange;

			svg {
				stroke:white !important;
			}
		}
	}
}

.catalogue__property {
	cursor:pointer;

	&.is-pending {
		.catalogue__property__price {
			display:none;
		}

		.catalogue__property__description {
			opacity:0.5;
		}

		.all-projects__project__type {
			svg {
				stroke:white;
			}
		}
	}

	.all-projects__project__type {
		position:absolute;
		top:0;
		left:0;
		padding:15px;
		background-color:$redOrange;
		color:white;
	}
}

.all-projects__create {
	min-height:250px;
}

.folder__profile, .header__dashboard__profile {
	background-image:url('assets/svg/default-profile.svg');
}

.header__dashboard__infos {
	vertical-align:middle;

	svg {
		margin-top:15px;
	}

	.header__dashboard__infos__links {
		display:inline-block;
		vertical-align:middle;
		width:0;
		overflow:hidden;
		transition:all 0.8s ease;

		&.expanded {
			width:auto;
		}

		li {
			display:inline-block;
			vertical-align:middle;
			width:100%;
			margin:0 0 5px 0;
			list-style-type:none;

			a {
				font-size:$fontSize13;
				text-decoration:none;
				text-transform:uppercase;
				color:$carolinaBlue;

				&:hover {
					color:$ubikonBlue;
				}
			}

			&:last-child {
				margin-bottom:0;
			}
		}
	}
}

.all-projects__actions {
	display:inline-block;
	vertical-align:middle;
	width:100%;
	text-align:right;

	.all-projects__action {
		display:inline-block;
		vertical-align:middle;

		.action__search {
			&:hover {
				svg {
					path {
						fill:$ubikonBlue;
					}
				}
			}
		}

		.action__filters {
			&:hover {
				svg {
					#Fill-13 {
						fill:$ubikonBlue;
						fill-rule:nonzero;
					}
				}
			}
		}
	}

	.SearchBar {
		vertical-align:middle;
		width:0;
		opacity:0;
		overflow:hidden;
		transition:all 0.8s ease;

		&.visible {
			width:80%;
			margin-left:5%;
			opacity:1;
		}

		.catalogue__header__search__input {
			top:-4px;
		}

		.Search__cancel {
			transform:translate(0, -30%);
		}
	}
}

.project-views__header {

}

.project-views__header__inner {
	.project-views__header__button {
		margin-right:5px;

		&.btn {
			padding:0 29px 0 30px;
			white-space:nowrap;
		}
	}
}

.catalogue__header {
	&.collections {
		padding-bottom:30px;
	}
}

.catalogue__header__infos {
	width:70%;
	margin-top:-10px;

	&.properties {
		width:55%;
	}
}

.left-sidebar {
	width:250px;
	overflow-y:scroll;
	overflow-x:hidden;
	background-color:white;

	@include customScrollbar();

	.left-sidebar__button {
		width:100%;
		height:auto;
		margin:20px 0;
		padding:20px;

		&:hover {
			background-color:$ubikonBlue;
			color:white;
		}
	}

	.LeftMenu__collapse__btn {
		width:100%;
		margin:20px auto;
	}
}

.right-sidebar {
	width:280px;
}

.custom-checkbox__text {
	&:hover {
		color:$greyishBrownColor;
	}
}

.Chat {
	.search__items {
		.search__field {
			height:auto;

		    &:hover {
		    	border-color:$carolinaBlue;
		    }

			.SearchBar {
				padding-bottom:5px;

				.Search__cancel {
					transform:translate(0, -30%);
				}
			}
		}
	}

	.search__create {
		svg {
			&:hover {
				#Oval {
					stroke:$ubikonBlue;
				}

				#Fill-1, #Fill-2 {
					fill:$ubikonBlue;
				}
			}
		}
	}
}

.all-projects__nav__item {
	&:hover {
		color:$ubikonBlue;

		.all-projects__nav__item__infos {
			color:$carolinaBlue;
		}
	}

	&.is-active {
		.all-projects__nav__item__infos {
			color:$carolinaBlue;
		}
	}

	.all-projects__nav__item__infos__empty {
		display:inline-block;
		width:30px;
		height:1px;
	}
}

.header__dashboard__name {
	&:hover {
		color:$greyishBrownColor;
	}
}

.catalogue__header__infos {
	.catalogue__header__title {
		padding-top:10px;
	}
}