.PropertyDetail {
	padding:35px;

	.LandDetail__infos {
		h1 {
			position:relative;

			.catalogue__property__like {
				position:absolute;
				top:0;
				right:0;
				padding:0;
				margin:0;
			}
		}
	}
}

.ContactSellerForm {
	display:inline-block;
	vertical-align:top;
	width:100%;

	.Col__left, .Col__right {
		display:inline-block;
		vertical-align:top;
	}

	.Col__left {
		width:25%;

		h3 {
			margin:0 0 15px 0;
			font-size:$fontSizeBig;
			color:$brownishGrey;
		}

		.Property__detail {
			margin:0 0 15px 0;
			font-size:$fontSizeMedium;
			color:$greyishBrownColor;

			&.price {
				color:$carolinaBlue;
			}
		}
	}

	.Col__right {
		width:75%;

		.TextButton {
			margin-left:30px;
			color:$brownGrey;

			&:hover {
				color:$brownishGrey;
			}
		}

		.Contact__success {
			margin-top:20px;
			font-size:$fontSizeMedium;
			color:$brownishGrey;
			text-align:left;

			.TextButton {
				margin-left:0;
			}
		}
	}
}