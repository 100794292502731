.ProjectDashboardBox {
	display:inline-block;
	width:100%;
	margin-bottom:$marginTopBig;
	padding:20px;
	background-color:$projectDetailBoxBackgroundColor;

	&:last-child {
		margin-bottom:0;
	}

	&.notification-active {
		box-shadow:0 5px 7px $mediumGray;
	}

	.ProjectDashboardBox__header {
		display:inline-block;
		width:100%;
		margin:0 0 10px 0;

		h3 {
			display:inline-block;
			margin:0;
			padding:0;
			text-transform:uppercase;
			font-size:$fontSizeMedium;

			.count {
				position:relative;
				margin:0 0 0 20px;
				font-style:italic;
				color:$mediumGray;

				&:before {
					content:'.';
					position:absolute;
					left:-20px;
					top:0;
					width:10px;
					height:10px;
					margin:0 5px;
					border-style:solid;
					border-width:0 0 1px 0;
					border-color:$mediumGray;
					color:transparent;
				}
			}
		}

		button {
			margin:0 10px 0 0;

			&:last-child {
				margin-right:0;
			}

			&.TextButton {
				vertical-align:top;
				margin-top:5px;
				color:$ubikonBlue;
			}
		}

		.TaskBox__date__alert {
			display:inline-block;
			margin:0 20px;
			font-size:$fontSize13;
			font-weight:normal;
			font-stretch:normal;
			font-style:normal;
			line-height:normal;
			letter-spacing:0.59px;
			color:$redOrange;
		}

		.TaskBox__Partners__List {
			display:inline-block;
			margin:0 0 0 20px;

			span {
				color:$mediumGray;
				margin:0 5px 0 0;
			}

			.Taskbox__Partner__Avatar {
				@include avatar(25px);
				display:inline-block;
				margin:0 0 0 5px;
				vertical-align:middle;
			}
		}
	}

	.ProjectDashboardBox__content {
		width:100%;

		.Task__description {
			width:75%;
			margin:0 0 20px 0;

			h4 {
				margin:10px 0 10px 0;
				text-transform:uppercase;
				font-size:$fontSize12;
				color:$brownishGrey;
			}

			p {
				margin:0 0 10px 0;
				font-size:$fontSize12;
			}
		}

		.Task__FilesList {
			display:inline-block;
			width:100%;
			padding:0;
			margin:0 0 30px 0;

			.Task__FilesList__Item {
				display:inline-block;
				width:100%;
				list-style-type:none;

				.filename {
					font-size:$fontSizeMedium;
				}

				.done {
					font-size:$fontSizeSmall;
					font-style:italic;
				}
			}
		}

		.Task__File__input {
			.FileInput {
				display:none;
			}
		}

		.TaskFile__Upload__Input {
			@include submitButton();
		}

		.UploadMessage {
			display:inline-block;
			margin:30px 0 0 0;

			&.success {
				color:$ubikonBlue;
			}

			&.error {
				color:$redOrange;
			}
		}

		h4.Approve__title {
			width:100%;
			margin:20px 0;
		}
	}
}