.ImageButton {
	@include buttons();
	position:relative;

	img {
		width:100%;
	}

	&.has-notifications {
		&:after {
			content:'';
			position:absolute;
			width:6px;
			height:6px;
			margin:0 0 0 5px;
			background-color:$ubikonOrange;
		}
	}
}