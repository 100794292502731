.CollectionsIndex {
	.all-projects__create {
		min-height:315px;
		max-height:375px;
	}

	.all-projects__project {
		max-height:375px;

		.all-projects__project__picture {
			&.is-empty {
				background-image:url('assets/png/collection-default-background.png');
				opacity:0.4;
			}
		}
	}

	.all-projects__project__title {
		margin-bottom:10px;
		padding-bottom:0;
	}

	.all-projects__list {
		.Spinner {
			padding-bottom:0;
		}
	}
}

.all-projects__project__location.collection {
	display:inline-block;
	vertical-align:middle;
	margin-right:20px;
	margin-bottom:0;

	.collection__infos__icon {
		vertical-align:middle;
		width:16px;
		margin-right:10px;
	}

	&:last-child {
		margin-right:0;
	}
}

.all-projects__project__profiles.collection {
	margin-bottom:35px;

	.all-projects__project__profile {
		background-color:#ccc;
	}
}

.CollectionDetail {
	.all-projects__project__profiles.collection {
		margin-top:15px;
	}
}

.catalogue__property__like {
	cursor:pointer;
}