.ProjectPipeline {
	width:100%;
	color:white;

	&--blured {
		@extend .ProjectPipeline;
		opacity:0.05;
	}

	.project__steps__text {
		color:white;
	}

	.ProjectPipeline__list {
		position:relative;
		display:inline-block;
		padding:0;
		margin:40px 0 0 0;
		border-style:solid;
		border-width:1px 0 0 0;
		border-color:white;
		list-style-type:none;
		color:white;
		z-index:3;

		.ProjectPipeline__list_item {
			position:relative;
			display:inline-block;
			margin:10px 20px 0 20px;
			padding:$defaultPadding 0 0 0;

			&:before {
				position:absolute;
				top:-16px;
				left:calc(50% - 10px);
				content:'';
				width:10px;
				height:10px;
				border-style:solid;
				border-width:1px;
				border-color:white;
				border-radius:50%;
				font-size:4em;
				line-height:0;
				background-color:transparent;
			}

			&--disabled {
				@extend .ProjectPipeline__list_item;
			}

			.TextButton {
				padding:0 0 5px 0;
				font-size:$fontSize12;
				color:white;
				text-transform:uppercase;
			}

			&.active {
				&:before {
					background-color:white;
				}

				.TextButton {
					border-style:solid;
					border-width:0 0 1px 0;
					border-color:white;
				}
			}

			.ProjectPipeline__check {
				margin:0 0 0 10px;
				cursor:pointer;

				path {
					fill:$projectPipelineCheckIncomplete;
				}

				&--complete {
					@extend .ProjectPipeline__check;

					path {
						fill:$projectPipelineCheckComplete;
					}
				}
			}
		}
	}
}