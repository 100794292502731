.LandDetail__wrapper {
	.LandDetail__infos {
		.LandDetail__carousel {
			display:none;
		}
	}
}

@media only screen and (min-width: $mobileMinWidth) and (max-width: $mobileMaxWidth) {
	.LandDetail, .PropertyDetail {
		padding:12px;

		.LandDetail__content {
			width:100%;
			margin-left:0;
			margin-right:0;

			.LandDetail__wrapper {
				.LandDetail__infos {
					width:100%;
					margin-left:0;
					margin-right:0;
					padding:0 20px 20px 0;

					.LandDetail__owner {
						position:absolute;
						top:10px;
						right:2px;
					}

					.LandDetail__carousel {
						&.is-mobile {
							position:relative;
							display:inline-block;
							width:100%;
							max-height:35vh;
							margin-top:25px;

							.Carousel {
								position:unset;

								.Carousel__fullscreen__button {
									display:none;
								}
							}
						}
					}
				}

				.LandDetail__carousel {
					display:none;
				}
			}
		}
	}

	.Popin__content {
		.ContactSellerForm {
			.Col__left {
				width:100%;
				margin-bottom:12px;

				.LandDetail__owner {
					margin-bottom:36px;
				}
			}

			.Col__right {
				width:100%;
				padding-right:12px;
			}
		}
	}
}