.FinancialAnalysis {
	display:inline-block;
	width:100%;
	opacity:1;

	.FinancialAnalysis__intro, .FinancialAnalysis__empty {
		margin:0 0 50px 0;
		font-size:$fontSizeMedium;
		font-weight:normal;
		font-stretch:normal;
		font-style:normal;
		line-height:1.79;
		letter-spacing:0.5px;
		color:$brownishGrey;
	}

	.FinancialAnalysis__empty {
		margin:0 0 50px 0;
	}

	.FinancialAnalysis__form {
		position:relative;
		display:inline-block;
		width:100%;
		margin-top:50px;

		.UpdatingOverlay {
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			opacity:0.5;
			background-color:white;
			cursor:wait !important;
		}
	}

	.folder__line {
		&:hover {
			box-shadow:none;
			cursor:default;

			.folder__name {
				cursor:default !important;
			}
		}

		&.total {
			background-color:white;

			.folder__name {
				font-weight:600;

				&:first-child {
					text-transform:uppercase;
				}

				&.large {
					width:60%;
				}
			}
		}
	}

	.FinancialAnalysis__section {
		display:inline-block;
		width:100%;
		margin-bottom:50px;

		&:nth-child(3) {
			margin-bottom:50px;
		}

		&.incomes {
			margin-bottom:0px;

			.folders {
				margin-top:0;
			}
		}

		.Section__header {
			display:inline-block;
			width:100%;

			h3 {
				font-size:$fontSizeMedium;
				text-transform:uppercase;
				color:$greyishBrownColor;
			}
		}

		.folders {
			margin-top:15px;

			.folder__breadcrumb {

			}

			.folder__line {
				font-size:$fontSize12;
				background-color:$paleGreyColor;

				.folder__name {
					&:last-child {
						padding-right:10px;
					}
				}
			}

			.land_charges {
				.folder__name {
					&.name, &.description {
						width:45%;
					}

					&.priceTaxExcluded, &.priceTaxIncluded, &.taxRateAmount {
						width:20%;
					}
				}
			}

			.folder__name {
				&.is-editable {
					height:75%;
					cursor:text;
				}

				input {
					width:100%;
					background-color:white;

					&:hover {
						box-shadow:0 4px 11px 0 rgba(0, 75, 255, 0.1);
					}
				}
			}
		}
	}

	.AnalysisConfigForm {
		display:inline-block;
		width:100%;

		.FormRow {
			display:inline-block;
			width:50%;
			padding:0 5% 0 0;

			label.Label {
				display:inline-block;
				margin:0 0 5px 0;
				font-size:$fontSizeSmall;
				font-weight:normal;
				font-stretch:normal;
				font-style:normal;
				line-height:normal;
				letter-spacing:1.39px;
				color:$brownishGrey;
				text-transform:uppercase;
			}
		}
	}
}