
.left-sidebar {
    z-index: 2;

    height: calc(100vh - #{$header-height} -  #{$space-medium} - #{$space-medium} - #{$space-xsmall});
    border-bottom: 1px solid $pale-lilac;
}

.right-sidebar .sidebar-block__inner {
    border-bottom: 1px solid $pale-lilac;
}

/**
 * Sidebar blocks
 */
 .sidebar-block {
    width: 320px;
    // height: calc(100vh - #{$header-height} -  #{$space-medium} - #{$space-medium} - #{$space-xsmall});
    // height: calc(100vh - 80px - 30px - 30px - 20px);
}

.sidebar-block--small {
    width: 80px;
}

.sidebar-block__inner {
    width: 100%;
    padding: $space-small $space-large;
    
    background: $white;
}

.left-sidebar .sidebar-block__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 100%;
}

.left-sidebar__button {
    cursor: pointer;
    height: 54px;
    border: 2px solid $electric-blue;

    font-weight: 600;
    font-size: $text-medium;
    text-transform: uppercase;
    color: $electric-blue;
}

.sidebar-block--small__inner {
    width: 100%;
    padding: 35px 20px;
    
    background: $white;
}

.sidebar-block__title {
    text-transform: uppercase;
    font-size: $text-xlarge;
    line-height: 1.26;
}

/**
 * sidebar Offers
 */

 .sidebar-offers {
    margin-bottom: $space-large;
 }

 .sidebar-offers__title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: $space-small;
 }

 /**
 * Nav
 */

.nav__items {
    margin-top: 50px;
}

.nav__item {
    cursor: pointer;
    position: relative;

    display: flex;
    align-items: center;

    margin-bottom: $space-small;

    color: $greyish-brown;
    font-size: $text-medium;
    line-height: 1.3;
}

.nav__item.is-active {
    color: $electric-blue;
}

.nav__item.is-active:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;

    display: block;

    height: 3px;
    width: 70px;

    background-color: $orangey-red;

    transform: translate3d(80px, -1px, 0);
}

.nav__item__icon {
    fill: #737373;
}

.nav__item__icon__fill {
    fill: #737373;
}

.nav__item.is-active .nav__item__icon__fill {
    fill: #F63C2C;
}

.nav__item__icon__stroke {
    stroke: #737373;
}

.nav__item.is-active .nav__item__icon__stroke {
    stroke: #F63C2C;
}

.nav__item.is-active .nav__item__icon {
    fill: #F63C2C;
}

.nav__item__text {
    margin-left: $space-small;
}

.nav--small__items {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.nav--small__item {
    cursor: pointer;

    display: flex;
    justify-content: center;

    margin-bottom: 30px;
}

.nav--small__item svg {
    fill: $brownish-grey;
} 

.nav--small__item svg .grey-filled-path {
    fill: $brownish-grey;
}

.nav--small__item svg .grey-stroked-path {
    stroke: $brownish-grey;
}

.nav--small__item.is-active svg {
    fill: #F64535;    
}
.nav--small__item.is-active svg .grey-filled-path {
    fill: #F64535; 
}

.nav--small__item.is-active svg .grey-stroked-path {
    stroke: #F64535; 
}
/**
 * Search
 */

 .search {
    margin-top: $space-small;
 }

 .search__items {
    display: flex;
    justify-content: space-between;
    align-items: center;
 }

 .search__field {
    cursor: pointer;
    position: relative;

    width: 150px;
    height: 40px;
    border-bottom: 1px solid $pale-lilac;

    line-height: 40px;
 }

 .search__field__input {
     width: 100%;
     height: 100%;
     border: none;

     text-transform: uppercase;
 }

 .search__field__input::placeholder {
    font-size: $text-xsmall;
    letter-spacing: 0.1em;
 }

 .search__field__icon {
     cursor: pointer;
     position: absolute;
     right: 0;
     top: 50%;

     transform: translateY(-50%);
 }

 .search__filters {
    cursor: pointer;
 }

 .search__create {
    cursor: pointer;
 }


  /**
 * Suggets
 */

 .suggests {
    display: flex;
    flex-wrap: wrap;

    margin-top: $space-xsmall;
    margin-bottom: $space-small;
 }


  /**
 * Single suggest
 */

 .suggest {
    cursor: pointer;

    height: 30px;
    padding: 0 13px;
    border: 1px solid $pale-lilac;
    border-radius: 5px;

    font-size: $text-small;
    color: $brownish-grey;
    line-height: 30px;
}

.suggest {
    display: flex;
    align-items: center;

    margin-bottom: 10px;
    margin-right: 10px;
}

.suggest__item__close {
    width: 23px;
    height: 23px;
    margin-left: 10px;
}


 /**
 * Groups
 */
 .groups {
    margin-bottom: $space-large;
 }

 .groups__title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: $space-small;
 }

 /**
 * Chat
 */
 .chat {
    margin-bottom: $space-large;
 }

 .chat__title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: $space-small;
 }

  /**
 * Profile
 */

.profile {
    cursor: pointer;
    
    display: flex;
    align-items: center;

    margin-bottom: 18px;
}

.profile__picture {
    position: relative;
    width: 29px;
    height: 29px;
    border-radius: 50%;


    background-size: cover;
    background-position: 50% 50%;
}

.profile.is-active .profile__picture {
    border: 1px solid $electric-blue;
}

.profile.is-active .profile__picture:after {
    content: '';

    position: absolute;
    top: 0;
    right: -15px;

    width: 6px;
    height: 6px;

    background: $orangey-red;
} 

.profile__name {
    margin-left: 33px;

    color: $brownish-grey;
    font-size: $text-medium;
    letter-spacing: 0.05em;
}

.profile.has-texted .profile__name {
    color: $electric-blue;
}


 /**
 * Group
 */

.group {
    cursor: pointer;

    display: flex;
    align-items: center;

    margin-bottom: 18px;
}

.group__name {
    margin-left: 38px;

    color: $brownish-grey;
    font-size: $text-medium;
    letter-spacing: 0.05em;
}

.group.is-active .group__name {
    margin-left: 26px;
}

.group.is-selected .group__name {
    color: $electric-blue;
}

.group__icon__svg__inner {
    fill: $greyish-brown;
}

.group.is-selected .group__icon__svg__inner {
    fill: $electric-blue;
}