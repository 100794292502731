@mixin spinner($borderWidth: 5px, $dimension: 20px, $spinDuration: 2s, $borderColor: $lightGray, $spinnerColor: $ubikonLightBlue) {
	content:'';
	position:absolute;
	top:20%;
	left:10px;
	border:$borderWidth solid $borderColor;
	border-top:$borderWidth solid $spinnerColor;
	border-radius:50%;
	width:$dimension;
	height:$dimension;
	animation:spin $spinDuration linear infinite;
	outline:none;
}