.PropertyForm {
	display:inline-block;
	width:100%;

	.PropertyForm__left {
		display:inline-block;
		vertical-align:top;
		width:25%;
		margin-right:5%;

		.PropertyForm__progress {
			display:inline-block;
			vertical-align:middle;

			.progress__count {
				position:relative;
				width:60px;
				height:60px;
				display:inline-block;
				border-style:solid;
				border-width:1px;
				border-radius:50%;
				border-color:$brownGrey;

				.progress__blue {

				}

				.progress__label {
					position:absolute;
					top:50%;
					left:50%;
					transform:translate(-50%, -50%);
					display:inline-block;
					width:100%;
					font-size:$fontSizeMedium;
					line-height:normal;
					letter-spacing:normal;
					text-align:center;
					color:$brownishGrey;
				}
			}

			.progress__title {
				margin:20px 0 5px 0;
				font-size:$fontSize16;
				letter-spacing:0.62px;
				color:$ubikonBlue;
			}

			.progress__subtitle {
				margin:8px 0 0 0;
				font-size:12px;
				letter-spacing:0.46px;
				color:$brownishGrey;
			}
		}

		.progress__nav {
			margin-top:100px;

			button {
				width:100%;
				padding:15px 0;

				&.BlueButton {
					&:hover {
						padding:14px 0;
					}
				}

				&.SubmitButton {
					margin-bottom:15px;
				}

				&.WhiteButton {
					margin-top:15px;
				}
			}

			.progress__cancel {
				width:100%;
			}
		}
	}

	.PropertyForm__right {
		display:inline-block;
		vertical-align:top;
		width:70%;
		height:450px;
		overflow-y:scroll;

		&::-webkit-scrollbar {
			display:none;
		}
	}

	.PropertyForm__row {
		label {
			display:inline-block;
			margin:0 0 15px 0;
			font-size:12px;
			letter-spacing:1.67px;
			color:$brownishGrey;
			text-transform:uppercase;
		}

		.FormRow {
			&.text, &.number {
				input {
					padding:15px 10px;
					font-size:$fontSize15;
					letter-spacing:0.5px;
				}
			}
		}

		.RadioGroup {
			.Radio {
				padding:5px;

				&:first-child {
					padding-top:0;
				}

				input {
					display:inline-block;
					vertical-align:middle;
					margin-right:10px;
					padding:0;
				}

				label {
					display:inline-block;
					vertical-align:middle;
					width:auto;
					margin:0;
					font-size:$fontSize15;
					line-height:1.67;
					letter-spacing:0.5px;
					text-transform:initial;
				}

				.Label--required {
					&:after {
						display:none;
					}
				}
			}
		}

		&.propertyItems {
			.FormRow.checkbox-group {
				margin-bottom:0;
				padding-bottom:0;

				label {
					margin-bottom:0;
				}

				.CheckboxGroup__item {
					label {
						text-transform:none;
					}
				}
			}
		}
	}

	.PropertyFormFieldsGroup {
		border-style:solid;
		border-width:1px 0;
		border-color:$paleGrey;

		&:last-child {
			
		}

		.Group__label {
			position:relative;
			display:inline-block;
			width:100%;
			padding:10px 0;
			cursor:pointer;

			img {
				position:absolute;
				right:0;
			}
		}

		.Group__content {
			display:none;

			.FormRow {
				margin-bottom:5px;
				padding-bottom:0;

				&:first-child {
					margin-bottom:25px;
				}

				&.photos {
					margin-top:25px;
				}

				label.Label {
					display:inline-block;
					margin-bottom:8px;
					font-size:$fontSize12;
					letter-spacing:1.67px;
					color:$brownishGrey;
					text-transform:uppercase;
				}

				.CheckboxGroup {
					margin-top:0;

					.CheckboxGroup__item {
						margin-top:0;
						margin-bottom:0;
					}
				}
			}
		}

		&.is-active {
			.Group__label {
				.Group__arrow {
					transform:rotate(180deg);
				}
			}

			.Group__content {
				display:block;
				width:100%;
			}
		}
	}
}