@import './reset.scss';
@import './variables.scss';
@import './base.scss';
@import './header.scss';
@import './sidebars.scss';

/**
 * Bloc all projects
 */

.all-projects {
    padding: $space-large;

    background: $white;
}

.all-projects__nav {
    width: 100%;

    display: flex;

    padding-bottom: $space-large;
    border-bottom: 1px solid $pale-lilac;

    color: $brownish-grey;
    font-size: $text-xlarge;
}

.all-projects__nav__item {
    cursor: pointer;

    margin-right: 65px;
}

.all-projects__nav__item.is-active {
    color: $electric-blue;
}

.all-projects__nav__item__infos {
    position: relative;

    padding-left: 15px;
    margin-left: 5px;

    font-size: $text-small;
    color: $brown-grey;
    font-style: italic;
}

.all-projects__nav__item__infos:before {
    content: '';

    position: absolute;
    top: 50%;
    left: 0;

    width: 8px;
    height: 1px;

    transform: translateY(-50%);

    background: $brown-grey;
}

.all-projects__actions {
    display: flex;
    justify-content: flex-end;

    width: 100%;
    padding: $space-small 0px;
}

.all-projects__action {
    cursor: pointer;

    margin-left: 27px;
}

.all-projects__action:first-child {
    margin-left: 0;
}

.all-projects__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.all-projects__create, .all-projects__project {
    cursor: pointer;

    max-width: 345px;
    width: calc(33% - 70px / 3);
    border-radius: 15px;

    background: $pale-grey;
}

.all-projects__create {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-bottom: $space-large;
}

.all-projects__create__cross {
    position: relative;

    height: 20px;
    width: 20px;
    margin-bottom: 25px;
}

.all-projects__create__cross:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;

    height: 1px;
    width: 100%;

    background: $brownish-grey;

    transform: translateY(-50%);
    transform-origin: center center;
}

.all-projects__create__cross:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 1px;

    top: 0;
    left: 50%;

    background: $brownish-grey;

    transform: translateX(-50%);
    transform-origin: center center;
}

.all-projects__create__text {
    font-size: $text-large;
    text-transform: uppercase;
    color: $brownish-grey;
}

.all-projects__project {
    overflow: hidden;

    display: flex;
    justify-content: space-between;
    flex-direction: column;

    margin-bottom: $space-large;

    background: $pale-grey;
}

.all-projects__project__infos {
    padding: $space-medium;
} 

.all-projects__project__title {
    padding-bottom: 25px;

    font-size: $title-xsmall;
    line-height: 1.3;
    color: $greyish-brown;
}

.all-projects__project.is-pending
.all-projects__project__title {
    opacity: 0.4;
}

.all-projects__project__location, .all-projects__project__type {
    display: flex;
    align-items: center;

    font-size: $text-medium;
    color: $brownish-grey;
}

.all-projects__project.is-pending .all-projects__project__location {
    opacity: 0.4;
}

.all-projects__project__location svg,
.all-projects__project__type svg {
    stroke: $electric-blue;
}

.all-projects__project.is-pending .all-projects__project__location svg {
    stroke: $brownish-grey;
}

.all-projects__project.is-pending
.all-projects__project__type svg {
    stroke: $orangey-red;
}

.all-projects__project.is-pending
.all-projects__project__type {
    color: $orangey-red;
}


.all-projects__project__location {
    margin-bottom: 10px;
}

.all-projects__project__picture {
    height: 145px;
    width: 100%;

    background: $brownish-grey;
}

.all-projects__project__people {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-top: 35px;
}

.all-projects__project__profile--more {
    font-size: $text-small;
    color: $brown-grey;
    font-style: italic;
}

.all-projects__project__updates {
    display: flex;

    font-size: $text-small;
}

.all-projects__project__updates__notif {
    margin-left: 10px;
}

.all-projects__project__profiles {
    display: flex;
    align-items: center;
}

.all-projects__project__profile {
    position: relative;

    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 10px;


    background-size: cover;
    background-position: 50% 50%;
}

.all-projects__project__location__icon svg {
    margin-right: 10px;
}

.all-projects__project__type__icon svg {
    margin-right: 12px;
}

.all-projects__project__picture {

    background-size: cover;
    background-position: 50% 50%;
}

.all-projects__project.is-pending .all-projects__project__picture {
    opacity: 0.3;
}