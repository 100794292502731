/**
 * Header
 */
 .header {
    z-index: 4;

    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-between;
    
    height: $header-height;
    width: 100%;
    margin-bottom: $space-xsmall;
    padding: 0px $space-small;

    background: $white;
}

.header__logo {
    width: 122px;
}

.header__welcome {
    position: absolute;
    left: 50%;

    font-size: $title-xsmall;

    transform: translateX(-50%);
}

.header__dashboard {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

.header__dashboard__login {
    margin-right: 20px;
    
    font-size: $text-medium;
    line-height: 1.3;
    text-align: right;

    cursor: pointer;
}

.header__dashboard__name {
    font-weight: 600;
    letter-spacing: 0.5px;
    color: $brownish-grey;
}

.header__dashboard__signout {
    font-size: $text-xsmall;
    line-height: 1.3;
    text-transform: uppercase;

    cursor: pointer;
}

.header__dashboard__profile {
    // margin-right: 30px;
    cursor: pointer;
    position: relative;

    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 20px;


    background-size: cover;
    background-position: 50% 50%;
}

.header__separator {
    display: block;

    width: 1px;
    height: 47px;

    background: $pale-lilac;
}

.header__dashboard__infos {
    margin-left: 32px;
    margin-right: 37px;

    cursor: pointer;
}

.header__dashboard__notif {
    cursor: pointer;
}