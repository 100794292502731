.PropertyPerformance {
	display:inline-block;
	width:100%;
	margin-top:15px;

	.PropertyPerformance__content {
		display:inline-block;
		max-width:120px;
		margin:0 10px 0 0;
		padding:10px;
		border-style:solid;
		border-width:1px;
		border-color:$paleGrey;
		text-align:center;
		text-transform:uppercase;

		&:last-child {
			margin-right:0;
		}

		.PropertyPerformance__title, .PropertyPerformance__index {
			display:inline-block;
			width:100%;
		}

		.PropertyPerformance__title {
			font-size:$fontSize11;
			color:$carolinaBlue;
		}

		.PropertyPerformance__index {
			margin-top:10px;
			font-size:$fontSize24;
			color:$ubikonBlue;
		}
	}
}