.Chat {
	width:calc(100% - 1px) !important;
	height:calc(100vh - 60px - 80px - 20px);
	overflow-x:hidden;
	overflow-y:scroll;

	@include customScrollbar();

	.SearchBar {
		display:inherit;
		border:none;
		position:inherit;
		width:inherit;
		vertical-align:inherit;
		min-height:inherit;
		font-size:inherit;

		.search__field__input {
			font-size:$fontSizeSmall;
			font-weight:normal;
			font-stretch:normal;
			font-style:normal;
			line-height:normal;
			letter-spacing:1.67px;
			color:$brownishGrey;
		}
	}

	.Chat__header {
		display:inline-block;
		width:100%;
		font-size:0;

		.NavSection__title {
			display:inline-block;
			width:60%;
		}

		.Chat__header__actions {
			display:inline-block;
			width:35%;
			margin-left:5%;
			text-align:right;

			.ImageButton {
				display:inline-block;
				margin-right:20px;

				&:last-child {
					margin-right:0;
				}
			}
		}

		.Chat__header__search {
			display:inline-block;
			width:100%;
			margin:30px 0;
		}
	}

	.Chat__empty__notice {
		width:100%;
		margin:80px 0;
		text-align:center;
		font-size:$fontSize12;
		color:$brownGrey;
	}

	.Spinner {
		margin:80px 0;
		padding:0;
	}

	.ConversationsList {
		width:100%;
		padding:0;
		margin:0;

		.chat__title {
			.title--small {
				.title--small__infos {
					margin-left:5px;
				}
			}
		}

		ul {
			width:100%;
			padding:0;
			margin:20px 0 0 0;

			.ConversationListItem {
				display:flex;
				align-items:center;
				width:100%;
				padding:0;
				margin:0 0 20px 0;
				list-style-type:none;
				cursor:pointer;

				&:last-child {
					margin-bottom:0;
				}

				.ConversationListItem__avatar {
					@include avatar(30px);
					position:relative;
					display:inline-block;

					.isAlert {
						position:absolute;
						top:0;
						right:-10px;
						width:6px;
						height:6px;
						background-color:$ubikonOrange;
					}
				}

				.ConversationListItem__title {
					display:inline-block;
					width:calc(80% - 30px);
					margin-left:10%;
				}
			}
		}
	}
}