.LeftMenu {
	&.is-collapsed {
		width:100px;

		.NavSection__title, .LeftMenu__actions, .nav__item__text, .LeftMenuAdmin {
			display:none;
		}

		nav {
			padding-top:18px;
		}

		.LeftMenu__collapse__btn {
			display:block;

			button {
				transform:rotate(-90deg);
			}
		}
	}

	.nav__item__icon, .nav__item__text {
		cursor:pointer;
	}

	.LeftMenu__actions {
		text-align:left;

		.TextButton.LeftMenu__bottom {
			padding:10px;
			border-style:solid;
			border-width:1px;
			border-color:$ubikonBlue;
		}
	}

	.LeftMenu__collapse__btn {
		button {
			transform:rotate(90deg);
			width:20px;
		}
	}
}