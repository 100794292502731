.app__decorator {
	&.app__decorator--mobile-wrapper-bot, &.app__decorator--mobile-nav-top {
		display:none;
	}
}

@media only screen and (min-width: $mobileMinWidth) and (max-width: $mobileMaxWidth) {
	.app__decorator {
		&.app__decorator--top {
			top:10px;
		}

		&.app__decorator--bot {
			bottom:10px;
		}

		&.app__decorator--left {
			left:10px;
		}

		&.app__decorator--right {
			right:10px;
		}

		&.app__decorator--header {
			top:90px;
		}

		&.app__decorator--wrapper {
			top:100px;
		}

		&.app__decorator--mobile-wrapper-bot {
			bottom:90px;
		}

		&.app__decorator--mobile-nav-top {
			bottom:80px;
		}

		&.app__decorator--mobile-wrapper-bot,
		&.app__decorator--mobile-nav-top {
			display:initial;
		}

		&.app__decorator--sidebar-left,
		&.app__decorator--sidebar-right,
		&.app__decorator--middleblock-left,
		&.app__decorator--middleblock-right {
			display:none;
		}
	}
}