.TextButton {
	@include buttons();

	&.BigButton {
		padding:30px 20px;
	}

	&.has-image {
		vertical-align:middle;

		img {
			width:10px;
			vertical-align:middle;
			margin-right:10px;
		}
	}

	&.WhiteButton {
		padding:10px;
		background-color:transparent;
		border-style:solid;
		border-width:1px;
		border-color:$brownGrey;

		&:hover {
			background-color:$brownGrey;
			border-color:$brownGrey;
			color:white;
		}
	}

	&.OrangeButton {
		padding:10px;
		background-color:$ubikonOrange;
		color:white;

		&:hover {
			padding:9px;
			border-style:solid;
			border-width:1px;
			border-color:$ubikonOrange;
			color:$ubikonOrange;
			background-color:white;
		}
	}

	&.BlueButton {
		padding:10px;
		background-color:$ubikonBlue;
		color:white;

		&:hover {
			padding:9px;
			background-color:white;
			color:$ubikonBlue;
			border-style:solid;
			border-width:1px;
			border-color:$ubikonBlue;
		}

		&.disabled {
			color:$brownishGrey;
			background-color:$lightGray;
		}
	}

	&.BlueBorderButton {
		padding:9px;
		border-style:solid;
		border-width:1px;
		border-color:$ubikonBlue;
		background-color:transparent;
		color:$ubikonBlue;

		&:hover {
			background-color:$ubikonBlue;
			color:white;
		}
	}

	&.grey-block__action {
		&:hover {
			background-color:$greyishBrownColor;
			color:white;
		}
	}

	&.RedButton {
		padding:10px;
		background-color:$redOrange;
		color:white;

		&:hover {
			background-color:white;
			color:$redOrange;
		}
	}

	&.disabled {
		cursor:not-allowed;
		opacity:0.5;
	}
}

.project-views__header__button {
	&:hover {
		border-style:solid;
		border-width:1px;
		border-color:$ubikonOrange !important;
		color:$ubikonOrange;
		background-color:white;
	}
}