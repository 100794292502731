.MapList__header {
	width:100%;
	padding:50px 0 0 0;

	h2 {
		font-size:$fontSize22;
	}
}

.MapList {
	display:inline-block;
	width:calc(50% - 15px);
	height:60vh;
	overflow-y:scroll;

	&::-webkit-scrollbar {
		display:none;
	}

	.MapList__items {
		width:100%;
		display:grid;
		grid-template-columns:calc(50% - 10px) calc(50% - 10px);
		grid-gap:20px;

		.MapList__item {
			background-image:url('assets/png/default-project-background.jpg');
		}
	}
}