/**
 * HTML / BODY
 */

body {
    overflow-x: hidden;
    background: $pale-grey;
    color: #000;
    font-family: $main-font;
    font-weight: normal;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

button:focus {
    outline:0;
}

/**
 * App
 */

.app {
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding: $space-medium;

    background: $pale-grey;
}

.app__decorator {
    z-index: 3;
    position: absolute;

    display: block;

    width: 100vw;
    height: 1px;

    background: $pale-lilac;
    // background: red;
}

.app__decorator--top {
    z-index: 5;

    top: $space-medium;
    left: 0;
}

.app__decorator--left {
    z-index: 5;

    top: 0;
    left: $space-medium;

    width: 1px;
    height: 100%;
}

.app__decorator--right {
    z-index: 5;
    
    top: 0;
    right: $space-medium;

    width: 1px;
    height: 100%;
}

.app__decorator--bot {
    z-index: 1;
    bottom: $space-medium;
    left: 0;
}

.app__decorator--header {
    top: $space-medium + $header-height;
    left: 0;
}

.app__decorator--wrapper {
    top: $space-medium + $header-height + $space-xsmall;
    left: 0;
}

.app__decorator--sidebar-left {
    z-index: 1;

    top: $space-medium;
    left: $space-medium + $sidebar-width;

    // height: calc(100vh - #{$space-medium});
    // height: 100%;
    height: calc(100% - #{$space-medium});
    width: 1px;
}

.app__decorator--sidebar-right {
    z-index: 1;
    
    top: $space-medium;
    right: $space-medium + $sidebar-width;

    // height: calc(100vh - #{$space-medium});
    height: calc(100% - #{$space-medium});
    width: 1px; 
}

.app__decorator--middleblock-left {
    z-index: 3;

    top: $space-medium;
    left: $space-medium + $sidebar-width +$space-small;

    // height: calc(100vh - #{$space-medium});
    height: calc(100% - #{$space-medium});
    width: 1px;
}

.app__decorator--middleblock-right {
    z-index: 3;

    top: $space-medium;
    right: $space-medium + $sidebar-width +$space-small;

    // height: calc(100vh - #{$space-medium});
    height: calc(100% - #{$space-medium});
    width: 1px;
}

.app__container {
    width: 100%;
    // max-width: 1860px;
}

.app__wrapper {
    z-index: 2;

    position: relative;

    display: flex;
    justify-content: space-between;

    width: 100%;
}

/**
 * Global Layouts
 */

.middle-block {
    // width: calc(65.6% - 50px);
    width: calc(100% - 690px)
}

.middle-block--large {
    z-index: 4;
    position: relative;
    
    width: calc(100% - 110px);
}



/**
 * global classes
 */

 // text
 .text--blue-electric {
     color: $electric-blue;
 }

 .text--blue-carolina {
    color: $carolina-blue;
}

.text--orangey-red {
    color: $orangey-red;
}

// titles
.title--small {
    margin-right: 10px;

    font-size: $text-small;
    text-transform: uppercase;
    color: $greyish-brown;
    line-height: 1;
    letter-spacing: 0.1em;
}

.title--small__infos {
    position: relative;

    padding-left: 15px;

    color: $brown-grey;
    font-style: italic;
}

.title--small__infos:before {
    content: '';

    position: absolute;
    top: 50%;
    left: 0;

    width: 8px;
    height: 1px;

    transform: translateY(-50%);

    background: $brown-grey;
}

.title--medium {
    margin-right: 10px;

    font-size: $text-large;
    text-transform: uppercase;
    color: $greyish-brown;
    line-height: 1;
    letter-spacing: 0.1em;
}

.title--medium__infos {
    position: relative;

    padding-left: 15px;

    color: $brown-grey;
    font-style: italic;
}

.title--medium__infos:before {
    content: '';

    position: absolute;
    top: 50%;
    left: 0;

    width: 8px;
    height: 1px;

    transform: translateY(-50%);

    background: $brown-grey;
}



.title--hide {
    cursor: pointer;
    
    text-transform: uppercase;
    font-size: $text-xsmall;
    letter-spacing: 0.1em;
}

// Buttons
.btn {
    cursor: pointer;

    display: flex;
    align-items: center;

    height: 50px;
    padding: 0 30px;

    line-height: 50px;
    text-transform: uppercase;
    font-size: $text-small;
}

.btn__icon {
    margin-right: 10px;
}

.btn--small {
    cursor: pointer;

    height: 30px;
    padding: 0 18px;

    line-height: 30px;
    text-transform: uppercase;
    font-size: $text-xsmall;
}

.btn--red {
    color: $white;
    
    background: $orangey-red;
}

.btn--blue-empty {
    border: 1px solid $electric-blue;

    color: $electric-blue;

    background: transparent;
}

.btn--blue {
    color: $white;

    background: $electric-blue;
}


// Custom checkbox

.custom-checkbox {
    cursor: pointer;

    position: relative;
}

.custom-checkbox__input {
    cursor: pointer;

    position: absolute;

    height: 0;
    width: 0;

    opacity: 0;
}

.custom-checkbox__check {
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 15px;
    height: 15px;
    border: 1px solid $brownish-grey;
}

.custom-checkbox.is-checked .custom-checkbox__check {
    border: 1px solid $electric-blue;
}

.custom-checkbox__check__svg {
    opacity: 0;
    visibility: hidden;
}

.custom-checkbox.is-checked .custom-checkbox__check__svg {
    opacity: 1;
    visibility: visible;
}

.custom-checkbox__text {
    display: flex;
    align-items: center;

    margin-left: 25px;

    color: $brownish-grey;
    font-size: $text-large;
}

.custom-checkbox.is-checked
.custom-checkbox__text {
    color: $greyish-brown;
}

.custom-checkbox__infos {
    margin-left: 10px;

    color: $brownish-grey;
    font-style: italic;
    font-size: $text-xsmall;
    vertical-align: center;

    display: none;
}

.custom-checkbox.is-checked
.custom-checkbox__infos {
    display: block;
}

.notif {
    display: block;
    
    width: 6px;
    height: 6px;

    background: $orangey-red;
}