.ProjectPartnerTasksDetail {
	display:inline-block;
	width:100%;
	padding:30px;
	background-color:$paleGreyColor;

	.Tasks__header {
		width:100%;

		.ImageButton {
			display:inline-block;
			margin:0 15px 0 0;
		}

		.Tasks__title {
			display:inline-block;
			font-size:$fontSizeMedium;
			font-style:normal;
			font-stretch:normal;
			line-height:normal;
			letter-spacing:1.39px;
			color:$ubikonBlue;
			text-transform:uppercase;
		}
	}
}