@media only screen and (min-width: $mobileMinWidth) and (max-width: $mobileMaxWidth) {
	.Popin__overlay--visible {
		.Popin__center {
			width:94%;
			padding:12px;

			.Close__btn {
				top:12px;
				right:12px;
			}

			.Popin__content {
				padding-right:0;
			}

			.ProjectsPartnersForm {
				.Column__left {
					width:50%;
				}

				.Column__right {
					width:45%;

					.FormRow {
						width:100%;
						margin:0 0 12px 0;
					}
				}
			}
		}
	}
}