.LandInfosIcons {
	font-size:$fontSizeSmall;

	p {
		display:inline-block;
		margin:0 10px 0 0;
		padding:0;
		vertical-align:middle;

		.icon {
			display:inline-block;
			vertical-align:middle;
			margin:0 5px 0 0;
		}

		.info {
			display:inline-block;
			vertical-align:middle;
			color:$mediumGray;
		}

		&:last-child {
			margin-right:0;
		}
	}
	}