.FormRow {
	width:100%;
	margin:0 0 20px 0;
	padding:10px 0;

	&.hidden {
		visibility:hidden;
		height:0;
		margin:0;
		padding:0;
		overflow:hidden;

		&.project {
			min-height:0px;
		}
	}

	.Label {
		width:100%;
	}

	.FormInput {
		width:100%;
	}

	.FormRow__repeat {
		width:95%;
		margin:20px 0 0 5%;
	}
}