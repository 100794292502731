.Spinner {
	position:relative;
	width:100%;
	margin-top:40px;
	padding-bottom:25%;
	text-align:center;

	&.TaskDetail {
		margin-top:20px;
		padding-bottom:0;
	}

	.Spin {
		display:inline-block;
		border:2px solid $paleGrey;
		border-top:2px solid $ubikonLightBlue;
		border-radius:50%;
		width:40px;
		height:40px;
		animation:spinSpinner 1.6s linear infinite;
	}
}

@keyframes spinSpinner {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}