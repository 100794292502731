@media only screen and (min-width: $mobileMinWidth) and (max-width: $mobileMaxWidth) {
	.project {
		min-height:240px;
		padding:15px 12px;

		.project__head {
			height:auto;

			.project__head__back, .project__head__edit {
				align-items:start;
				margin-top:12px;
			}

			.project__head__back {
				.project__head__back__text {
					display:none;
				}
			}

			.project__head__title {
				position:unset;
				width:90%;
			}

			.project__head__edit {
				.project__head__edit__text {
					display:none;
				}
			}
		}

		.ProjectPipeline {
			display:none;
		}
	}

	.project-views {
		padding:10px 12px 30px 12px;

		&.ProjectDetailTabs {
			padding:10px 12px 30px 12px;
		}

		.project-views__header {
			.project-views__header__inner {
				&::-webkit-scrollbar {
					display:none !important;
				}

				.project-views__header__nav {
					overflow-x:scroll;
					overflow-y:hidden;
					margin-right:12px;

					&::-webkit-scrollbar {
						display:none !important;
					}

					.project-views__header__nav__item {
						margin-right:40px;
					}
				}

				.project-views__header__button {
					min-width:35%;
					margin-bottom:0;
					padding:0 12px;
					line-height:18px;
				}
			}
		}

		.ProjectDetailTabs__content {
			margin-top:12px;

			.ProjectDetailTabContent {
				.ProjectSection--dashboard {
					.ProjectDashboardBox {
						margin-bottom:12px;
						padding:22px 12px;

						.ProjectDashboardBox__header {
							position:relative;

							.float-right {
								position:absolute;
								right:0;
								top:0;

								.ImageButton {
									margin:0;
								}

								.TextButton {
									display:none;
								}
							}
						}

						.TaskBox__Partners__List {
							width:100%;
							margin:12px 0;

							span.medium {
								display:inline-block;
								width:100%;
								margin-bottom:12px;
							}
						}

						.signatures {
							.sign {
								width:100%;
								margin-top:12px;
							}
						}
					}
				}
			}

			.PartnersList--tiles {
				margin-top:10px;

				.PartnersList__partners {
					.PartnersListItem {
						width:100%;
						margin:0 0 1px 0;

						&:last-child {
							margin-bottom:0;
						}
					}
				}
			}
		}
	}

	.TaskPopinForm {
		width:95%;
		margin-left:0;
		margin-right:0;

		.Column__left, .Column__right, .Column__full {
			width:100%;
			margin-left:0;
			margin-right:0;
		}
	}
}