.OffersList {
	border-style:solid;
	border-width:0 1px 0 0;
	margin-bottom:20px;

	.OffersListItem {
		display:flex;
		width:100%;
		border-style:solid;
		border-width:0 0 1px 0;
		padding:20px 0;

		&:hover {
			background-color:#ccc;
			cursor:pointer;
		}

		&--active {
			@extend .OffersListItem;
			background-color:#ccc;
			cursor:pointer;
		}

		.OffersListItem__User {
			width:70%;

			.OffersListItem__User__avatar {
				display:inline-block;
				width:$userAvatarWidth;
				height:$userAvatarWidth;
				background-color:#ccc;
				border-radius:50%;
			}

			.OffersListItem__User__username {
				margin:0 0 0 10px;
				padding:0;
				display:inline-block;
				vertical-align:top;
				height:100%;
			}
		}

		.OffersListItem__amount {
			width:30%;
			margin:0;
			padding:0;
		}
	}
}

.OffersList__body {
	display:inline-block;
	width:100%;

	.OffersList__body__header {
		h2 {
			display:inline-block;
			vertical-align:middle;
			margin:0;
			padding:0;
			font-size:$fontSizeMedium;
			letter-spacing:1.39px;
			color:$greyishBrownColor;
			text-transform:uppercase;
		}

		.Buttons {
			display:inline-block;
			vertical-align:middle;
			float:right;

			.ImageButton {
				display:inline-block;
				vertical-align:middle;
				margin:0 10px 0 0;
			}

			.TextButton {
				display:inline-block;
				vertical-align:middle;
			}
		}
	}

	.Offer__directories {
		display:inline-block;
		width:100%;

		.Offer__directory {
			display:inline-block;
			vertical-align:middle;
			width:100%;
			background-color:$paleGreyColor;
			cursor:pointer;

			.Offer__directory__header {
				display:inline-block;
				width:100%;
				padding:10px;
				border-style:solid;
				border-width:0 0 1px 0;
				border-color:$paleGrey;
				vertical-align:middle;

				label {
					display:inline-block;
					vertical-align:middle;
					font-size:$fontSizeMedium;
				}

				.count {
					display:inline-block;
					vertical-align:middle;
					font-size:$fontSizeMedium;
					font-style:italic;
					color:$brownishGrey;
				}
			}

			table.Directory__offers {
				width:95%;
				margin:0 auto;
				border-collapse:collapse;
				text-align:left;

				thead {
					tr {
						th {
							border-style:solid;
							border-width:0 0 1px 0;
							border-color:$paleGrey;
							padding:8px 0;
							font-size:$fontSizeSmall;
							text-transform:uppercase;
							color:$brownishGrey;
						}
					}
				}

				tbody {
					tr {
						td {
							padding:10px 0;
							border-style:solid;
							border-width:0 0 1px 0;
							border-color:$paleGrey;
							font-size:$fontSizeMedium;
						}
					}
				}
			}
		}
	}
}