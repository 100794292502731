@media only screen and (min-width: $mobileMinWidth) and (max-width: $mobileMaxWidth) {
	.AdminIndex {
		padding:12px;

		.AdminSection {
			padding:0 6px;

			.AdminSection__header {
				padding:20px 12px;

				h3 {
					width:40%;
					line-height:18px;
				}
			}

			.AdminSection__entities {
				.AdminSectionEntity {
					padding:12px;

					.AdminSectionEntity__content {
						width:100%;
					}

					.AdminSectionEntity__actions {
						width:100%;
						margin-top:24px;
					}
				}
			}
		}
	}
}