// Fonts
$main-font: 'IBM Plex Sans', sans-serif;

$text-xsmall: 10px;
$text-small: 12px;
$text-medium: 13px;
$text-large: 14px;
$text-xlarge: 15px;

$title-xsmall: 18px;
$title-small: 20px;
$title-medium: 22px;
$title-large: 24px;
$title-xlarge: 32px;
$title-xxlarge: 36px;

// blocs spaces
$space-xsmall: 20px;
$space-small: 25px;
$space-medium: 30px;
$space-large: 35px;

// Colors
$white: #ffffff;

$pale-grey: #f9f9fb;
$brownish-grey: #737373;
$brown-grey: #a9a9a9;
$pale-lilac: #ececee;

$greyish-brown: #3d3d3d;

$electric-blue: #004bff;
$carolina-blue: #7ea4ff;

$orangey-red: #f63c2c;

// Borders
$border-grey: 1px solid $pale-lilac;


// Others
$header-height: 80px;
$sidebar-width: 320px;