.Organization {
	display:inline-block;
	width:calc(100% - 1px);
	height:calc(100vh - 60px - 80px - 20px);
	padding-right:10px;
	overflow-y:scroll;
	overflow-x:hidden;
	background-color:white;

	@include customScrollbar();

	.Edit__Form__Wrapper {

	}
}

.Organization__Detail {
	background-color:white;

	.Organization__nav {
		width:100%;
		margin-top:25px;
		padding:20px 20px 20px 10%;

		.Edit__btns {
			float:right;

			button {
				&:last-child {
					margin-left:10px;
				}
			}
		}
	}

	.Organization__header {
		position:relative;
		display:inline-block;
		width:100%;
		min-height:100px;
		border-style:solid;
		border-width:1px 0;
		border-color:$paleGreyColor;
		font-size:0;

		.Organization__name {
			display:inline-block;
			width:50%;

			h1 {
				@include pageTitle();
				position:absolute;
				top:50%;
				left:10%;
				transform:translate(0, -50%);
				width:35%;
			}
		}

		.Organization__cover {
			position:absolute;
			top:0;
			right:0;
			display:inline-block;
			width:50%;
			height:100%;
			background-image:url('assets/png/organization-cover.png');
			background-size:cover;
			background-position:center;
			background-repeat:no-repeat;
		}
	}
}

.Organization__content {
	display:inline-block;
	width:90%;
	margin:40px 0 0 10%;
	font-size:0;

	.Column__left, .Column__right, .Column__full {
		display:inline-block;
		vertical-align:top;
		font-size:initial;
	}

	.Column__left {
		width:40%;
	}

	.Column__right {
		width:50%;
		margin:0 0 0 5%;
	}

	.Column__full {
		width:100%;
		text-align:center;
		padding:20px 0;
	}

	.Organization__Logo {
		@include avatar();
		margin:20px;
	}

	.Organization__infobox {
		width:100%;
		margin:0 0 20px 0;
		font-size:$fontSize12;
		color:$greyishBrownColor;

		label {
			display:inline-block;
			width:100%;
			margin-bottom:5px;
			@include smallLabel();
		}

		p {
			width:100%;
			margin:0;
			padding:0;
		}

		a {
			text-decoration:none;
			color:inherit;
		}

		.Address {
			h2 {
				display:inline-block;
				width:100%;
				margin-bottom:5px;
				@include smallLabel();
			}

			p {
				width:100%;
				margin:0;
				padding:0;
			}
		}
	}

	.Organization__description {
		margin-top:50px;
		font-size:$fontSizeMedium;
		font-weight:normal;
		font-stretch:normal;
		font-style:normal;
		line-height:1.79;
		letter-spacing:0.54px;
		color:$brownishGrey;
	}

	.Organization__members {
		width:100%;
		margin-top:50px;

		h2 {
			@include smallLabel();
		}

		.Organization__empty__members {
			width:100%;
			margin:20px 0;
			text-align:center;
			font-size:$fontSize12;
			color:$brownGrey;
		}

		.Members__list {
			display:inline-block;
			width:100%;
			margin:10px 0 0 0;
			padding:0;
			font-size:0;

			li {
				display:inline-block;
				vertical-align:top;
				width:45%;
				padding:10px;
				background-color:$paleGreyColor;

				.Avatar {
					@include avatar(30px);
					display:inline-block;
					vertical-align:middle;
				}

				label {
					display:inline-block;
					vertical-align:middle;
					width:calc(100% - 45px);
					margin-left:10px;
					font-size:$fontSize13;
					letter-spacing:0.5px;
					color:$greyishBrownColor;
				}

				&:nth-child(even) {
					margin-left:5%;
				}

				&:nth-child(odd) {
					margin-right:5%;
				}
			}
		}
	}

	.Edit__btn__wrapper {
		width:100%;
		margin:50px 0 30px 0;
	}

	.FormRow {
		padding-top:0;

		input[type="text"], input[type="email"], input[type="password"] {
			font-size:$fontSizeMedium;
		}

		textarea {
			min-height:180px;
			resize:none;
			font-size:$fontSizeMedium;
			line-height:1.79;
			letter-spacing:0.54px;
		}
	}
}

.CreateOrganization {
	display:inline-block;
	vertical-align:top;
	width:100%;
	padding:30px 50px;

	h1 {
		width:100%;
		margin:40px 0 20px 0;
		padding:0;
		font-size:$fontSize24;
		color:$greyishBrownColor;
	}

	p {
		width:90%;
		margin-top:50px;
		line-height:24px;
		color:$brownishGrey;
	}

	.CreateOrganization__columns {
		display:inline-block;
		vertical-align:middle;
		width:100%;
		margin:50px 0 0 0;

		.Col__left, .Col__right {
			display:inline-block;
			vertical-align:top;
			width:50%;
			text-align:center;

			h2 {
				width:100%;
				margin:0 0 50px 0;
				color:$brownishGrey;
				font-size:$fontSize16;
				text-align:center;
			}
		}

		.Col__left {
			padding:20px 5% 20px 0;
			border-style:solid;
			border-width:0 1px 0 0;
			border-color:$paleGrey;
			text-align:left;

			label {
				@include smallLabel();
				display:inline-block;
				margin-bottom:10px;
			}

			input {
				width:100%;
				padding:20px;
			}

			textarea {
				min-height:180px;
				resize:none;
				font-size:$fontSize13;
				line-height:1.79;
				letter-spacing:0.54px;
			}
		}

		.Col__right {
			padding:20px 0 20px 5%;

			select {
				-webkit-appearance:none;
				-moz-appearance:none;
				appearance:none;
				width:100%;
				margin-top:35px;
				padding:20px;
				border:none;
				background-color:$paleGreyColor;
			}
		}

		.Col__full {
			width:100%;
			margin-top:30px;
			text-align:center;

			button {
				padding:15px 40px;
			}
		}
	}
}

.Organization__content__pending {
	display:inline-block;
	width:100%;
	margin:40px 0;

	h3 {
		width:100%;
		font-size:$fontSizeMedium;
		color:$redOrange;
	}
}