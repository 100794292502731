@mixin customScrollbar() {
	&::-webkit-scrollbar {
		display:block;
		width:1px;
		height:1px;
		cursor:pointer;
	}

	&::-webkit-scrollbar-track {
		background:$lighterGray;
		cursor:pointer;
	}

	&::-webkit-scrollbar-thumb {
		background:$ubikonBlue;
		cursor:pointer;
	}
}