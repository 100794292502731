.ProjectDetailTabs {
	.ProjectDetailTabs__nav {
		display:inline-block;
		width:100%;
		margin:20px 0 0 0;
		padding:0 0 20px 0;
		border-style:solid;
		border-width:0 0 1px 0;
		border-color:$lightGray;

		.ProjectDetailTabs__navList {
			list-style-type:none;
			display:inline-block;
			margin:0;
			padding:0;

			.ProjectDetailTabs__navListItem {
				display:inline-block;
				margin:0 50px 0 0;
				padding:0;
				cursor:pointer;
				color:$mediumGray;
				font-size:$fontSize15;

				&--active {
					@extend .ProjectDetailTabs__navListItem;
					color:$ubikonBlue;
				}

				&:last-child {
					margin-right:0;
				}

				&:hover {
					text-decoration:underline;
				}
			}
		}
	}

	.ProjectDetailTabs__content {
		display:inline-block;
		width:100%;
		margin-top:50px;

		.Offer__back {
			margin:0 0 30px 0;
		}
	}
}