.ChatSectionTitle {
	.ChatSectionTitle__count {
		font-style:italic;
		color:$mediumGray;

		&:before {
			content:'.';
			display:inline-block;
			width:10px;
			height:10px;
			margin:0 5px;
			border-style:solid;
			border-width:0 0 1px 0;
			border-color:$mediumGray;
			color:transparent;
		}
	}

	.ChatSectionTitle__expend {
		float:right;
		cursor:pointer;
	}
}