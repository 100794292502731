@keyframes loginCenter {
	0% {
		width:0;
		padding:50px 30px;
		opacity:0;
	}

	50% {
		opacity:0;
	}

	75% {
		opacity:0;
	}

	100% {
		width:60%;
		opacity:1;
	}
}