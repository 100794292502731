@mixin submitButton() {
	padding:10px 15px;
	border:none;
	outline:none;
	color:white;
	background-color:$ubikonBlue;
	text-align:center;
	cursor:pointer;

	&.is-disabled {
		background-color:$carolinaBlue;
		cursor:not-allowed;
	}
}