@mixin smallLabel() {
	font-family:$fontFamilyIBMPlexSans;
	font-size:$fontSize12;
	font-weight:normal;
	font-style:normal;
	font-stretch:normal;
	line-height:normal;
	letter-spacing:1.29px;
	color:$greyishBrownColor;
	text-transform:uppercase;
}