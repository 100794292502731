.left-sidebar.is-mobile {
	display:none;
}

@media only screen and (min-width: $mobileMinWidth) and (max-width: $mobileMaxWidth) {
	.left-sidebar.is-mobile {
		display:flex;
		position:fixed;
		bottom:10px;
		left:10px;
		right:10px;
		width:94%;
		height:70px;
		overflow:hidden;
		z-index:1;

		.sidebar-block__inner {
			margin-top:25px;
			padding:0;

			nav.nav {
				ul.nav__items {
					display:flex;
					width:100%;
					margin-top:0;
					justify-content:space-evenly;

					li.nav__item {
						display:inline-block;
						width:auto;
						margin-bottom:0;

						&.is-active {
							&:after {
								display:none;
							}

							#MOBILE_BLOC_Bottom-Navigation {
								stroke:$ubikonOrange;
							}
						}

						.nav__item__icon--message {
							g {
								fill:none;
							}
						}
					}
				}
			}
		}
	}
}