.LandDetail {
	width:100%;
	height:calc(100vh - 60px - 80px - 20px);
	padding:35px;
	overflow:hidden;
	background-color:white;

	.LandDetail__content {
		position:relative;
		display:inline-block;
		width:90%;
		height:calc(100vh - 60px - 80px - 66px - 56px);
		margin:20px 0 0 10%;
		font-size:0;

		.Back__button {
			display:inline-block;
			margin:20px 0;
		}

		.LandDetail__wrapper {
			display:inline-block;
			width:100%;

			.LandDetail__infos {
				display:inline-block;
				width:47%;
				height:calc(100vh - 60px - 80px - 66px - 56px - 50px);
				margin:20px 3% 0 0;
				padding:0 20px 80px 0;
				overflow-y:scroll;
				overflow-x:hidden;

				@include customScrollbar();

				h1 {
					margin:0 0 15px 0;
					font-size:$fontSize32;
					line-height:1.19;
					letter-spacing:1.23px;
					color:$greyishBrownColor;
				}

				.PartnersListItem {
					padding:0;

					.PartnersListItem__avatar {
						@include avatar(30px);
					}

					.PartnersListItem__username {
						width:auto;
						margin-top:5px;
						font-size:$fontSizeSmall;
						color:$mediumGray;

						.username {
							font-weight:300;
						}
					}
				}

				.LandDetail__price {
					margin:10px 0 15px 0;
					font-size:$fontSize20;
					font-weight:normal;
					font-style:normal;
					font-stretch:normal;
					line-height:normal;
					letter-spacing:0.77px;
				}

				.LandDetail__description {
					margin:20px 0 12px 0;
					font-size:$fontSizeMedium;
					font-weight:normal;
					font-style:normal;
					font-stretch:normal;
					line-height:1.79;
					letter-spacing:0.54px;
					color:$brownishGrey;
				}

				.LandDetailButtons {
					margin:50px 0;

					button {
						padding:15px 20px;
						margin:0 20px 0 0;
						text-transform:uppercase;

						&:last-child {
							margin-right:0;
						}

						&:hover {
							padding:14px 19px;
							border-style:solid;
							border-width:1px;
							border-color:$ubikonBlue;
							color:$ubikonBlue;
							background-color:white;
						}

						&.Contact__seller__btn {
							padding:15px 20px;

							&:hover {
								padding:14px 19px;
							}
						}
					}
				}
			}

			.LandDetail__carousel {
				position:absolute;
				top:0;
				bottom:0;
				right:0;
				width:50%;
				height:100%;
				transition:all 0.8s ease;

				&--fullscreen {
					@extend .LandDetail__carousel;
					width:100%;
					top:0;
					bottom:0;
					right:0;
				}
			}
		}
	}
}

.Land__popin {
	.LandFormTheme__left, .LandFormTheme__right {
		display:inline-block;
		vertical-align:top;
		width:50%;
		padding:0 2.5%;
	}

	.LandFormTheme__full {
		display:inline-block;
		vertical-align:top;
		width:100%;
		padding:0 2.5%;

		.LandForm__row {
			display:inline-block;
			width:calc(33.33% - 15px);
			margin-right:20px;

			&:last-child {
				margin-right:0;
			}
		}
	}

	.FormRow {
		display:inline-block;
		vertical-align:top;

		label {
			display:inline-block;
			width:100%;
			margin-bottom:5px;
			font-size:$fontSize12;
			font-weight:normal;
			font-stretch:normal;
			font-style:normal;
			line-height:normal;
			letter-spacing:1.67px;
			color:$brownishGrey;
			text-transform:uppercase;
		}

		input[type="text"], input[type="number"], textarea {
			padding:15px;
		}

		textarea {
			min-height:180px;
			resize:none;

			&::-webkit-scrollbar {
				display:none;
			}
		}
	}
}

.LandDetail__owner {
	margin:0 0 30px 0;

	.avatar {
		@include avatar(30px);
		display:inline-block;
		vertical-align:middle;
	}

	.username {
		margin:0 0 0 10px;
		padding:0;
		display:inline-block;
		vertical-align:middle;
		font-size:$fontSize13;
		color:$brownishGrey;
		letter-spacing:0.5px;
	}
}