.catalogue {
	.all-projects__header {
		display:none;
	}
}

@media only screen and (min-width: $mobileMinWidth) and (max-width: $mobileMaxWidth) {
	.catalogue {
		padding:12px;
		overflow-y:scroll;
		@include customScrollbar();

		.all-projects__header {
			position:relative;
			display:inline-block;
			width:100%;
			margin-bottom:12px;
			padding:10px 0 0 0;

			h1 {
				font-size:$fontSize24;
				font-weight:normal;
				font-stretch:normal;
				font-style:normal;
				line-height:normal;
				letter-spacing:0.92px;
				text-align:center;
				color:$greyishBrownColor;
			}

			.all-projects__actions {
				display:none;

				&.is-mobile {
					display:inline-block;
					border-bottom:1px solid $paleGrey;
					text-align:left;

					.all-projects__action {
						position:absolute;
						top:50%;
						transform:translate(-50%, 0);

						&.filters {
							left:10px;
						}

						&.search {
							right:10px;
							margin-top:0;
							margin-left:0;
						}
					}
				}
			}
		}

		header.catalogue__header {
			display:none;
		}

		/*
		header.catalogue__header {
			display:inline-block;
			width:100%;
			padding:24px 0;

			.catalogue__header__infos {
				display:inline-block;
				width:100%;
				margin-bottom:12px;

				.catalogue__header__title {
					display:inline-block;
					width:100%;
					margin-bottom:12px;
				}

				.catalogue__header__search {
					.catalogue__header__search__input {
						padding-right:12px;
					}
				}
			}

			.catalogue__header__filters {
				width:100%;
				margin:12px 0;

				.catalogue__header__filter {
					width:30%;
				}

				.catalogue__header__filter--icon {
					margin-left:12px;
				}
			}
		}
		*/

		.catalogue__wrapper {
			height:unset;
			flex-direction:column;
			margin-top:12px;

			.catalogue__properties {
				width:100%;
				height:unset;

				.catalogue__properties__header {
					.catalogue__properties__title {
						width:40%;
					}
				}

				.catalogue__properties__wrapper {
					.catalogue__property {
						width:100%;
					}
				}
			}

			.catalogue__map {
				width:100%;
			}
		}
	}
}