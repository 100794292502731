.Row {
	width:100%;

	.Column {
		display:inline-block;
		width:100%;
		vertical-align:top;

		&--30 {
			@extend .Column;
			width:30%;
		}

		&--70 {
			@extend .Column;
			width:70%;
		}
	}
}