@media only screen and (min-width: $mobileMinWidth) and (max-width: $mobileMaxWidth) {
	.app {
		padding:10px;
	}

	.app__wrapper {
		height:calc(100vh - 190px);
		overflow:hidden;

		&.login-form {
			height:calc(100vh - 20px);

			&.is-done {
				height:calc(100vh - 120px);
			}
		}

		.left-sidebar, .right-sidebar {
			display:none;
		}

		section.middle-block {
			width:100%;
		}
	}

	.App {
		&.is-collapsed {
			section.middle-block {
				&.size-small {
					width:100%;
				}
			}
		}
	}
}