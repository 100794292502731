.TextPage {
	padding:50px 30px 30px 30px;
	height:100%;
	background-color:white;

	h1 {
		margin:0 0 40px 0;
		font-size:$fontSize32;
		color:$ubikonBlue;
	}

	.Content {
		font-size:$fontSize15;
		color:$brownishGrey;
		line-height:24px;
	}
}

.ContactForm {
	margin-top:40px;

	label {
		display:inline-block;
		margin:0 0 5px 0;
		font-size:$fontSize12;
		font-weight:normal;
		font-stretch:normal;
		font-style:normal;
		line-height:normal;
		letter-spacing:1.29px;
		color:$greyishBrownColor;
		text-transform:uppercase;
	}

	input[type="text"], textarea {
		padding:15px;
	}

	textarea {
		min-height:250px;
		resize:none;
		overflow:hidden;

		&::-webkit-scrollbar {
			display:none;
		}
	}

	.Spinner {
		margin-top:150px;
		padding-bottom:150px;
	}
}

.Contact__success {
	width:100%;
	margin-top:150px;
	text-align:center;
	line-height:24px;

	.TextButton {
		margin-top:40px;
		color:$carolinaBlue;

		&:hover {
			color:$ubikonBlue;
		}
	}
}