@media only screen and (min-width: $mobileMinWidth) and (max-width: $mobileMaxWidth) {
	.Login {
		height:calc(100vh - 60px - 40px - 20px);

		&.is-intro {
			height:100%;
		}

		.Login__center {
			max-width:85%;

			.Login__tabs {
				width:100%;
				margin-top:20px;
				text-align:center;

				button.Login__tab__button {
					padding:0;

					&:last-child {
						margin-top:10px;
					}
				}
			}

			.Login__tab__content {
				width:90%;

				.Login__form {
					.FormRow {
						&.help {
							.TextButton {
								font-size:$fontSize12;

								&:last-child {
									margin-top:2px;
								}
							}
						}
					}

					.Login__help {
						&.signup {
							margin-top:30px;
							font-size:$fontSize12;
						}
					}
				}

				.Login__signup_form {
					.FormRow {
						&.help {
							.Terms__wrapper {
								width:55%;

								.optin {
									max-width:20%;
								}

								.TextButton {
									display:inline-block;
									float:none;
									max-width:80%;
									text-align:left;
								}
							}
						}
					}

					.SubmitButton {
						margin-top:20px;
					}
				}
			}
		}
	}
}