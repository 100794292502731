.LandsIndex {
	height:100%;
	padding:0 30px 30px 30px;
	background-color:white;

	.LandsIndex__list {
		position:relative;
		display:inline-block;
		width:100%;
		margin:30px 0 0 0;

		.Carousel {
			position:absolute;
			height:100%;
			border-radius:10px 0 0 10px;
		}
	}

	.MapList {
		width:calc(55% - 15px);
	}

	.LandsMap {
		position:absolute;
		top:0;
		right:0;
		width:calc(45% - 15px);
		height:100%;
		overflow:hidden;

		.HideMap__button {
			position:absolute;
			top:20px;
			right:20px;
			padding:10px;
			background-color:white;
			z-index:9;
		}
	}
}