.PartnersList {
	display:inline-block;
	width:100%;

	.PartnersList__partners {
		width:100%;

		.PartnersListItem {
			display:inline-block;
			height:50px;
			margin:0 10px 0 0;
			padding:$defaultPadding;
			cursor:pointer;

			&--active {
				@extend .PartnersListItem;
			}

			&--inactive {
				@extend .PartnersListItem;
				opacity:0.4;
			}

			div {
				.PartnersListItem__avatar {
					@include avatar(50px);
					display:inline-block;
					margin-right:10px;
				}

				.PartnersListItem__username {
					display:inline-block;
					width:calc(100% - 50px);
					vertical-align:top;

					.username {
						display:inline-block;
						width:100%;
						font-size:$fontSize13;
						color:$greyishBrownColor;

						&.auto {
							width:auto;
						}
					}

					.role {
						display:inline-block;
						width:100%;
						font-size:$fontSize11;
						color:$brownishGrey;
					}
				}
			}
		}
	}

	&--tiles {
		@extend .PartnersList;
		font-size:0;

		.PartnersList__partners {
			.PartnersListItem {
				position:relative;
				width:32%;
				height:auto;
				min-height:70px;
				margin:2% 2% 0 0;
				background-color:white;

				&:nth-child(3n) {
					margin-right:0;
				}

				.Partners__view__tasks {
					position:absolute;
					top:0;
					left:0;
					width:100%;
					height:100%;
					background-color:white;
					z-index:99;
					cursor:pointer;

					p {
						position:absolute;
						top:50%;
						width:100%;
						transform:translate(0, -50%);
						font-size:$fontSize11;
						text-align:center;
						color:$ubikonBlue;
						z-index:99;
					}
				}

				div {
					.PartnersListItem__avatar {
						width:35px;
						height:35px;
					}

					.PartnersListItem__username {
						max-width:55%;
						margin-top:8px;

						.username {
							font-weight:normal;
							font-size:$fontSize13;
						}

						&.auto {
							max-width:60%;
						}
					}

					.PartnersListItem__actions {
						float:right;
						margin:8px 0 0 0;

						.ImageButton {
							margin:0 20px 0 0;

							&:last-child {
								margin-right:0;
							}
						}
					}

					.PartnersListItem__actions__list {
						position:absolute;
						top:100%;
						left:0;
						width:100%;
						padding:0;
						margin:0;
						background-color:white;
						z-index:9;

						li.PartnersListItem__actions__list__item {
							display:inline-block;
							width:100%;
							padding:10px;
							border-style:solid;
							border-width:1px 0 0 0;
							border-color:$lightGray;
							list-style-type:none;
							font-size:$fontSizeMedium;
							color:$blackColor;

							&:hover {
								color:$ubikonBlue;
								cursor:pointer;
							}
						}
					}
				}
			}
		}
	}

	&--project_index {
		@extend .PartnersList;

		.PartnersListItem__avatar {
			@include avatar(30px);
		}
	}

	.PartnersList__add {
		width:20%;
	}
}

.AssignTaskPopin {
	display:inline-block;
	vertical-align:top;
	width:100%;
	margin:20px 0 0 0;

	.Col__left, .Col__right {
		display:inline-block;
		vertical-align:top;
		padding:0 2.5%;
	}

	.Col__left {
		width:20%;
		text-align:center;

		.SubmitButton {
			margin-top:50px;

			&--loading {
				margin-top:50px;
			}
		}
	}

	.Col__right {
		width:80%;
	}

	.PartnersListItem__avatar {
		@include avatar(50px);
		display:inline-block;
		margin-right:10px;
	}

	.PartnersListItem__username {
		margin:10px 0 0 0;
		width:100%;
		text-align:center;
	}
}