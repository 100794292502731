@import './reset.scss';
@import './variables.scss';
@import './base.scss';
@import './header.scss';
@import './sidebars.scss';


/**
 * Project
 */

.project {
    position: relative;

    width: 100%;
    padding: $space-small $space-large;

    color: $white;

    background-color: $electric-blue;

    background-size: cover;
    background-position: 50% 50%;
}

.project__decorator {
    z-index: 3;
    position: absolute;
    bottom: 0;
    left: -($space-small);

    display: block;

    width: calc(100% + 2 * #{$space-small});
    height: 1px;


    background: $pale-lilac;
}

.project__bg {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

}

.project__head, .project__infos, .project__steps__title, .project__steps {
    z-index: 2;
    position: relative;
}

.project__head {
    position: relative;

    display: flex;
    justify-content: space-between;

    height: 46px;
    width: 100%;
}

.project__head__back, .project__head__edit {
    display: flex;
    align-items: center;

    cursor: pointer;
}

.project__head__back__text {
    margin-left: 10px;

    text-transform: uppercase;
    line-height: 1.3;
    font-size: $text-xsmall;
    font-weight: 600;
    letter-spacing: 1.07px; 
}

.project__head__edit__text {
    margin-left: 10px;

    text-transform: uppercase;
    line-height: 1.3;
    font-size: $text-xsmall;
    letter-spacing: 1.07px; 
}

.project__head__title {
    position: absolute;

    width: 100%;

    font-size: $title-xxlarge;
    line-height: 1.3;
    text-align: center;
}

.project__infos {
    align-items: center;
    justify-content: center;

    margin-top: 15px;
    margin-bottom: $space-large;

    display: flex;

    font-size: $text-medium;

    opacity: 0.75;
}

.project__infos svg {
    cursor: pointer;
}

// .project__infos__separator {
//     height: 20px;
//     width: 1px;
//     margin-left: 20px;
//     margin-right: 20px;

//     background: $pale-lilac;
// }

// .project__select {
//     cursor: pointer;

//     margin-right: 10px;
// }

.project__steps__title {
    text-transform: uppercase;
    text-align: center;
    font-size: $text-small;
}

.project__steps__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
}

.project__steps__button {
    z-index: 2;
    cursor: pointer;
    position: relative;

    display: flex;
    align-items: center;

    height: 30px;
    padding: 0 10px;
    border: 1px solid #94b3fe;
    border-radius: 5px;

    line-height: 30px;
    text-transform: uppercase;
    color: #94b3fe;

    background: transparent;
}

.project__steps__button:first-child {
    margin-right: 10px;
}

.project__steps__buttons .custom-select  {
    margin-right: 10px;
}

.custom-select {
    cursor:pointer;
    overflow:hidden;
    z-index:2;
    position:relative;
    border:1px solid #94b3fe;
    border-radius:5px;
}

.custom-select__icon--arrow-down {
    position: absolute;
    right: 10px;
    top: 50%;

    transform: translateY(-50%);
}

.custom-select__inner {
    cursor: pointer;

    -webkit-appearance: none;
    appearance: none;

    display: flex;
    align-items: center;

    height: 30px;
    width: 110%;
    padding: 0 40px 0 10px;
    border: none;

    line-height: 30px;
    text-transform: uppercase;
    color: #94b3fe;

    background: transparent;
}

.project__steps__button__icon--eye {
    stroke: #94b3fe;
    margin-right: 5px;
}

.project__steps__button__icon--arrow-down {
    margin-left: 5px;
}

.project__steps {
    position: relative;

    width: 80%;
    margin: 0 auto;
    padding-bottom: 60px;
    padding-top: 35px;
}

.project__steps__step {
    position: absolute;
}

.project__steps__step:nth-child(2) {
    left: 0%;
}

.project__steps__step:nth-child(3) {
    left: 25%;
}

.project__steps__step:nth-child(4) {
    left: 50%;
}

.project__steps__step:nth-child(5) {
    left: 75%;
}

.project__steps__step:nth-child(6) {
    left: 100%;
}



.project__steps__line {
    height: 2px;
    width: 100%;

    display: flex;

    background-color: #6892f8;

    transform: translateY(1px);
}

.project__steps__line--1,
.project__steps__line--2,
.project__steps__line--3,
.project__steps__line--4 {
    height: 2px;
    width: 25%;
}

.project__steps__line--1.is-done,
.project__steps__line--2.is-done,
.project__steps__line--3.is-done,
.project__steps__line--4.is-done {
    background: $white;
}

.project__steps__circle {
    position: absolute;

    width: 12px;
    height: 12px;
    border-radius: 50%;

    background: $white;

    transform: translate3d(-50%, -50%, 0);
}

.project__steps__circle:after {
    content: '';
    
    position: absolute;

    width: 12px;
    height: 12px;
    border-radius: 50%;

    background: $electric-blue;

    transform: scale(0.8);
}

.project__steps__step.is-done .project__steps__circle:after {
    background: $white;
}

.project__steps__step.is-future .project__steps__circle{
    background-color: #6892f8;
}

.project__steps__step.is-future .project__steps__circle:after {
    background-color: #1056fa;
}

.project__steps__text {
    margin-top: 22px;

    font-size: $text-small;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    white-space: nowrap;
    line-height: 1.75;

    transform: translateX(-50%);
}

// .project__steps__step.is-done .project__steps__text:after {
//     content: '';

//     display: inline-block;
    
//     height: 8.2px;
//     width: 5.9px;
//     margin-left: 8px;
//     margin-bottom: 2px;
//     border-bottom: 2px solid $orangey-red;
//     border-right: 2px solid $orangey-red;

//     transform: rotate(45deg);
// }

// .project__steps__step.is-current .project__steps__text {
//    border-bottom: 1px solid $white;
// }

.project__steps__step.is-future .project__steps__text {
    opacity: 0.4;
}

 /**
 * Middle block, project views
 */

 .project-views {
    position: relative;

    width: 100%;
    margin-top: $space-small;
    padding: 15px 35px 35px 35px;
    border-bottom: 1px solid $pale-lilac;

    background: $white;
 }

 .project-views__decorator {
    z-index: 3;
    position: absolute;
    top: 0;
    left: -($space-small);

    display: block;

    width: calc(100% + 2 * #{$space-small});
    height: 1px;


    background: $pale-lilac;
  }

 .project-views__header {
    border-bottom: 1px solid $pale-lilac;
 }

 .project-views__header__inner {
     display: flex;
     align-items: center;
     justify-content: space-between;
 }

 .project-views__header__nav {
    display: flex;
 }

 .project-views__header__nav__item {
    cursor: pointer;
    position: relative;

    display: flex;
    align-items: center;

    margin-right: 95px;
    font-size: $text-xlarge;
 }

 .project-views__header__nav__item svg {
     margin-right: 10px;
 }

 .project-views__header__nav__item__stroke {
     stroke: $brownish-grey;
 }

 .project-views__header__nav__item.is-active {
     color: $electric-blue;
 }

 .project-views__header__nav__item.is-active:after {
     content: '';
     position: absolute;
     left: 25px;
     bottom: -24px;

     width: 30px;
     height: 1px;

     background: $electric-blue;
 }

 .project-views__header__nav__item.is-active .project-views__header__nav__item__stroke {
    stroke: $electric-blue;
}

 .project-views__header__nav__item.has-notif:after {
    content: '';

    position: absolute;
    top: 0;
    right: -12px;

    width: 6px;
    height: 6px;

    background: $orangey-red;
 }

 .project-views__header__button {
    display: flex;
    align-items: center;

    margin-bottom: 15px;
 }

 .project-views__header__button__icon {
     margin-right: 10px;
     transform: scale(1.2);
     transform-origin: 50% 50%;
 }

 // grey block

 .grey-block {
     margin-top: $space-small;
     width: 100%;
     border-radius: 10px;
     padding: 25px 30px;

     background: $pale-grey;
 }

 .grey-block__header {
     display: flex;
     align-items: center;

     width: 100%;
 }

 .grey-block__nav {
    flex-grow: 1;
 }

 .grey-block__nav__inner {
     display: flex;
     justify-content: space-between;
 }

 .grey-block__infos {
    display: flex;
 }

 .grey-block__select {
     cursor: pointer;

     display: flex;
     align-items: center;

     margin-left: 37px;
 }

 .grey-block__assigned {
    cursor: pointer;

    display: flex;
    align-items: center;

    margin-left: 40px;
 }

 .grey-block__assigned__people {
    position: relative;

    width: 29px;
    height: 29px;
    border-radius: 50%;
    margin-left: 8px;


    background-size: cover;
    background-position: 50% 50%;
 }

 .grey-block__select__text {
     margin-right: 10px;
 }

 .grey-block__select__text {
     font-size: $text-medium;
 }

 .grey-block__assigned__text {
     color: $brown-grey;
     font-size: $text-medium;
 }


 .grey-block__actions {
     display: flex;

     margin-left: auto;
     // align-self: flex-end;
 }

 .grey-block__action {
     cursor: pointer;

     display: flex;
     align-items: center;

     margin-left: 28px;
 }

 .add-partner__btn {
     height: 30px;
     padding: 0 15px;
     border: 1px solid $greyish-brown;
     border-radius: 5px;
 }

 .add-partner__btn .add-partner__btn__text {
    color: $greyish-brown;
 }

 .grey-block__action:first-child {
     margin-left: 0;
 }

 .grey-block__action__icon--filter {
     display: block;
     
     height: 12px;
     margin-right: 10px;
 }

 .grey-block__action__icon {
    margin-right: 10px;
 }

 .grey-block__action__icon--filter svg {
     height: 100%;
 }

 .grey-block__action__text {
     text-transform: uppercase;
     color: $carolina-blue;
     font-size: $text-xsmall;
 }


 .partners__wrapper {
     display: flex;
     flex-wrap: wrap;
     justify-content: space-between;

     margin-top: 10px;
 }

 // Single partner
 .partner {
     cursor: pointer;
     position: relative;

     display: flex;
     justify-content: space-between;

     padding: 15px;
     width: calc(33.33% - 20px);
     margin-top: 20px;

     background: $white;
 }

 .partner.not-selected {
    opacity: 0.4;
 }

 .partner__tasks {
     z-index: 2;
     position: absolute;
     top: 0;
     left: 0;

     display: flex;
     align-items: center;
     justify-content: center;

     width: 100%;
     height: 100%;

     background: $white;

     opacity: 0;
     visibility: hidden;
 }

 .partner.is-hover .partner__tasks {
    opacity: 1;
    visibility: visible;
 }

 .partner__tasks__link {
     color: $electric-blue;
     text-transform: uppercase;
     font-size: $text-small;
 }

 .partner.has-options {
    box-shadow: 0 0 11px 0 rgba(0, 75, 255, 0.1);
 }

 .partner__options {
     position: absolute;
     z-index: 3;

     top: 100%;
     left: 0;
     width: 100%;
     box-shadow: 0 0 11px 0 rgba(0, 75, 255, 0.1);

     background: $white;

     opacity: 0;
     visibility: hidden;
 }

 .partner.has-options .partner__options {
     opacity: 1;
     visibility: visible;
 }

 .partner__options:before {
     content: '';
     height: 11px;
     width: 100%;
     position: absolute;
     top: -11px;
     background: inherit;
     z-index: 2;
 }

 .partner__option {
     cursor: pointer;

     padding: 15px 28px;
     border-top: 1px solid $pale-lilac;

     font-size: $text-medium;
     color: $brownish-grey;
     line-height: 1.3;
 }

 .partner__option:hover {
     color: $carolina-blue;
 }

 .partner__profile {
     display: flex;
     align-items: center;
 }

 .partner__profile__pic {
    position: relative;

    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 10px;


    background-size: cover;
    background-position: 50% 50%;
 }

 .partner__profile__name {
     line-height: 1.3;
     font-size: $text-medium;
     color: $greyish-brown;
 }

 .partner__profile__tasks {
    position: relative;

    padding-left: 20px;
    margin-left: 5px;

    color: $brown-grey;
    font-style: italic;
 }

 .partner__profile__tasks:before {
    content: '';

    position: absolute;
    top: 50%;
    left: 0;

    width: 8px;
    height: 1px;

    transform: translateY(-50%);

    background: $brown-grey;
}

 .partner__profile__role {
    line-height: 1.3;
    color: $brownish-grey;
    font-size: $text-small;
 }

 .partner__actions {
     display: flex;
     align-items: center;
 }

 .partner__action {
     cursor: pointer;

     margin-right: 15px;
 }

 .partner__action:last-child {
    margin-right: 0px;
}

.partner__action__options svg {
    fill: $brownish-grey;
}

.partner.has-options .partner__action__options svg {
    fill: $electric-blue;
}


// Sign block

.signatures {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.sign {
    padding: 15px;
    width: calc(33.33% - 20px);
    margin-top: 20px;

    background: $white;
}

.sign__title {
    font-size: $text-large;
    color: $greyish-brown;
}

.sign__action {
    display: flex;
    align-items: center;

    margin-top: 10px;
}

.sign__people {
    display: flex;
    align-items: center;

    margin-left: 10px;
}

.sign__people__text {
    margin-right: 6px;
    color: $brownish-grey;
    font-size: $text-xsmall;
    font-style: italic;
}

.sign__people__profile {
    position: relative;

    width: 18px;
    height: 18px;
    border-radius: 50%;
    margin-right: 5px;


    background-size: cover;
    background-position: 50% 50%;
}

// Uploads blocks
.uploads {
    margin: 35px 0;
}

.uploads__item {
    display: block;

    margin-top: 20px;
}

.uploads__item:first-child {
    margin-top: 0;
}


// Bloc offers
.offers {
    margin-top: $space-medium;
}

.offers__title {
    display: flex;
    justify-content: space-between;
}

.offers__title__new {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.offers__title__new__text {
    margin-left: 10px;
    text-transform: uppercase;
    color: $carolina-blue;
    font-size: $text-xsmall;
}

.offers__title__text {
    text-transform: uppercase;
    font-size: $text-large;
    color: $greyish-brown;
}

// Folder
.folders {
    margin-top: $space-small;
}

.folder__group {
    display: flex;
    align-items: center;

    height: 50px;
    border-bottom: 1px solid $pale-lilac;

    background: $pale-grey;
}

.folder__title {
    padding-left: 10px;

    font-size: $text-large;
    color: $greyish-brown;
    line-height: 1;
    letter-spacing: 0.1em;
}

.folder__title__infos {
    position: relative;

    padding-left: 15px;

    color: $brown-grey;
    font-style: italic;
    font-size: $text-medium;
}

.folder__title__infos:before {
    content: '';

    position: absolute;
    top: 50%;
    left: 0;

    width: 8px;
    height: 1px;

    transform: translateY(-50%);

    background: $brown-grey;
}

// Folders columns
.folder__breadcrumb {
    display: flex;
    align-items: center;

    width: 100%;
    height: 34px;
    border-bottom: 1px solid $pale-lilac;

    color: $brownish-grey;
    font-size: $text-xsmall;
    text-transform: uppercase;
}

.folder__line {
    display: flex;
    align-items: center;

    width: 100%;
    height: 50px;
    border-bottom: 1px solid $pale-lilac;

    color: $brownish-grey;
    font-size: $text-large;
}

.folder__name {
    display: flex;

    width: 30%;
    padding-left: 10px;

    color: $greyish-brown;
}

.folder__date, .folder__pending, .folder__refused, .folder__interested {
    position: relative;

    display: flex;
    align-items: center;

    width: 15%;
    height: 100%;
}

.folder__date {
    font-size: $text-medium;
}

.folder__breadcrumb .folder__date {
    font-size: $text-xsmall;
}

.folder__profile {
    z-index: 3;

    display: block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border:$white 3px solid;


    background-size: cover;
    background-position: 50% 50%;
}

.folder__profile:nth-child(2) {
    z-index: 2;
    margin-left: -12px;
}

.folder__profile:nth-child(3) {
    z-index: 1;
    margin-left: -12px;
}

.folder__more-people {
    margin-left: 8px;
}

.folder__actions {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;

    height: 100%;
}

.folder__actions__check {
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 14px;
    height: 14px;
    margin-left: 25px;
    margin-right: 10px;
    border: 1px solid $brownish-grey;
}

.folder__actions__check__svg {
    fill: $brownish-grey;

    opacity: 0;
    visibility: hidden;
}

.folder__actions__check.is-checked
.folder__actions__check__svg {
    opacity: 1;
    visibility: visible;
}

.folder__actions__trash {
    cursor: pointer;

    margin-left: 10px;
}

.folder__arrow--filter-up {
    cursor: pointer;

    position: relative;

    display: block;

    transform: translateY(-1px) translateX(-2px) rotate(180deg);
}

.folder__arrow--filter-down {
    cursor: pointer;

    position: relative;

    display: block;

    margin-left: 5px;
}

.folder__date .folder__arrow--filter-up {
    transform: translateX(-1px) rotate(180deg);
}