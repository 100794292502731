.Popin__center.admin {
	padding:30px 15px;
	overflow-y:hidden;
	overflow-x:hidden;

	.Popin__content {
		width:100%;
		padding-left:3%;
		padding-right:3%;
		padding-bottom:105px;
	}

	.Popin__title {
		text-align:left;

		h4 {
			padding-left:30px;
		}
	}
}

.AdminPopin {
	.AdminPopin__content {
		.AdminPopin__tabs {
			width:100%;
			margin:0 0 30px 0;
			padding:0;
			text-align:center;

			li {
				display:inline-block;
				margin:0 20px 0 0;
				list-style-type:none;
				cursor:pointer;

				&:last-child {
					margin-right:none;
				}

				&.active {
					color:$ubikonBlue;
				}
			}
		}

		.AdminPopin__blocks {
			.AdminPopinBlock {
				margin:0 0 20px 0;
				padding:25px 60px;
				border-style:solid;
				border-width:1px 0 0 0;
				border-color:$paleGrey;
				background-color:$lighterGray;

				&.inner {
					padding:10px 30px 0 30px;

					&:first-child {
						margin-top:10px;
					}
				}

				&.half {
					display:inline-block;
					vertical-align:top;
					width:50%;
				}

				.AdminPopinBlock__header {
					h4 {
						margin-bottom:5px;
						font-size:12px;
						color:$brownishGrey;
						letter-spacing:0.5px;
					}
				}

				.AdminPopinBlock__content {
					.Type__title {
						margin-top:15px;
						text-transform:uppercase;
						font-size:$fontSizeMedium;
						letter-spacing:0.5px;
					}

					.LandInfosIcons {
						display:inline-block;
						vertical-align:middle;
						margin:20px 40px 15px 0;
						font-size:$fontSize12;
					}

					.Land__price {
						display:inline-block;
						vertical-align:middle;
						width:calc(50% - 40px);
						margin:20px 0 15px 0;
						font-size:$fontSize12;
					}

					.Photos {
						display:inline-block;
						width:100%;
						font-size:0;

						img {
							width:auto;
							max-height:80px;
							margin-right:15px;

							&:last-child {
								margin-right:0;
							}
						}
					}

					.RoomsCount {
						display:inline-block;
						margin:10px 20px 0 0;

						&:last-child {
							margin-right:0;
						}
					}

					.Description {
						width:75%;
						margin-top:30px;
						font-size:$fontSize12;
						color:$brownishGrey;
						line-height:18px;
					}

					.Property__item__wrapper {
						display:inline-block;
						width:100%;
						margin:10px 0 25px 0;

						.LandInfosIcons {
							display:inline-block;
						}

						.Property__item__price {
							display:inline-block;
							margin-left:25px;
							font-size:$fontSizeMedium;
						}

						.AdminPopinBlock {
							&.inner {
								border:none;

								&:first-child {
									border-style:solid;
									border-width:1px 0 0 0;
									border-color:$paleGrey;
								}
							}
						}

						.Photos {
							margin-top:15px;
						}
					}
				}
			}
		}

		.AdminPopin__buttons {
			position:absolute;
			bottom:0;
			left:0;
			width:100%;
			padding:30px 0;
			border-style:solid;
			border-width:1px 0 0 0;
			border-color:$paleGrey;
			text-align:center;
			background-color:white;

			.SubmitButton {
				padding:15px 30px !important;
				text-transform:uppercase;

				&:hover {
					border-color:$greenColor;
					background-color:$greenColor;
					color:white;
				}

				&--loading {
					padding-top:15px !important;
					padding-bottom:15px !important;

					&.deny {
						margin-left:50px;
						border-color:$redOrange;
						background-color:$redOrange;
						color:white;
						text-transform:uppercase;
					}
				}

				&.deny {
					margin-left:50px;
					border-style:solid;
					border-width:1px;
					border-color:$blackColor;
					background-color:white;
					color:$blackColor;

					&:hover {
						border-color:$redOrange;
						background-color:$redOrange;
						color:white;
					}
				}
			}
		}
	}

	.AdminDenyForm {
		padding:20px;
		border-style:solid;
		border-width:1px 0 0 0;
		border-color:$paleGrey;
		background-color:$lighterGray;

		.AdminDenyForm__Header {
			margin-bottom:30px;

			.User {
				display:inline-block;
				vertical-align:middle;
				width:100%;
				margin-top:15px;

				.Avatar {
					display:inline-block;
					vertical-align:middle;
					@include avatar(30px);
				}

				p {
					display:inline-block;
					vertical-align:middle;
					margin-left:10px;
					font-size:$fontSize12;
					line-height:18px;
				}
			}
		}

		label.Label {
			color:$brownishGrey;
			font-size:$fontSize12;
			text-transform:uppercase;
		}

		textarea {
			width:100%;
			min-height:250px;
			margin-top:25px;
			resize:none;
		}

		.WhiteButton {
			margin-right:50px;
			padding:15px 30px !important;
			text-transform:uppercase;
		}
	}
}