.PropertiesIndex {
	height:100%;
	padding:30px;
	background-color:white;

	.PropertiesIndex__list {
		position:relative;
		display:inline-block;
		width:100%;
		margin:30px 0 0 0;

		.MapList {
			width:calc(55% - 15px);
		}
	}

	.PropertiesMap {
		position:absolute;
		top:0;
		right:0;
		width:calc(45% - 15px);
		height:100%;
		overflow:hidden;

		.HideMap__button {
			position:absolute;
			top:20px;
			right:20px;
			padding:10px;
			background-color:white;
			z-index:9;
		}
	}
}

.Properties__filter__popin {
	width:85% !important;
	max-height:85vh !important;

	.Popin__content {
		max-height:calc(100vh - 190px) !important;

		.filters-modal__ranges, .filters-modal__rooms {
			margin-right:60px;
		}

		.filters-modal__needs {
			margin-bottom:20px;

			.filters-modal__needs__title {
				display:inline-block;
				width:100%;
			}

			.filters-modal__needs__list {
				display:inline-block;
				width:100%;
				max-height:calc(100vh - 320px);
				overflow-y:scroll;
				overflow-x:hidden;

				&::-webkit-scrollbar {
					display:none;
				}

				.filters-modal__needs__checkbox  {
					display:inline-block;
					width:45%;
				}
			}
		}
	}
}