.ChatCenterWrapper {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	padding:0 20px;
	background-color:white;
	z-index:9;

	.Close__chat {
		position:absolute;
		top:20px;
		right:20px;
		display:inline-block;
		vertical-align:middle;

		.ImageButton, .TextButton {
			vertical-align:middle;
		}

		.TextButton {
			margin-top:-2px;
			color:$carolinaBlue;

			&:hover {
				color:$ubikonBlue;
			}
		}
	}

	h1 {
		width:100%;
		margin:25px 0;
		font-size:$fontSize36;
		font-weight:normal;
		font-stretch:normal;
		font-style:normal;
		line-height:normal;
		letter-spacing:1.07px;
		color:$ubikonBlue;
		text-align:center;
	}

	.Conversation__header {
		position:relative;
		display:inline-block;
		width:100%;
		height:40px;
		margin:0 0 10px 0;
		padding:0;
		border-style:solid;
		border-width:0 0 1px 0;
		border-color:$paleGrey;

		.Conversation__users {
			display:inline-block;
			vertical-align:middle;
			margin:0 5% 0 0;
			padding:0;

			.Conversation__user {
				@include avatar(30px);
				position:relative;
				display:inline-block;
				vertical-align:middle;
				margin:0 0 0 10px;
			}

			.Conversation__users__count {
				display:inline-block;
				vertical-align:middle;
				margin:0 0 0 20px;
				padding:0;
				font-size:$fontSize13;
				color:$mediumGray;
			}
		}

		.Conversation__files {
			display:inline-block;
			vertical-align:middle;
			margin:0 20px;

			.Conversation__files__icon {
				display:inline-block;
				vertical-align:middle;
				margin:0 10px 0 0;
			}

			.Conversation__files__count {
				display:inline-block;
				vertical-align:middle;
				font-size:$fontSize13;
				color:$mediumGray;
			}
		}

		.Conversation__expand__btn {
			display:inline-block;

			&.expanded {
				transform:rotate(180deg);
			}
		}

		.Conversation__actions {
			float:right;
			display:inline-block;
			vertical-align:middle;

			.Add__user__image__btn, .Add__user__text__btn {
				display:inline-block;
				vertical-align:middle;
			}

			.Add__user__image__btn {
				width:11px;
				margin:0 9px 0 0;
			}

			.Add__user__text__btn {
				font-size:$fontSizeSmall;
				color:$carolinaBlue;

				&:hover {
					color:$ubikonBlue;
				}
			}

			.Actions__btn {
				margin:0 0 0 50px;
			}
		}

		.Conversation__header__content {
			position:absolute;
			top:0;
			bottom:0;
			left:0;
			display:inline-block;
			width:100%;
			height:auto;
			margin:58px 0 0 0;
			padding:0 20px;
			overflow-y:scroll;
			background-color:$lighterGray;
			transition:all 0.8s ease;
			box-shadow:0 4px 11px 0 rgba(0, 75, 255, 0.1);
			z-index:9;

			&.expanded {
				bottom:-280px;
				padding-top:10px;
			}

			&::-webkit-scrollbar {
				display:none;
			}

			.Conversation__dropdown__title {
				font-size:$fontSizeMedium;
				font-weight:normal;
				font-stretch:normal;
				font-style:normal;
				line-height:normal;
				letter-spacing:1.39px;
				color:$greyishBrownColor;
				text-transform:uppercase;
			}

			.Conversations__users__list {

			}

			.Conversation__files__list {
				display:inline-block;
				width:100%;
				margin:40px 0 20px 0;

				table.Files__list {
					width:100%;
					margin:20px 0 0 0;

					tr {
						border-style:solid;
						border-width:1px 0;
						border-color:$paleGrey;

						&:nth-child(odd) {
							background-color:white;
						}

						&:nth-child(even) {
							background-color:$bodyBackgroundColor;
						}

						td {
							vertical-align:middle;
							font-size:$fontSizeMedium;
							font-weight:normal;
							font-stretch:normal;
							font-style:normal;
							line-height:normal;
							letter-spacing:0.54px;
							color:$greyishBrownColor;
						}
					}

					.File__preview {
						width:80px;
						height:50px;
						background-repeat:no-repeat;
						background-position:center;
						background-size:cover;
					}

					.File__user {
						display:inline-block;
						vertical-align:middle;

						.File__user__avatar {
							@include avatar(25px);
							display:inline-block;
							vertical-align:middle;
						}

						.File__user__username {
							display:inline-block;
							vertical-align:middle;
							margin:0 0 0 10px;
						}
					}

					.File__action {
						display:inline-block;
						margin:0 5px;
					}
				}
			}
		}
	}

	.Conversation {
		height:60%;
		padding-bottom:20px;
		overflow-y:scroll;

		&::-webkit-scrollbar {
			width:1px;
		}

		&::-webkit-scrollbar-track {
			background:$lighterGray;
		}

		&::-webkit-scrollbar-thumb {
			background:$ubikonBlue;
		}

		.Spinner {
			padding:0;

			.Spin {
				width:20px;
				height:20px;
				border-width:1px;
			}
		}
	}

	.MessageForm__wrapper {
		position:absolute;
		bottom:0;
		left:2.5%;
		width:95%;
		border-style:solid;
		border-width:1px 0 0 0;
		border-color:$paleGrey;
		background-color:white;

		.MessageForm {
			vertical-align:middle;
			width:100%;
			margin:15px 0;
			padding:18px;
			border-radius:10px;
			background-color:$lighterGray;
			font-size:0;

			.MessageForm__attachment {
				display:inline-block;
				vertical-align:middle;
				width:10%;
				margin:0;

				label {
					position:relative;
					cursor:pointer;

					.MessageForm__attachment__btn {
						&:hover, &.is-selected {
							path {
								fill:$ubikonBlue;
							}
						}
					}

					.MessageForm__files__count {
						position:absolute;
						top:-30px;
						right:-5px;
						font-size:$fontSizeSmall;
						font-weight:700;
						color:$ubikonBlue;
					}
				}

				img {
					max-width:12px;
				}
			}

			.MessageForm__file_input {
				display:none;
			}

			form {
				display:inline-block;
				vertical-align:middle;
				width:90%;

				.MessageForm__textarea {
					display:inline-block;
					vertical-align:middle;
					width:85%;
					padding:5px;
					border:none;
					background-color:transparent;
					resize:none;
					outline:none;
					font-size:$fontSizeMedium;
					font-weight:normal;
					font-stretch:normal;
					font-style:normal;
					line-height:1.79;
					letter-spacing:0.5px;
					color:$brownishGrey;

					&::-webkit-scrollbar {
						display:none;
					}
				}

				.MessageForm__submit {
					float:right;
					display:inline-block;
					vertical-align:middle;
					width:15%;
					margin:10px 0 0 0;
					text-align:right;
				}
			}
		}
	}
}