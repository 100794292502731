.ProjectsListFilters__buttons {
	display:inline-block;
	width:100%;
	margin:0 0 20px 0;

	button {
		float:right;
		margin:0 25px 0 0;
	}
}

.ProjectsListFilters__form {
	display:inline-block;
	vertical-align:middle;
	width:0;
	opacity:0;
	overflow:hidden;
	transition:all 0.8s ease;

	&.visible {
		width:80%;
		opacity:1;
		overflow:visible;
	}

	.UbikonForm {
		display:inline-block;
		vertical-align:middle;
		width:100%;

		.FormRow {
			display:inline-block;
			vertical-align:middle;
			width:45%;
			margin:0;
			padding:0;

			label {
				display:none;
			}

			&:first-child {
				margin-right:2.5%;
			}
		}
	}
}