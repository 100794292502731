.Organization__content {
	.Column__left {
		.Organization__name {
			display:none;
		}
	}

	.Column__right {
		.Organization__header {
			display:none;
		}
	}
}

@media only screen and (min-width: $mobileMinWidth) and (max-width: $mobileMaxWidth) {
	.Organization {
		height:100%;
	}

	.UserProfile, .Organization {
		.Organization__Detail {
			height:100%;
			padding-right:1px;
			overflow-x:scroll;
			@include customScrollbar();

			.Organization__nav {
				padding-top:12px;

				.Edit__btns {
					.TextButton {
						display:none;
					}
				}
			}

			.Organization__header {
				display:none;
			}

			.Organization__content {
				width:100%;
				margin-top:12px;
				margin-left:0;
				padding:0 12px;

				.Column__left {
					width:100%;
					padding-left:22px;

					.Organization__Logo {
						margin:0 0 32px 0;
						width:120px;
						height:120px;
					}

					.Organization__name {
						display:inline-block;
						width:100%;

						h1 {
							margin-bottom:32px;
							font-size:$fontSize34;
							font-weight:normal;
							font-stretch:normal;
							font-style:normal;
							line-height:normal;
							letter-spacing:1.31px;
							color:$greyishBrownColor;
						}
					}

					.Organization__infobox {
						padding-left:15%;
					}
				}

				.Column__right {
					width:100%;
					margin:0;
					padding-left:calc(15% + 22px);

					.Organization__description {
						margin-top:12px;
					}

					.Organization__header {
						display:inline-block;
						width:100%;
						margin-top:25px;

						.Organization__cover {
							width:100%;
						}
					}
				}

				.Profile__bottom {
					margin-bottom:30px;
				}

				.Organization__members {
					.Members__list {
						li {
							width:100%;
							margin:0 0 12px 0;
						}
					}

					.Edit__btn__wrapper {
						margin-top:24px;
						margin-bottom:24px;
					}
				}
			}
		}
	}
}