.UserProfile {
	display:inline-block;
	width:100%;
	height:calc(100vh - 60px - 80px - 20px);
	padding-right:1px;
	overflow-y:scroll;
	overflow-x:hidden;
	background-color:white;

	@include customScrollbar();

	.Organization__content {
		.Column__right {
			.Organization__infobox {
				textarea {
					min-height:222px;
					resize:none;

					@include customScrollbar();
				}
			}
		}

		.Column__full {
			.Cancel__btn {
				margin-left:20px;

				&:hover {
					color:$ubikonBlue;
				}
			}
		}
	}

	.Profile__bottom {
		display:inline-block;
		vertical-align:middle;
		width:100%;
		min-height:60px;
		margin:40px 0;
		padding:0 !important;
		border-style:solid;
		border-width:1px 0;
		border-color:$paleGrey;
		text-align:center;

		.Profile__bottom__item {
			display:inline-block;
			vertical-align:middle;
			padding:20px;
			border-style:solid;
			border-width:0 1px 0 0;
			border-color:$paleGrey;
			font-size:$fontSizeMedium;
			text-align:center;

			&:first-child {
				border-width:0 1px;
			}

			&.project-request {
				span {
					color:$redOrange;

					&:before {
						content:url('assets/svg/icon-pending.svg');
						display:inline-block;
						vertical-align:middle;
						margin-right:8px;
					}
				}
			}

			span {
				color:$ubikonBlue;
			}
		}
	}
}