.Projects__empty__notice {
	width:100%;
	margin:120px 0 0 0;
	text-align:center;
	font-size:$fontSize12;
	color:$brownGrey;
}

.ProjectsList {
	display:inline-block;
	width:100%;

	.ProjectListItem {
		position:relative;
		display:inline-block;
		vertical-align:top;
		width:31.7%;
		min-height:350px;
		margin:$defaultMargin 2% $defaultMargin 0;
		padding:0 0 12% 0;
		border:$defaultBorder;
		border-radius:10px;
		background-color:$projectItemBackgroundColor;

		&:nth-child(3n) {
			margin-right:0;
		}

		a {
			text-decoration:none;
			color:$blackColor;
		}

		.ProjectListItem__top {
			width:85%;
			margin:30px auto 0 auto;

			.ProjectListItem__title {
				width:100%;
				font-size:$fontSize18;
				color:$greyishBrownColor;
			}

			.ProjectListItem__details {
				margin:25px 0 0 0;

				p {
					margin:0;
					padding:0;
				}

				.ProjectListItem__location {
					display:inline-block;
					vertical-align:middle;
					width:100%;

					img {
						display:inline-block;
						vertical-align:middle;
						margin-right:5px;
					}

					span {
						display:inline-block;
						vertical-align:middle;
					}
				}

				.ProjectListItem__status {
					display:inline-block;
					vertical-align:middle;
					color:inherit;

					&--pending {
						@extend .ProjectListItem__status;
						color:red;
					}

					img {
						display:inline-block;
						vertical-align:middle;
						margin-right:5px;
					}

					span {
						display:inline-block;
						vertical-align:middle;
					}
				}
			}

			.PartnersList {
				margin-top:20px;

				.PartnersListItem {
					height:25px;
					margin:0;
					padding:0;

					.PartnersListItem__avatar {
						width:25px;
					}
				}
			}
		}

		.ProjectListItem__bottom {
			display:inline-block;
			position:absolute;
			bottom:0;
			width:100%;
			height:40%;
			border-radius:0 0 $borderRadiusSmall $borderRadiusSmall;
			background-size:cover;
			background-position:center;
			background-repeat:no-repeat;
		}

		.ProjectListItem__add {
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			border:none;
			text-align:center;
			background-color:transparent;
			cursor:pointer;

			.ProjectListItem__add__icon {

			}

			.ProjectListItem__add__text {
				display:inline-block;
				width:100%;
				margin:20px 0 0 0;
			}
		}
	}
}