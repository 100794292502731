.ProjectsPartnersForm {
	display:inline-block;
	width:100%;
	vertical-align:top;
	margin-top:25px;

	.Column__left {
		display:inline-block;
		width:35%;
		margin-right:5%;
		vertical-align:top;

		h3 {
			margin:0 0 30px 0;
			color:$brownishGrey;
			text-transform:uppercase;
		}

		p {
			margin:0 0 50px 0;
		}
	}

	.Column__right {
		display:inline-block;
		width:60%;
		vertical-align:top;

		.FormRow {
			display:inline-block;
			vertical-align:top;
			width:47.5%;
			margin:0 0 10px 0;
			padding:0;

			&:first-child {
				margin-right:2.5%;
			}

			&:last-child {
				margin-left:2.5%;
			}

			label {
				display:inline-block;
				width:100%;
				margin:0 0 10px 0;
				font-size:$fontSize12;
				color:$brownishGrey;
				text-transform:uppercase;
			}
		}
	}
}