input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active {
	-webkit-animation:autofill 0s forwards;
	animation:autofill 0s forwards;
}

@keyframes autofill {
    100% {
        background:$inputBackgroundColor;
        color:inherit;
    }
}

@-webkit-keyframes autofill {
    100% {
        background:$inputBackgroundColor;
        color:inherit;
    }
}

.Label {
	opacity:1;

	&--required {
		@extend .Label;

		&:after {
			content:'*';
		}
	}

	&.repeat {
		font-weight:600;
	}
}

.NumberInput, .Textarea, .TextInput {
	padding:10px;
	border:none;
	background-color:$inputBackgroundColor;
	outline:none;
}

.TextInput__attached {
	position:relative;

	&.is-empty {
		span {
			display:none;
		}

		svg {
			display:none;
		}
	}

	&.is-valid {
		svg {
			path {
				fill:$greenColor;
			}
		}
	}

	.TextInput {
		display:inline-block;
		width:100%;
	}

	span {
		position:absolute;
		top:12px;
		left:30px;
		font-size:$fontSize8;
		text-transform:uppercase;
		color:$brownGrey;
	}

	svg {
		position:absolute;
		top:50%;
		right:30px;

		path {
			fill:$redOrange;
		}
	}
}

.SingleDatePicker, .Select {
	width:100%;
}

.Select {
	outline:none;

	.css-yk16xz-control, .css-1pahdxg-control {
		padding:12px 0;
		border-color:$inputBackgroundColor;
		border-radius:0;
		background-color:$inputBackgroundColor;
		font-size:$fontSize12;

		&:hover {
			border-color:$brownishGrey;
		}
	}

	.css-yk16xz-control {
		position:relative;
		cursor:pointer;
		max-height:50px;
		overflow:hidden;

		div {
			&:last-child {
				margin-right:10px;
			}
		}
	}

	.css-26l3qy-menu {
		position:absolute;
		top:100%;
		left:0;
		z-index:9;

		.css-4ljt47-MenuList {
			border:none;
			background-color:$inputBackgroundColor;
			-webkit-box-shadow:none;
			-moz-box-shadow:none;
			box-shadow:none;

			div {
				border:none;
				cursor:pointer;
				font-size:$fontSizeMedium;
				-webkit-box-shadow:none;
				-moz-box-shadow:none;
				box-shadow:none;

				&:hover {
					color:$carolinaBlue;
				}
			}
		}
	}

	.css-1laao21-a11yText, .css-1okebmr-indicatorSeparator {
		display:none !important;
	}
}

.RadioGroup {
	.Radio {
		padding:5px ;
	}
}

.Radio {
	.Label {
		cursor:pointer;
	}
}

.CheckboxGroup {
	margin:10px 0 20px 0;

 	.CheckboxGroup__item {
		display:inline-block;
		margin:10px 10px 10px 0;

		&:last-child {
			margin-right:0;
		}

		.Label {
			padding:15px 17px;
			border-style:solid;
			border-width:1px;
			border-color:$brownGrey;
			border-radius:5px;
			cursor:pointer;
			color:$brownGrey;

			&:hover {
				border-color:$ubikonBlue;
				color:$ubikonBlue;
			}
		}

		.Checkbox {
			visibility:hidden;
			height:0;
			margin:0;
			padding:0;
		}

		&--selected {
			@extend .CheckboxGroup__item;

			.Label {
				border-color:$ubikonBlue;
				color:$ubikonBlue;
			}
		}
	}
}

button.SubmitButton {
	@include submitButton();

	&--loading {
		@extend button.SubmitButton;
		position:relative;
		padding-left:40px;

		&:before {
			@include spinner(1px, 20px);
		}
	}

	&.NextButton, &.PreviousButton {
		margin:0 10px 0 0;
		border-style:solid;
		border-width:1px;
		border-color:$lightGray;
		background-color:white;
		color:$blackColor;

		&:hover {
			color:$ubikonBlue;
		}

		&:last-child {
			margin-right:0;
		}
	}
}

.FileInput {
	vertical-align:middle;

	.Label {
		display:inline-block;
		width:100%;
		padding:20px 0;
		background-color:$inputBackgroundColor;
		border:dashed 1px $ubikonLightBlue;
		text-align:center;
		color:red;
		cursor:pointer;
	}

	input[type="file"] {
		display:none;
	}

	.FileInput__widget--white-button {
		.Label {
			width:100%;
			margin:0;
			padding:0;
			border:none;
			font-size:$fontSizeSmall;
			letter-spacing:1.39px;
			color:$brownishGrey;
			background-color:transparent;
			text-align:left;
			cursor:initial;

			&.Upload__btn {
				display:inline-block;
				width:100%;
				padding:20px;
				border:dashed $greyishBrownColor 1px;
				letter-spacing:1.19px;
				text-transform:uppercase;
				text-align:center;
				cursor:pointer;
				color:$greyishBrownColor;

				&:hover {
					color:$brownishGrey;
				}
			}
		}

		.FileInput__preview {
			display:inline-block;
			max-width:75%;
			margin:0 20px 0 0;

			.FileInputPreview__item {
				display:inline-block;
				width:45%;
				background-color:$paleGrey;
				margin:10px 10px 5px 0;
				padding:15px;

				img {
					display:none;
				}

				.Preview {
					display:inline-block;
					vertical-align:middle;

					img {
						display:inline-block;
						vertical-align:middle;
						margin:0 10px 0 0;
					}

					span {
						display:inline-block;
						vertical-align:middle;
						max-width:85%;
						font-size:$fontSize13;
						letter-spacing:0.5px;
						color:$greyishBrownColor;
					}
				}
			}
		}
	}

	.FileInput__preview {
		display:inline-block;
		width:100%;
		margin:10px 0;
		font-size:0;

		.FileInputPreview__item {
			display:inline-block;
			width:25%;
			margin:0 10px 0 0;

			.FileInputPreview__item__image {
				width:100%;

				&--pdf {
					@extend .FileInputPreview__item__image;
					width:20%;
				}
			}
		}
	}
}

.FileInputErrors {
	display:inline-block;
	vertical-align:middle;
	width:100%;
	margin:10px 0;
	padding:15px 0;
	background-color:$redOrange;

	.Error__msg {
		display:inline-block;
		vertical-align:middle;
		width:95%;
		padding:0 0 0 10px;
		font-size:$fontSize12;
		color:white;
	}

	.Error__close {
		display:inline-block;
		vertical-align:middle;

		svg {
			g {
				fill:white;
			}

			&:hover {
				g {
					fill:$lightGray;
				}
			}
		}
	}
}

.PartnersSelect {
	width:100%;

	.PartnersSelect__input {
		position:relative;
		width:100%;
		min-height:55px;
		padding:20px;
		background-color:$inputBackgroundColor;
		text-align:left;
		font-size:$fontSize12;

		&:hover {
			padding:19px;
			border-style:solid;
			border-width:1px;
			border-color:$brownishGrey;

			&:after {
				right:19px;
			}
		}

		&:after {
			content:url('assets/svg/icon-arrow-down.svg');
			position:absolute;
			top:50%;
			right:20px;
			transform:rotate(0deg) translate(0, -50%);
		}

		&--expanded {
			@extend .PartnersSelect__input;

			&:after {
				transform:rotate(180deg) translate(0, 50%);
			}
		}
	}

	.PartnersSelect__list__wrapper {
		position:relative;
		width:100%;

		.PartnersSelect__list {
			position:absolute;
			top:10px;
			left:0;
			width:100%;
			max-height:120px;
			overflow-y:scroll;
			z-index:9;
			margin:0;
			padding:0;
			background-color:$inputBackgroundColor;

			&::-webkit-scrollbar {
				display:none;
			}

			.PartnersSelect__list__item {
				display:inline-block;
				width:100%;
				padding:10px;
				font-size:0;
				cursor:pointer;

				&:hover {
					color:$carolinaBlue;
				}

				&--selected {
					@extend .PartnersSelect__list__item;
					color:$ubikonBlue;
				}

				.PartnersSelect__checkbox {
					display:inline-block;
					width:calc(5% - 30px);
					margin:0 30px 0 0;
					vertical-align:middle;
				}

				.PartnersSelect__label {
					display:inline-block;
					width:84%;
					margin:0;
					vertical-align:middle;

					.PartnersSelect__avatar {
						@include avatar(25px);
						display:inline-block;
						margin:0 10px 0 0;
						vertical-align:middle;
					}

					.PartnersSelect__username {
						display:inline-block;
						vertical-align:middle;

						&:hover {
							color:$carolinaBlue;
						}
					}
				}
			}
		}
	}

	.PartnersSelect__selected__avatars {
		display:inline-block;
		width:100%;
		margin:10px 0 20px 0;

		.PartnersSelect__selected__partner {
			@include avatar(30px);
			display:inline-block;
			margin-right:10px;
		}
	}
}

.PartnersSearch {
	.PartnersSearch__input {
		position:relative;
		display:inline-block;
		width:50%;

		input {
			width:100%;
		}
	}

	.PartnersSearch__list {
		position:absolute;
		top:20px;
		width:100%;
		padding:0;
		background-color:$inputBackgroundColor;
	}

	.PartnersSearch__selected_list {
		display:inline-block;
		width:100%;
		margin:10px 0;
		padding:0;
		font-size:0;

		.PartnersSearch__list__item {
			width:30%;
			margin:3% 3% 0 0;
			background-color:$inputBackgroundColor;

			&:nth-child(3n) {
				margin-right:0;
			}
		}
	}

	.PartnersSearch__list__item {
		display:inline-block;
		width:100%;
		padding:10px;
		list-style-type:none;
		vertical-align:middle;
		cursor:pointer;

		.Partner__avatar {
			@include avatar(30px);
			display:inline-block;
			margin-right:10px;
			vertical-align:middle;
		}

		.Partner__infos {
			display:inline-block;
			width:calc(100% - 40px);
			vertical-align:middle;

			.Partner__username {
				display:inline-block;
				vertical-align:middle;
			}

			.Partner__role {
				display:inline-block;
				vertical-align:middle;
				font-style:italic;
				color:$mediumGray;

				&:before {
					content:'.';
					display:inline-block;
					width:10px;
					height:10px;
					margin:0 5px;
					border-style:solid;
					border-width:0 0 1px 0;
					border-color:$mediumGray;
					color:transparent;
				}
			}

			.Partner__organization {
				display:inline-block;
				width:100%;
			}
		}

		&--selected {
			@extend .PartnersSearch__list__item;
			cursor:initial;

			.Partner__infos {
				width:65%;
			}

			.Partner__actions {
				display:inline-block;
				margin:10px 0 0 0;
				font-size:10px;
				float:right;
			}
		}
	}
}

.Custom__checkbox {
	display:inline-block;
	margin:0 10px 0 0;

	input[type="checkbox"] {
		display:none;
	}

	.Checkbox__button {
		width:15px;
		height:15px;
		border-style:solid;
		border-width:1px;
		border-color:$brownishGrey;
		background-color:transparent;
		outline:none;
		cursor:pointer;

		&--checked {
			@extend .Checkbox__button;
			position:relative;
			border-color:$ubikonBlue;

			&:before {
				content:url('assets/svg/icon-check-blue.svg');
				position:absolute;
				top:50%;
				left:50%;
				transform:translate(-50%, -50%);
			}
		}

		&.disabled {
			border-color:$brownishGrey;
			cursor:not-allowed;

			&:hover {
				border-color:$brownishGrey;
			}
		}

		&:hover {
			border-color:$ubikonBlue;
		}
	}

	.Checkbox__label {
		display:inline-block;
		margin:0 0 0 10px;
		cursor:pointer;

		&:hover {
			color:$ubikonBlue;
		}
	}
}

.FormRow__repeat {
	button.TextButton {
		&:hover {
			color:$ubikonBlue;
		}

		&.RepeatedField__action {
			&.remove {
				float:right;
			}
		}
	}
}

.DragAndDrop {
	position:relative;
	display:inline-block;
	width:100%;
	margin-top:10px;

	.DragWrapper {
        border:dashed $greyishBrownColor 1px;
        background-color:white;
        position:absolute;
        top:0;
        bottom:0;
        left:0; 
        right:0;
        z-index:9999;

        .DragWrapper__inner {
            position:absolute;
            top:50%;
            right:0;
            left:0;
            color:$greyishBrownColor;
			text-align:center;
			text-transform:uppercase;
            font-size:$fontSizeSmall;
            letter-spacing:1.19px;

            div {
				position:absolute;
				top:50%;
				left:50%;
				transform:translate(-50%, -50%);
            }
        }
	}
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
	-webkit-appearance: none; 
	margin: 0; 
}