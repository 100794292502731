.ProjectOfferForm {
	.Form__title {

	}

	.Form__wrapper {
		display:inline-block;
		margin-top:40px;

		.FormRow {
			display:inline-block;
			width:100%;
			margin:0 0 20px 0;

			label {
				display:inline-block;
				width:100%;
				margin:0 0 12px 0;
				font-size:$fontSizeSmall;
				line-height:normal;
				letter-spacing:1.39px;
				color:$brownishGrey;
				text-transform:uppercase;
			}

			input[type="text"], input[type="email"], input[type="password"], textarea {
				padding:20px 15px;
				font-size:$fontSizeMedium;
			}

			textarea {
				min-height:250px;
				resize:none;
			}

			.PartnersSearch__input {
				width:100%;

				input {
					position:relative;

				}

				&:before {
					content:url('assets/svg/icon-search.svg');
					position:absolute;
					top:20px;
					right:15px;
					z-index:88;
				}

				.PartnersSearch__list {
					top:100%;

					.PartnersSearch__list__item {
						width:49.5%;
						margin:0 1% 1% 0;
						background-color:$paleGreyColor;

						&:nth-child(even) {
							margin:0 0 1% 0;
						}
					}
				}
			}
		}

		.SubmitButton {
			margin:20px 0 40px;
		}
	}
}