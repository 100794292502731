.middle-block {
	&.size-small {
		/* PROJECTS LIST */
		.all-projects {
			.all-projects__list {
				.all-projects__create, .all-projects__project {
					max-width:100%;
					width:100%;
				}
			}
		}
	}

	&.size-medium {
		/* PROJECTS LIST */
		.all-projects {
			.all-projects__list {
				.all-projects__create, .all-projects__project {
					max-width:unset;
					width:calc(50% - 70px / 3);
				}
			}
		}

		/* PROJECT DETAIL */
		.ProjectDetail {
			.project {
				.project__head {
					.project__head__back {
						width:20%;
					}

					.project__head__title {
						width:60%;
						margin-left:20%;
					}

					.project__head__edit {
						width:15%;
					}
				}

				.project__infos {
					margin-top:50px;
				}
			}

			.project-views {
				.project-views__header {
					.project-views__header__nav {
						li {
							margin-right:70px;
						}
					}
				}
			}
		}

		/* CATALOGUE */
		.catalogue {
			.catalogue__wrapper {
				.catalogue__properties {
					.catalogue__properties__wrapper {
						.catalogue__property {
							width:100%;
						}
					}
				}
			}
		}
	}
}