.SearchBar {
	position:relative;
	display:inline-block;
	width:100%;
	min-height:40px;
	vertical-align:middle;
	padding-bottom:5px;
    border-style:solid;
    border-width:0 0 1px 0;
    border-color:$lightGray;
    font-size:0;

    &.no-border {
    	border:none;
    }

    .Search__cancel {
    	position:absolute;
    	top:50%;
    	right:15px;
    	transform:none;
    	width:auto;
    	z-index:1;
    	cursor:pointer;
    }

	.default {
		position:absolute;
		top:0;
		left:0;
		display:inline-block;
		width:100%;
		vertical-align:middle;
		padding:10px;
		border:none;
		background-color:$paleGreyColor;
		color:$brownGrey;

		&:focus {
			outline: 0 !important;
		}
	}

	button {
		position:absolute;
		top:50%;
		right:2%;
		transform:translate(0, -50%);
		display:inline-block;
		width:8%;
		height:14px;
		vertical-align:middle;

		img {
			width:auto;
			height:100%;
		}
	}
}