.CollectionsPopin {
	.Collection__select {
		label {
			margin:0 0 10px 0;
			font-size:$fontSize12;
			font-weight:normal;
			font-style:normal;
			font-stretch:normal;
			line-height:normal;
			letter-spacing:1.67px;
			color:$brownishGrey;
			text-transform:uppercase;
		}

		select {
			margin:0 0 30px 0;
		}

		.Spinner {
			margin-top:5px;
			padding-bottom:0;
		}

		.Button__wrapper {
			width:100%;
			margin-top:25px;
			text-align:center;

			.BlueButton {
				padding:15px 45px;

				&:hover {
					padding:14px 44px;
				}
			}
		}
	}

	.Collection__popin__separator {
		position:relative;
		margin:50px 0 30px 0;
		font-size:15px;
		font-weight:normal;
		font-style:normal;
		font-stretch:normal;
		line-height:normal;
		letter-spacing:0.58px;
		text-align:center;
		color:$brownishGrey;
		text-transform:uppercase;

		&:before, &:after {
			content:'';
			position:absolute;
			top:50%;
			width:45%;
			border-style:solid;
			border-width:1px 0 0 0;
			border-color:$paleGrey;
		}

		&:before {
			left:0;
		}

		&:after {
			right:0;
		}
	}

	.Collection__create {
		width:100%;
		text-align:center;

		h2 {
			width:100%;
			margin:0 0 25px 0;
			font-size:$fontSize24;
			font-weight:normal;
			font-style:normal;
			font-stretch:normal;
			line-height:normal;
			letter-spacing:0.92px;
			text-align:center;
			color:$greyishBrownColor;
		}

		button {
			padding:15px 45px;

			&:hover {
				border-color:$ubikonBlue;
				color:$ubikonBlue;
			}
		}
	}

	.Collection__success {
		width:100%;
		margin:30px 0;
		text-align:center;

		p {
			font-size:$fontSize16;
			color:$brownishGrey;
		}

		h2 {
			width:100%;
			margin:0 0 25px 0;
			font-size:$fontSize24;
			font-weight:normal;
			font-style:normal;
			font-stretch:normal;
			line-height:normal;
			letter-spacing:0.92px;
			text-align:center;
			color:$greyishBrownColor;
		}
	}

	.Collection__create__form {
		label {
			display:inline-block;
			margin:0 0 5px 0;
			font-size:$fontSize12;
			font-weight:normal;
			font-style:normal;
			font-stretch:normal;
			line-height:normal;
			letter-spacing:1.67px;
			color:$brownishGrey;
			text-transform:uppercase;
		}
	}
}