/* LAYOUT -- start */
$bodyBackgroundColor:#f9f9fb;
$ubikonBlue:#004bff;
$ubikonLightBlue:#b4c7e8;
$ubikonRed:red;
$ubikonOrange:#f63c2d;
$blackColor:#676767;
$lighterGray:#f9f9fb;
$lightGray:#ececec;
$mediumGray:#a7a7a7;
$projectItemBackgroundColor:#f9f9fb;
$projectDetailBoxBackgroundColor:#f9f9fb;
$inputBackgroundColor:#f9f9fb;
$tableOddRowBackgroundColor:#f9f9fb;
$paleGreyColor:#f9f9fb;
$greyishBrownColor:#3d3d3d;
$brownishGrey:#737373;
$brownGrey:#a9a9a9;
$paleGrey:#ececee;
$redOrange:#f64535;
$carolinaBlue:#7ea4ff;
$greenColor:#16a988;
/* LAYOUT -- end */

/* HEADER -- start */
$headerHeight:80px;
$headerBackgroundColor:white;
/* HEADER -- end */

/* FONT SIZES -- start */
$fontSize8:8px;
$fontSizeSmall:10px;
$fontSize11:11px;
$fontSize12:12px;
$fontSize13:13px;
$fontSizeMedium:14px;
$fontSize15:15px;
$fontSize16:16px;
$fontSize18:18px;
$fontSizeBig:18px;
$fontSize20:20px;
$fontSize22:22px;
$fontSize24:24px;
$fontSize32:32px;
$fontSize34:34px;
$fontSize36:36px;
/* FONT SIZES -- end */

/* FONT FAMILIES - start */
$fontFamilyIBMPlexSans:'IBM Plex Sans', sans-serif;
/* FONT FAMILIES - end */

$defaultPadding:10px;
$defaultMargin:10px;
$defaultBorder:solid 1px #ececee;
$defaultBorderRadius:0;
$defaultBackgroundColor:#ccc;
$borderRadiusRound:50%;
$borderRadiusSmall:10px;
$popinWidth:60%;
$popinBackgroundColor:rgba(25,93,254,0.9);
$marginTopBig:40px;
$alertColorRed:'red';
$alertColorGreen:#16a988;
$userAvatarWidth:35px;
$organizationLogoWidth:50px;

$projectPipelineCheckIncomplete:red;
$projectPipelineCheckComplete:#70f770;

$activityFeedColumn1:40%;
$activityFeedColumn2:15%;
$activityFeedColumn3:15%;
$activityFeedColumn4:15%;
$activityFeedColumn5:15%;

/* BREAKPOINTS */
$mobileMinWidth:0px;
$mobileMaxWidth:900px;