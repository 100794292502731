.FilterButton {
	position:relative;
	display:inline-block;
	margin:0 10px 0 0;

	&:hover {
		border-color:$blackColor;
	}

	&:last-child {
		margin-right:0;
	}

	&.is-selected {
		.FilterButton__button {
			color:$ubikonBlue;
		}
	}

	&.is-clicked {
		border-color:$blackColor;
	}

	.List__label {
		display:inline-block;
		width:100%;
		margin-bottom:7px;
	}

	.FilterButton__inner {
		display:inline-block;
		margin:0 20px 0 0;

		&:last-child {
			margin-right:0;
		}
	}

	.FilterButton__button {
		padding:10px 20px;
		border-style:solid;
		border-width:1px;
		border-color:$lightGray;
		border-radius:5px;
		outline:none;
		font-size:$fontSizeSmall;
		background-color:white;
		cursor:pointer;

		&--clicked {
			@extend .FilterButton__button;
			border-color:$blackColor;
		}

		&:clicked, &:focus {
			outline:none;
		}
	}

	.FilterButton__content {
		@include boxShadow(3px, 3px, 2px, 0.1);
		position:absolute;
		right:0;
		max-width:400px;
		min-width:300px;
		padding:20px;
		z-index:9;
		background-color:white;
		font-size:0;

		&.bedrooms {
			label {
				display:inline-block;
				width:100%;
				margin:0 0 10px 0;
			}
		}

		.Filter__input__wrapper {
			display:inline-block;
			width:50%;
			margin:0;

			label {
				color:$brownishGrey;
			}

			.TextInput {
				position:relative;
				width:90%;
				margin-top:7px;
			}
		}

		.Filter__button__submit {
			margin-top:10px;
		}

		.Select__value__box {
			position:relative;
			display:inline-block;
			width:30px;
			height:30px;
			margin:0 10px 0 0;
			padding:20px;
			border-style:solid;
			border-width:1px;
			border-radius:5px;
			border-color:$lightGray;
			text-align:center;
			color:$brownishGrey;
			cursor:pointer;

			&:last-child {
				margin-left:0;
			}

			&--selected, &:hover {
				@extend .Select__value__box;
				border-color:$ubikonBlue;
				color:$ubikonBlue;
			}

			.Select__value__box__label {
				position:absolute;
				top:50%;
				left:50%;
				transform:translate(-50%, -50%);
			}
		}
	}
}