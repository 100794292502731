.OfferDetail {
	padding:0 10px 30px 10px;

	.OfferDetail__header {
		display:inline-block;
		width:100%;
		vertical-align:middle;
		padding:0 0 20px 0;

		.ImageButton {
			display:inline-block;
			vertical-align:middle;
		}

		h2 {
			@include pageTitle();
			display:inline-block;
			vertical-align:middle;
			margin-left:20px;
			font-size:$fontSize24;
		}

		.Offer__date {
			display:inline-block;
			vertical-align:middle;
			margin-top:4px;
			margin-left:0;
			font-size:$fontSize13;
			color:$brownGrey;

			&:before {
				content:'–';
				color:$brownGrey;
				margin:0 10px;
			}
		}
	}

	.Offer__description {
		display:inline-block;
		width:100%;
		padding:30px 0 10px 0;
		font-size:$fontSizeMedium;
		font-stretch:normal;
		line-height:1.79;
		letter-spacing:normal;
		color:$brownishGrey;
	}

	.Offer__files {
		display:inline-block;
		vertical-align:middle;
		width:100%;
		margin-top:20px;

		.Offer__file__item {
			display:inline-block;
			vertical-align:middle;
			margin:0 5px 5px 0;
			padding:12px;
			background-color:$paleGreyColor;

			&:last-child {
				margin-right:0;
			}

			img {
				display:inline-block;
				vertical-align:middle;
			}

			span {
				display:inline-block;
				vertical-align:middle;
				margin-left:10px;
				font-size:$fontSize13;
				letter-spacing:0.5px;
				color:$greyishBrownColor;
			}
		}
	}

	.Offer__partners {
		display:inline-block;
		width:100%;
		margin-top:40px;

		.Offer__partner__item {
			display:inline-block;
			width:100%;
			margin:0 0 50px 0;

			&:last-child {
				margin-bottom:0;
			}

			.Offer__partner__header {
				display:inline-block;
				vertical-align:middle;
				margin-bottom:20px;

				h3 {
					display:inline-block;
					vertical-align:middle;
					margin:0 10px 0 0;
					font-size:$fontSizeMedium;
					letter-spacing:1.39px;
					color:$greyishBrownColor;
					text-transform:uppercase;
				}

				.count {
					display:inline-block;
					vertical-align:middle;
					font-size:$fontSize13;
					font-style:italic;
					letter-spacing: 1.29px;
					color:$brownGrey;

					&:before {
						content:'–';
						color:$brownGrey;
						margin:0 10px;
					}
				}
			}

			table.Offer__partner__quotes {
				width:100%;
				margin:0;
				border-collapse:collapse;
				text-align:left;

				.information {
					max-width:150px;
					font-size:$fontSize13;
					letter-spacing:0.5px;
					color:$brownishGrey;

					button {
						margin-left:10px;
					}
				}

				.Offer__quote__status {
					width:100%;
					text-align:center;

					&.denied {
						color:$redOrange;
					}

					&.approved {
						color:$ubikonBlue;
					}
				}

				thead {
					tr {
						th {
							border-style:solid;
							border-width:0 0 1px 0;
							border-color:$paleGrey;
							padding:8px 0;
							font-size:$fontSizeSmall;
							text-transform:uppercase;
							color:$brownishGrey;
						}
					}
				}

				tbody {
					tr {
						td {
							padding:10px 0;
							border-style:solid;
							border-width:0 0 1px 0;
							border-color:$paleGrey;
							font-size:$fontSizeMedium;

							.Offer__quote__avatar {
								@include avatar(40px);
								display:inline-block;
								vertical-align:middle;
								margin-right:12px;
							}

							.Offer__quote__user {
								display:inline-block;
								vertical-align:middle;
								width:calc(100% - 52px);

								.username, .organization {
									display:inline-block;
									width:100%;
								}

								.username {
									font-size:$fontSizeMedium;
									color:$greyishBrownColor;
								}

								.organization {
									font-size:$fontSize11;
									color:$brownishGrey;
								}
							}

							.Offer__quote__actions {
								button {
									width:calc(50% - 2.5px);

									&:first-child {
										margin-right:5px;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.Offer__quote__form {
		display:flex;
		width:100%;
		margin-top:80px;
		flex-wrap:wrap;
		justify-content:space-between;
	}

	.Offer__refused {
		width:100%;
		margin:50px 0 0 0;

		p {
			margin:0;
			padding:0;
			font-size:$fontSize18;
			color:$redOrange;
		}
	}
}