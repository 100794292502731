.UbikonForm {
	.UbikonForm__step {
		visibility:visible;

		&--hidden {
			visibility:hidden;
			height:0;
		}

		.UbikonForm__step__title {

		}

		.UbikonForm__step__buttons {
			width:100%;
			margin:10px 0;
		}
	}
}