.Message {
	display:flex;
	width:100%;
	padding:$defaultPadding;
	margin-bottom:$defaultMargin;
	align-items:center;

	&--left {
		@extend .Message;

		.Message__content {
			margin-left:2%;

			.Content {
				padding:9px 13px 13px 30px;
				border-radius:10px 10px 10px 0;
			}
		}
	}

	&--right {
		@extend .Message;
		text-align:right;

		.Message__User {
			display:none;
		}

		.Message__content {
			margin-right:2%;

			.Message__file {
				float:right;
			}

			.Content {
				float:right;
				padding:9px 30px 13px 13px;
				background-color:$ubikonBlue;
				color:white;
			}
		}
	}

	&.no-bottom {
		margin-top:0;
		margin-bottom:0;
		padding-top:0;
	}
}

.Message__User {
	width:50px;
	margin-top:-10px;

	.Message__User_avatar {
		@include avatar(50px);
	}
}

.Message__content {
	display:inline-block;
	width:100%;

	.Message__Created_at {
		margin:0;
		padding:0;
		color:#ccc;
	}

	.Message__file {
		display:inline-block;
		vertical-align:middle;
		width:60%;
		font-size:0;

		&:nth-child(n+2) {
			margin-top:10px;
		}

		.Message__file__actions {
			display:inline-block;
			vertical-align:middle;
			width:10%;
			text-align:center;
		}

		.Message__file__preview {
			display:inline-block;
			vertical-align:middle;
			width:90%;
			height:auto;
			min-height:130px;
			max-height:150px;
			border-radius:10px;
			background-position:center;
			background-size:cover;
			background-repeat:no-repeat;

			&:hover {
				box-shadow:0 4px 11px 0 rgba(0, 75, 255, 0.1);
				cursor:pointer;
			}

			&.is-not-image {
				width:75%;
				position:relative;
				min-height:70px;
				max-height:100px;
				background-color:$projectDetailBoxBackgroundColor;

				p {
					position:absolute;
					top:50%;
					left:50%;
					width:100%;
					padding:10%;
					transform:translate(-50%, -50%);
					font-size:$fontSize12;
					text-align:left;
				}
			}
		}
	}

	.Content {
		max-width:80%;
		min-width:45%;
		margin:0;
		padding:10px;
		border-radius:10px;
		background-color:$lighterGray;
		font-size:$fontSizeMedium;
		font-weight:normal;
		font-stretch:normal;
		font-style:normal;
		line-height:1.79;
		letter-spacing:0.5px;
		color:$brownishGrey;

		&.margin-top {
			margin-top:10px;
		}
	}

	.MessageBottom {
		display:inline-block;
		width:100%;
		font-size:$fontSize11;
		font-weight:normal;
		font-stretch:normal;
		font-style:italic;
		line-height:normal;
		letter-spacing:0.42px;
		color:$brownishGrey;

		p {
			margin:0;
			padding:0;

			span.Message__date {
				margin:0 0 0 20px;
			}
		}
	}
}