.Alert {
	font-weight:500;

	&--danger {
		@extend .Alert;
		color:$redOrange;
	}

	&--success {
		@extend .Alert;
		color:$greenColor;
	}
}