.LandDetail.CollectionDetail {
	.LandDetail__content {
		.Back__button {
			margin:100px 0 20px 0;
		}

		.LandDetail__wrapper {
			.LandDetail__infos {
				width:40%;

				&.is-empty {
					width:100%;
				}

				.all-projects__project__profiles {
					&.collection {
						.all-projects__project__profile {
							cursor:pointer;

							&:hover {
								border-style:solid;
								border-width:1px;
								border-color:$paleGrey;
							}
						}
					}
				}
			}

			.LandDetail__carousel {
				width:55%;

				.CollectionDetail__list {
					display:flex;
					flex-wrap:wrap;
					justify-content:space-between;
					width:100%;
					margin-top:30px;
				}
			}
		}
	}
}