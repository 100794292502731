.MapFilters {
	width:100%;
	padding:10px;
	border-style:solid;
	border-width:0 0 1px 0;
	border-color:$lightGray;

	h1.MapFilters__title {
		display:inline-block;
	}

	.SearchBar {
		width:35%;
		margin-left:20px;
	}

	.MapFilters__buttons {
		float:right;
	}
}