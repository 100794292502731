.ProjectsListTabs {
	width:calc(100% - 30px);
	margin:0 0 30px 0;
	padding:15px;

	.ProjectsListTabs__tabs {
		width:100%;
		margin:0;
		padding:0 0 20px 0;
		border-style:solid;
		border-width:0 0 1px 0;
		border-color:$lightGray;

		.ProjectsListTabs__tabs__tab {
			display:inline-block;
			margin:0 40px 0 0;

			&:last-child {
				margin-right:0;
			}
		}
	}
}