.AdminSection {
	margin:20px 0 0 0;
	padding:0 20px;
	border-radius:12px;
	background-color:$lighterGray;

	&:first-child {
		margin-top:0;
	}

	&.is-expanded, &:hover {
		box-shadow:0 4px 11px 0 rgba(0, 75, 255, 0.1);
	}

	&.is-expanded {
		.AdminSection__header {
			.AdminSection__right {
				.TextButton {
					color:$redOrange;
				}

				.ImageButton {
					transform:rotate(180deg);
				}
			}
		}

		.AdminSection__entities {
			max-height:1500px;
			overflow-y:scroll;
			overflow-x:hidden;
		}
	}

	.AdminSection__header {
		padding:20px;
		cursor:pointer;

		h3 {
			display:inline-block;
			color:$greyishBrownColor;
		}

		.AdminSection__right {
			span {
				position:relative;
				margin:0 40px 0 0;
				color:$ubikonRed;

				&:after {
					content:'';
					position:absolute;
					top:0;
					right:-10px;
					width:5px;
					height:5px;
					background-color:$ubikonRed;
				}
			}

			.ImageButton {
				position:relative;
				margin:0 0 0 10px;
			}
		}
	}

	.AdminSection__entities {
		max-height:0px;
		overflow:hidden;
		transition:all 0.8s ease;

		@include customScrollbar();

		.AdminSectionEntity {
			display:inline-block;
			width:100%;
			padding:30px;
			vertical-align:middle;
			border-style:solid;
			border-width:1px 0 0 0;
			border-color:$lightGray;
			font-size:0;

			.AdminSectionEntity__content {
				display:inline-block;
				width:45%;
				vertical-align:middle;
				font-size:$fontSizeMedium;
				letter-spacing:0.5px;
				line-height:20px;
				color:$greyishBrownColor;

				.AdminSectionEntity__description {
					margin:0 0 0 5px;
					font-style:italic;
					color:$mediumGray;

					&:before {
						content:'-';
						margin:0 5px 0 0;
					}
				}
			}

			.AdminSectionEntity__actions {
				display:inline-block;
				width:55%;
				vertical-align:middle;
				padding:0 20px 0 0;
				text-align:left;

				.CreatedBy {
					display:inline-block;
					vertical-align:middle;
					width:60%;
					padding-right:5%;

					.Avatar {
						@include avatar(30px);
						display:inline-block;
						vertical-align:middle;
						margin:0 5px 5px 0;
					}

					.Username {
						display:inline-block;
						vertical-align:middle;
						margin:0;
						font-size:$fontSize12;
						color:$blackColor;
					}
				}

				.TextButton {
					float:right;
					display:inline-block;
					vertical-align:middle;
					padding:5px 10px;
					border-style:solid;
					border-width:1px;
					border-color:$ubikonBlue;
					font-size:10px;
					color:$ubikonBlue;

					&:hover {
						background-color:$ubikonBlue;
						color:white;
					}
				}
			}
		}
	}
}