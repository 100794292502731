@import './reset.scss';
@import './easings.scss';
@import './variables.scss';
@import './base.scss';
@import './header.scss';
@import './sidebars.scss';

.catalogue {
    padding: 35px;

    background: $white;
}

.catalogue__header {
   display: flex;
   align-items: center;
   justify-content: space-between;

   padding-bottom: 15px;
   border-bottom: 1px solid $pale-lilac;
}

.catalogue__header__infos {
    display: flex;
    align-items: center;
}

.catalogue__header__title {
    padding-right: 30px;

    font-size: $text-xlarge;
}

.catalogue__header__search {
    position: relative;
}

.catalogue__header__search__input {
    position: relative;

    width: 600px;
    height: 50px;
    border: none;
    border-radius: 5px;
    padding-left: 15px;

    font-size: $text-large;

    background: $pale-grey;
}

.catalogue__header__search__input::placeholder {
    color: $brown-grey;
}

.catalogue__header__search__icon {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 50%;
    z-index: 2;

    padding: 15px;

    transform: translateY(-50%);
}

.catalogue__header__filters {
    position: relative;

    display: flex;
    align-items: center;
}

.catalogue__header__filter {
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 130px;
    height: 50px;
    margin-right: 10px;
    border-radius: 5px;
    border: 1px solid $pale-lilac;

    color: $brownish-grey;
    font-size: $text-medium;
}

.catalogue__header__filter.is-selected {
    color: $electric-blue;
}

.catalogue__header__filter.is-active {
    border-color: $greyish-brown;
}

.catalogue__header__filter--icon {
    cursor: pointer;

    margin-left: 25px;
}

.catalogue__header__filter--search__fields {
    z-index: 5;
    position: absolute;
    top: 70px;
    left: 0;

    padding: 20px;
    box-shadow: 0 0 11px 0 rgba(0, 75, 255, 0.1);

    transform: translateX(-30px);

    background: $white;
}

.catalogue__header__filter--search__fields .filters-modal__range {
    margin-bottom: 0px;
}
     
.catalogue__header__filter--search__fields .filters-modal__range__input {
    height: 40px;
}
.catalogue__header__filter--search__fields .filters-modal__range__input,
.catalogue__header__filter--search__fields .filters-modal__range__metric {
    color: $greyish-brown;
    font-size: $text-small;
    line-height: 40px;
}

.catalogue__wrapper {
    display: flex;
    justify-content: space-between;

    margin-top: 50px;
}

.catalogue__map, .catalogue__properties {
    width: calc(50% - 18px);
}

.catalogue__properties__title {
    display: flex;
    align-items: center;

    font-size: $title-medium;
}

.catalogue__properties__header {
    display: flex;
    justify-content: space-between;
}

.catalogue__properties__nav {
    display: flex;
    align-items: center;
}

.catalogue__properties__nav__arrow--left, .catalogue__properties__nav__arrow--right {
    padding: 10px;

}

.catalogue__properties__nav__arrow--left {
    cursor: pointer;

    margin-right: 15px;
}

.catalogue__properties__nav__page, .catalogue__properties__nav__page--more {
    cursor: pointer;

    padding: 10px;

    color: $brownish-grey;
    font-size: $text-small;
}

.catalogue__properties__nav__page.is-active {
    color: $electric-blue;
}

.catalogue__properties__nav__arrow--right {
    cursor: pointer;

    display: block;
    margin-left: 15px;

    transform: rotate(180deg);
}

.catalogue__properties__nav__all {
    cursor: pointer;

    display: flex;
    align-items: center;
    
    margin-left: 35px;

    color: $carolina-blue;
    font-size: $text-xsmall;
    text-transform: uppercase;
}

.catalogue__properties__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    margin-top: 30px;
}

.catalogue__property {
    position: relative;

    width: calc(50% - 10px);
    margin-bottom: 20px;
}

.catalogue__property__bg {
    position: relative;

    width: 100%;
    display: block;
    padding-top: 100%;

    background-color: $pale-grey;

    background-size: cover;
    background-position: 50% 50%;
}

.catalogue__property__content {
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 100%;
    height: 100%;
}

.catalogue__property__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.catalogue__property__price {
    padding: 15px;

    color: $white;
    font-size: $text-xlarge;
    text-transform: uppercase;

    background-color: $electric-blue;
}

.catalogue__property__like {
    padding: 20px;
}

.catalogue__property__like__icon {
    stroke: #737373;
    fill: none;
}

.catalogue__property__like.is-liked .catalogue__property__like__icon {
    stroke: none;
    fill: #F64535;
}

.catalogue__property__description {
    color: $greyish-brown;
}

.catalogue__property__description__wrapper {
    display: inline-block;
    width: 90%;

    padding: 20px 30px 30px 30px;
    background: $pale-grey;
}

.catalogue__property__title {
    margin-bottom: 15px;

    font-size: $title-xsmall;
}

.catalogue__property__infos {
    display: flex;
}

.catalogue__property__surface {
    margin-right: 20px;
}

.catalogue__property__location, .catalogue__property__surface {
    margin-left: 10px;

    color: $brownish-grey;
    font-size: $text-medium;
}

.catalogue__property__attributes {
    position: relative;

    display: inline-block;

    margin-top: 18px;

    font-size: $text-small;
}

.catalogue__property__attributes__list {
    position: relative;

    display: flex;
}

.catalogue__property__attributes__list:before, .catalogue__property__attributes__list:after {
    content: '';

    position: absolute;
    top: -8px;

    display: block;

    height: calc(100% + 16px);
    width: 1px;

    background: $pale-lilac;
}

.catalogue__property__attributes__list:before {
    left: 0;
}

.catalogue__property__attributes__list:after {
    right: 0;
}

.catalogue__property__attributes:before, .catalogue__property__attributes:after {
    content: '';

    position: absolute;
    left: -8px;

    display: block;

    width: calc(100% + 16px);
    height: 1px;

    background: $pale-lilac;
}

.catalogue__property__attributes:before {
    top: 0;
}

.catalogue__property__attributes:after {
    bottom: 0;
}

.catalogue__property__attribute {
    padding: 13px;
    border-right: 1px solid $pale-lilac;

    text-align: center;
}

.catalogue__property__attribute:last-child {
    border: none;
}

.catalogue__map {
    position: relative;

    background: $pale-lilac;
}

.catalogue__map__pin {
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 50%;

    width: 345px;
    border-radius: 15px;

    transform: translate3d(-50%, -50%, 0);
}

.catalogue__map__pin .catalogue__property__description__wrapper {
    width: 100%;
}

.catalogue__property__slider {
    overflow: hidden;
    position: relative;

    width: 100%;
    padding: 25%;

    background: $white;
}

$animation-duration: 0.8s;
$animation-easing: $ease-in-out-circ;

.catalogue__property__slide {
    overflow: hidden;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;

    width: 100%;
    height: 100%;

    transition: transform 0s $animation-duration;
    transform: translate3d(100%, 0, 0);
    will-change: transform;
}

.catalogue__property__slide__inner {
    position: relative;

    width: 100%;
    height: 100%;

    transition: transform 0s $animation-duration;
    transform: translate3d(-100%, 0, 0);
    will-change: transform;
}

.catalogue__property__slide__bg {
    position: relative;

    width: 100%;
    height: 100%;

    background-color: $white;

    background-size: cover;
    background-position: 50% 50%;

    transition: transform 0s $animation-duration;
    transform: scale(1.1) translate3d(10px, 0, 0);
    will-change: transform;
}

.catalogue__property__slide.is-active {
    z-index: 2;

    transition: transform $animation-duration $animation-easing;
    transform: translate3d(0, 0, 0);
}

.catalogue__property__slide.is-active .catalogue__property__slide__inner {
    transition: transform $animation-duration $animation-easing;
    transform: translate3d(0, 0, 0);
}

.catalogue__property__slide.is-active .catalogue__property__slide__bg {
    transition: transform 0.8s $animation-easing;
    transform: scale(1) translate3d(0, 0, 0);
}
// debug

.catalogue__property__slide:nth-child(3) .catalogue__property__slide__bg {

}

.catalogue__property__slide:nth-child(4) .catalogue__property__slide__bg {

}

.catalogue__property__slider__nav {
    z-index: 3;
    position: absolute;
    bottom: 10px;
    left: 50%;

    display: flex;

    transform: translateX(-50%);
}

.catalogue__property__slider__nav__dot {
    cursor: pointer;
    padding: 10px;

    opacity: 0.5;
}

.catalogue__property__slider__nav__dot.is-active {
    opacity: 1;
}

.catalogue__property__slider__nav__dot__inner {
    width: 6px;
    height: 6px;
    border-radius: 50%;

    background: $white;
}

.catalogue__property__description__price {
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;

    padding: 15px;

    color: $white;
    font-size: $text-xlarge;
    text-transform: uppercase;

    background-color: $electric-blue;
}

.catalogue__map .catalogue__property__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.filters-modal {
    z-index: 5;
    position: fixed;
    top: 0;
    left: 0;

    height: 100vh;
    width: 100vw;

    display: flex;
    align-items: center;
    justify-content: center;

    opacity: 0;
    visibility: hidden;
}

.filters-modal.is-active {
    opacity: 1;
    visibility: visible;
}

.filters-modal__bg {
    cursor: pointer;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;

    height: 100vh;
    width: 100vw;

    opacity: 0.9;

    background: $electric-blue;
}

.filters-modal__elem {
    z-index: 2;
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    width: 80vw;
    height: 80vh;
    border-radius: 18px;

    background: $white;
}

.filters-modal__close {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;

    padding: 40px;
}

.filters-modal__title {
    margin-top: 70px;

    font-size: $title-large;
}

.filters-modal__btn {
    margin-bottom: 70px;
}

.filters-modal__content {
    display: flex;
}

.filters-modal__ranges, .filters-modal__rooms {
    margin-right: 80px;
}

.filters-modal__range {
    display: flex;
    margin-bottom: 25px;
}

.filters-modal__range__title {
    margin-bottom: 10px;

    text-transform: uppercase;
    color: $brownish-grey;
    font-size: $text-small;
}

.filters-modal__range__elem__title {
    margin-bottom: 3px;

    text-transform: uppercase;
    color: $brownish-grey;
    font-size: $text-xsmall;
}

.filters-modal__range__input-ctn {
    position: relative;

    height: 50px;
    width: 150px;
}

.filters-modal__range__input {
    display: block;

    height: 100%;
    width: 100%;
    padding: 0px 40px 0px 15px;
    border: none;

    line-height: 50px;
    color: $greyish-brown;
    font-size: $text-large;
    text-align: right;

    background: $pale-grey;
}

.filters-modal__range__metric {
    position: absolute;
    top: 0;
    right: 15px;

    color: $greyish-brown;
    font-size: $text-large;
    line-height: 50px;
}

.filters-modal__range__elem {
    margin-left: 20px;
}

.filters-modal__range__elem:first-child {
    margin-left: 0px;
}

.filters-modal__apply {
    cursor: pointer;

    margin-top: 10px;

    font-size: $text-xsmall;
    color: $carolina-blue;
    text-transform: uppercase;
    text-align: right;
}

.filters-modal__rooms {
    padding-top: 13px;
}

.filters-modal__room__title {
    margin-bottom: 10px;

    text-transform: uppercase;
    color: $brownish-grey;
    font-size: $text-small;
}

.filters-modal__room {
    display: flex;

    margin-bottom: 38px;
}

.filters-modal__room:last-child {
    margin-bottom: 0px;
}

.filters-modal__room__elem {
    cursor: pointer;
    
    height: 50px;
    min-width: 50px;
    margin-right: 10px;
    padding: 0 15px;
    border: 1px solid $brown-grey;
    border-radius: 5px;
    
    font-size: $text-large;
    color: $brownish-grey;
    line-height: 50px;
    text-align: center;
}

.filters-modal__room__elem.is-active {
    border-color: $electric-blue;

    color: $electric-blue;
}

.filters-modal__needs__list {
    display: flex;
}

.filters-modal__needs__checkboxes {
    margin-right: 40px;
}

.filters-modal__needs__title {
    margin-bottom: 25px;

    text-transform: uppercase;
    color: $brownish-grey;
    font-size: $text-small;
    line-height: 1;
}

.filters-modal__needs__checkbox {
    display: block;

    margin-bottom: 20px;
}