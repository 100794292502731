.Header {
	position:relative;
	width:100%;
	height:$headerHeight;
	padding:$defaultPadding;
	border:$defaultBorder;
	border-radius:$defaultBorderRadius;
	background-color:$headerBackgroundColor;
	z-index:9;

	.Header__wrapper {
		position:absolute;
		top:50%;
		left:50%;
		transform:translate(-50%, -50%);
		width:95%;

		.Header__logo {
			display:inline-block;
			width:124px;

			&:hover {
				cursor:pointer;
			}

			.Logo {
				width:100%;
				height:auto;
			}
		}

		.Header__actions {
			display:inline-block;
			float:right;
			min-width:300px;
			font-size:0;

			.Header__actions__left {
				display:inline-block;
				width:80%;
				text-align:right;
				line-height:10px;
				vertical-align:top;

				p {
					margin:0;
					padding:0;
				}

				button {
					margin-top:10px;
				}
			}

			.Header__actions__right {
				display:inline-block;
				width:15%;
				margin-left:5%;
				vertical-align:top;
			}
		}
	}
}

.NotifierHeader {
	position:relative;
	display:inline-block;
	float:right;

	.Notifications__Dropdown {
		position:absolute;
		top:78px;
		right:0;
		width:40vw;
		min-height:120px;
		max-height:420px;
		background-color:white;
		border:$defaultBorder;
		border-radius:10px 0 10px 10px;
		z-index:10;
		overflow-y:scroll;

		&::-webkit-scrollbar {
			display:none;
		}

		h3 {
			display:inline-block;
			width:100%;
			margin:0 0 20px 0;
			padding:30px 0 10px 0;
			font-size:$fontSize22;
			text-align:center;
		}

		ul {
			width:100%;
			padding:0 5%;

			li {
				display:inline-block;
				width:100%;
				margin:0 0 20px 0;
				padding:20px;
				background-color:$paleGreyColor;
				cursor:pointer;

				&:hover {
					background-color:$paleGrey;
				}

				.Notification__title {
					display:inline-block;
					width:30%;
					font-size:$fontSizeMedium;
					text-transform:uppercase;
					color:$blackColor;

					&.unread {
						span {
							position:relative;

							&:after {
								content:'';
								position:absolute;
								top:-5px;
								right:-10px;
								width:6px;
								height:6px;
								background-color:$redOrange;
							}
						}
					}
				}

				.Notification__date {
					display:inline-block;
					width:30%;
					font-size:$fontSize12;
					font-style:italic;
					color:$brownGrey;
				}

				.Notification__user {
					display:inline-block;
					vertical-align:middle;
					width:40%;
					text-align:right;

					p {
						display:inline-block;
						vertical-align:middle;
						margin:0 15px 0 0;
						font-size:$fontSize12;
						color:$brownGrey;
					}

					.Notification__Avatar {
						@include avatar(30px);
						display:inline-block;
						vertical-align:middle;
					}
				}
			}
		}

		.Notifications__empty {
			width:100%;
			margin:40px 0 80px 0;
			text-align:center;
			opacity:0.4;
		}
	}
}

.Notifications__overlay {
	position:fixed;
	top:0;
	left:0;
	right:0;
	bottom:0;
	width:100%;
	height:100%;
	background-color:$popinBackgroundColor;
	overflow:hidden;
	z-index:3;
}