.ChatCenterWrapper {
	.Close__chat__arrow {
		display:none;
	}
}

@media only screen and (min-width: $mobileMinWidth) and (max-width: $mobileMaxWidth) {
	.Messenger {
		.messenger__tabs {
			display:flex;
			margin-top:20px;

			.messenger__tab {
				width:50%;
				padding-bottom:10px;
				border-style:solid;
				border-width:0 0 1px 0;
				border-color:$paleGrey;
				text-align:center;
				cursor:pointer;

				&.is-active {
					border-color:$greyishBrownColor;

					p {
						color:$ubikonBlue;
					}
				}

				p {
					font-size:$fontSize15;
					font-weight:normal;
					font-stretch:normal;
					font-style:normal;
					line-height:normal;
					letter-spacing:0.5px;
					color:$brownishGrey;
				}
			}
		}

		.messenger__list {
			.ConversationsList {
				margin-bottom:0;

				.chat__profiles {
					padding-top:12px;

					.profile {
						padding:0 12px 12px 12px;
						border-style:solid;
						border-width:0 0 1px 0;
						border-color:$paleGrey;

						&:first-child {
							padding-top:12px;
						}

						.profile__picture {
							width:40px;
							height:40px;
						}

						.profile__name {
							font-size:$fontSize16;
						}
					}
				}
			}
		}
	}

	.ChatCenterWrapper {
		.Close__chat {
			display:none;
		}

		.Close__chat__arrow {
			display:inline-block;
			width:calc(10% - 20px);
			margin-right:20px;
		}

		h1 {
			display:inline-block;
			width:30%;
			font-size:$fontSize18;
		}

		.Conversation__header {
			width:60%;
			border:none;

			.Conversation__users {
				.Conversation__users__count {
					display:none;
				}
			}

			.Conversation__actions {
				.Add__user__image__btn, .Add__user__text__btn {
					display:none;
				}

				.Actions__btn {
					margin-left:25px;
				}
			}

			.Conversation__header__content {
				&.expanded {
					width:85vw;
					left:-35vw;
					padding-left:12px;
					padding-right:12px;
				}

				.Conversations__users__list {
					.PartnersList__partners {
						margin-top:12px;

						.PartnersListItem  {
							width:100%;
							margin-left:0;
							margin-right:0;
							padding-top:12px;
							padding-bottom:12px;
						}
					}
				}
			}
		}

		.Conversation {
			height:70%;
			padding-top:12px;
			border-style:solid;
			border-width:1px 0 0 0;
			border-color:$paleGrey;
		}
	}
}