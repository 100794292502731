.App {
	width:100%;
	min-height:100%;
	padding:30px;

	.App__content {
		display:flex;
		width:100%;
		min-height:calc(100vh - 60px); // $headerHeight
		margin-top:20px;

		.App__content__center {
			// position:relative;
			// width:60%;
			// margin:0 20px;
			// padding:$defaultPadding;
			// border:$defaultBorder;
			// border-radius:$defaultBorderRadius;
			// background-color:white;

			&--large {
				@extend .App__content__center;
				width:calc(90% - 20px);
				margin:0 0 0 $defaultMargin;
			}

			&.no-margin {
				margin:0;
			}

			&.no-padding {
				padding:0;
			}

			&.no-border {
				border:none;
			}

			&.full-width {
				width:100%;
				height:calc(100vh - 40px);
			}
		}
	}
}