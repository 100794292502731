.mapboxgl-popup-content {
	min-width:300px;
	padding:0 !important;
	border-radius:15px;
	background-color:$paleGreyColor;
}

.MapPopup {
	position:relative;
	width:100%;

	&.is-pending {
		.MapPopup__top {
			opacity:0.4;
		}
	}

	.Close__btn {
		display:none;
		position:absolute;
		top:5px;
		left:5px;
	}

	.MapPopup__top {
		padding:20px 30px;

		h3 {
			margin-bottom:10px;
			font-size:$fontSize18;
			font-weight:normal;
			font-stretch:normal;
			font-style:normal;
			line-height:normal;
			letter-spacing:0.6px;
			color:$greyishBrownColor;
			cursor:pointer;
		}

		.catalogue__property__attributes {
			.catalogue__property__attributes__list {
				.catalogue__property__attribute {
					color:$greyishBrownColor;
				}
			}
		}

		.catalogue__property__like {
			position:absolute;
			top:0;
			right:0;
		}
	}

	.MapPopup__bottom {
		position:relative;
		width:100%;
		padding-bottom:50%;

		.MapPopup__bottom__wrapper {
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;

			.catalogue__property__price {
				position:absolute;
				top:0;
				left:0;
				z-index:2;
				padding:10px 15px;
			}

			.all-projects__project__type {
				position:absolute;
				top:0;
				left:0;
				padding:15px;
				background-color:#f64535;
				color:white;

				svg {
					path {
						stroke:white;
					}
				}
			}
		}
	}
}