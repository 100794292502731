.ListItem {
	position:relative;
	height:100%;
	margin:0;
	padding-bottom:100%;
	background-position:center;
	background-size:cover;
	background-repeat:no-repeat;
	overflow:hidden;

	&--large {
		@extend .ListItem;
		width:100%;
		margin:10px 0;

		.ListItem__thumb {
			width:40%;
		}

		.ListItem__bottom {
			width:60%;
		}
	}

	&--small {
		@extend .ListItem;
		width:50%;

		.ListItem__thumb {
			width:100%;
		}

		.ListItem__bottom {
			width:100%;
		}
	}

	.ListItem__thumb {
		position:absolute;
		top:0;
		left:0;
		height:100%;
	}

	.ListItem__price {
		position:absolute;
		top:0;
		left:0;
		padding:10px 15px;
		text-align:center;
		background-color:$ubikonBlue;
		z-index:2;

		h4 {
			font-size:$fontSizeMedium;
			color:white;
		}
	}

	.ListItem__status {
		position:absolute;
		top:50%;
		left:50%;
		transform:translate(-50%, -50%);
		text-transform:uppercase;

		&--pending {
			@extend .ListItem__status;
			color:red;
			font-weight:600;
		}
	}

	.ListItem__bottom {
		position:absolute;
		bottom:0;
		display:inline-block;
		width:80%;
		padding:30px 20px;
		vertical-align:top;
		background-color:$lighterGray;

		.ListItem__title {
			margin-top:0;
			font-size:$fontSize18;
			color:$greyishBrownColor;

			a {
				color:$greyishBrownColor;
				text-decoration:none;
			}
		}

		.ListItem__infos {
			display:inline-block;
			width:100%;
			margin:10px 0;
			font-size:$fontSize13;
			letter-spacing:0.5px;
			color:$brownishGrey;

			.LandInfosIcons {
				font-size:$fontSize13;
				letter-spacing:0.5px;
			}
		}

		.ListItem__rooms {
			width:100%;
			display:inline-block;
			vertical-align:middle;
			margin-top:10px;
			border-style:solid;
			border-width:1px;
			border-color:$paleGrey;

			.ListItem__room__item {
				display:inline-block;
				vertical-align:middle;
				margin:0;
				padding:13px 5px;
				border-style:solid;
				border-width:0 1px 0 0;
				border-color:$paleGrey;
				font-size:$fontSize12;
				letter-spacing:0.46px;
				color:$greyishBrownColor;
				text-align:center;

				&:last-child {
					border:none;
				}
			}
		}

		.ListItem__city {
			float:right;
			margin:0;
		}
	}
}