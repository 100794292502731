.ActivityFeed {
	height:calc(100vh - 60px - 80px - 51px - 90px - 25px);
	margin-top:0;
	padding-right:25px;
	overflow-y:scroll;
	overflow-x:hidden;

	@include customScrollbar();

	.Spinner {
		margin-left:13px;
	}

	.ActivityFeed__filters {
		width:100%;
	}

	.ActivityFeed__list {
		margin:40px 0;

		.ActivityFeed__list__header {
			width:100%;
			padding:20px;

			p {
				display:inline-block;
				margin:0;
				padding:0;
				font-size:$fontSizeSmall;
				color:$brownishGrey;
				text-transform:uppercase;

				&:nth-child(1) {
					width:$activityFeedColumn1;
				}
				&:nth-child(2) {
					width:$activityFeedColumn2;
				}
				&:nth-child(3) {
					width:$activityFeedColumn3;
					text-align:center;
				}
				&:nth-child(4) {
					width:$activityFeedColumn4;
				}
				&:nth-child(5) {
					width:$activityFeedColumn5;
					text-align:center;
				}
			}
		}

		.ActivityFeed__list__items {
			width:100%;

			.ActivityFeed__list__item {
				display:inline-block;
				vertical-align:middle;
				width:100%;
				margin:0 0 20px 0;
				padding:20px;
				background-color:$projectItemBackgroundColor;

				p {
					display:inline-block;
					vertical-align:middle;
					font-size:$fontSizeMedium;

					&:nth-child(1) {
						width:$activityFeedColumn1;
						text-transform:uppercase;
						color:$greyishBrownColor;
					}
					&:nth-child(2) {
						width:$activityFeedColumn2;
						font-size:$fontSize12;
						font-style:italic;
						color:$brownGrey;
					}
					&:nth-child(3) {
						width:$activityFeedColumn3;
						font-size:$fontSize12;
						color:$redOrange;
						text-align:center;
					}
				}

				.Activity__details {
					display:inline-block;
					width:100%;
					margin-top:5px;
					font-size:$fontSize12;
					font-style:italic;
					color:$brownGrey;
					text-transform:none;
				}

				.Activity__users {
					display:inline-block;
					vertical-align:middle;
					width:$activityFeedColumn4;

					.Activity__user {
						@include avatar(20px);
						display:inline-block;
						margin-right:5px;

						&:last-child {
							margin-right:5;
						}
					}
				}

				.Activity__actions {
					display:inline-block;
					vertical-align:middle;
					width:$activityFeedColumn5;
					text-align:center;

					button {
						width:100%;
					}
				}
			}
		}
	}
}