.RequestForm {
	
}

.RequestFormPopin {
	position:relative;

	&.is-loading, &.is-finished {
		padding:50px 0;
	}

	.RequestFormPopin__choices {
		h2 {
			width:100%;
			text-align:center;
		}

		.RequestFormPopin__choices__buttons {
			width:100%;
			margin:40px 0 60px 0;
			text-align:center;

			.ProjectRequestType__button {
				margin:0 20px 0 0;
				padding:15px 20px;
				border-style:solid;
				border-width:1px;
				border-color:$mediumGray;
				font-size:$fontSizeMedium;
				text-transform:uppercase;

				&:last-child {
					margin-right:0;
				}

				&:hover {
					border-color:$ubikonBlue;
					color:$ubikonBlue;
				}
			}
		}

		.ProjectRequestType__submit {
			width:100%;
			margin:0 auto;
			text-align:center;

			.SubmitButton {

			}
		}
	}

	.filters-modal__needs__list {
		display:inline-block;
		width:100%;
		margin-top:5px;

		.filters-modal__needs__checkboxes {
			display:inline-block;
			width:100%;

			.filters-modal__needs__checkbox {
				display:inline-block;
				width:33%;
			}
		}
	}

	.LandFormTheme {
		.LandFormTheme__left {
			display:inline-block;
			vertical-align:top;
			width:45%;
			margin-right:5%;
		}

		.LandFormTheme__right {
			display:inline-block;
			vertical-align:top;
			width:50%;

			.address {
				.LandForm__row {
					display:inline-block;
					vertical-align:top;

					&:first-child {
						width:100%;
					}

					&:nth-child(2) {
						width:48%;
						margin-right:2%;
					}

					&:nth-child(3) {
						width:48%;
						margin-left:2%;
					}
				}
			}
		}

		.FormRow {
			label.Label {
				display:inline-block;
				width:100%;
				margin-bottom:5px;
				font-size:$fontSizeSmall;
				font-weight:normal;
				font-stretch:normal;
				font-style:normal;
				line-height:normal;
				letter-spacing:1.39px;
				color:$brownishGrey;
				text-transform:uppercase;
			}

			&.description {
				textarea {
					min-height:220px;
					resize:none;
					overflow-y:scroll;
					overflow-x:hidden;

					@include customScrollbar();
				}
			}

			.FileInput {
				label.Label {
					vertical-align:top;
					width:25%;
				}

				.FileInput__preview {
					vertical-align:top;
					width:75%;
					margin:0;
					padding-left:5%;

					.FileInputPreview__item {
						width:auto;
						height:55px;

						img {
							width:auto;
							height:100%;
						}
					}
				}
			}
		}
	}
}