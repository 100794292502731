.BenefitsList {
	display:inline-block;
	width:100%;
	margin:25px 0 0 0;

	h3 {
		margin:0 0 20px 0;
		font-size:$fontSize13;
		font-weight:normal;
		font-stretch:normal;
		font-style:normal;
		line-height:normal;
		letter-spacing:1.39px;
		color:$brownishGrey;
		text-transform:uppercase;
	}

	.filters-modal__needs__list {
		display:inline-block;
		vertical-align:top;
		width:100%;

		.filters-modal__needs__checkboxes {
			display:inline-block;
			vertical-align:top;
			width:100%;

			.filters-modal__needs__checkbox {
				display:inline-block;
				width:auto;
				margin:0 25px 15px 0;

				&:hover {
					cursor:default !important;
				}
			}
		}
	}
}