.Login {
	position:relative;
	width:100%;
	height:calc(100vh - 60px - 40px - 60px);
	background-image:url('assets/png/login-background.png');
	background-repeat:no-repeat;
	background-size:cover;
	background-position:center;

	&.is-intro {
		height:calc(100vh - 60px);
	}

	.Login__center {
		height:100%;
		max-width:60%;
		margin:0 auto;
		background-color:white;
		overflow-y:scroll;
		overflow-x:hidden;
		animation-name:loginCenter;
		animation-duration:0.6s;
		animation-iteration-count:1;

		&::-webkit-scrollbar {
			display:none;
		}

		.Login__tabs {
			width:70%;
			margin:0 auto;
			padding:20px 10px;
			border-style:solid;
			border-width:0 0 1px 0;
			border-color:$lightGray;
			text-align:center;

			button.Login__tab__button {
				padding:0 40px;
				border:none;
				background-color:transparent;
				color:$lightGray;
				font-size:$fontSize32;
				cursor:pointer;
				outline:none;

				&--active {
					@extend button.Login__tab__button;
					color:$ubikonBlue;
				}
			}
		}

		.Login__tab__content {
			width:50%;
			margin:50px auto 0 auto;

			.Login__form, .Login__signup_form {
				text-align:center;

				.Alert {
					display:inline-block;
					width:100%;
					margin-top:20px;
					text-align:center;
				}

				.User__created {
					margin:0 0 25px 0;
					line-height:25px;
					color:$alertColorGreen;
				}

				.FormRow {
					&.username, &.email, &.password {
						margin-bottom:0;
					}

					&.help {
						text-align:left;

						.Terms__wrapper {
							display:inline-block;
							vertical-align:middle;
							width:auto;
							margin-top:-5px;

							.optin {
								display:inline-block;
								vertical-align:middle;
								width:auto;
								margin:0 10px 0 0;
								padding:0;
								font-size:$fontSize11;
								color:$brownGrey;
							}

							.TextButton {
								margin-top:5px;
							}
						}

						.TextButton {
							color:$brownGrey;

							&:last-child {
								float:right;
							}

							&:hover {
								color:$brownishGrey;
							}
						}
					}

					input[type="text"], input[type="password"], input[type="email"] {
						padding:25px 30px;
						border-radius:5px;
					}

					input[type="text"] {
						position:relative;
					}
				}
			}

			.SubmitButton {
				margin-top:50px;
				padding:15px 25px;
				text-transform:uppercase;

				&--loading {
					margin-top:50px;
					padding:15px 25px 15px 40px;
				}
			}

			.Login__help {
				font-size:$fontSizeSmall;
				color:$brownGrey;

				&.signup {
					margin-top:15px;
					text-align:center;
				}

				&:hover {
					color:$brownishGrey;
				}
			}
		}
	}
}

.Forgotten__password__success {
	width:100%;
	padding:25px 0 0 0;
	text-align:center;

	button {
		margin-top:50px;
	}
}

.Help__popin__content {
	margin-top:20px;
	line-height:24px;
}