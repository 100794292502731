.AdminIndex {
	display:inline-block;
	width:calc(100% - 1px);
	height:calc(100vh - 60px - 80px - 20px);
	padding:30px;
	overflow-y:scroll;
	overflow-x:hidden;
	background-color:white;

	@include customScrollbar();
}