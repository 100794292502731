.ProjectFiles {
	h3.ProjectFiles__title {
		color:$mediumGray;
	}

	.Files__header {
		display:flex;
		flex-direction:row-reverse;
		width:100%;
		margin:0 0 20px 0;
	}

	table.Files__List {
		width:100%;
		margin:20px 0 0 0;
		border-spacing:unset;

		thead {
			tr {
				th {
					color:$mediumGray;
					font-size:$fontSizeSmall;
					font-weight:400;
					text-transform:uppercase;
					text-align:left;
				}
			}
		}

		tbody {
			tr {
				&:nth-child(odd) {
					border-style:solid;
					border-width:1px 0;
					border-color:$lightGray;
					background-color:$tableOddRowBackgroundColor;
				}

				td {
					font-size:$fontSizeMedium;

					&.avatar {
						.PartnersList {
							.PartnersList__partners {
								div {
									.PartnersListItem__avatar {
										@include avatar(30px);
									}

									.PartnersListItem__username {
										.username {
											font-size:$fontSizeMedium;
										}
									}
								}
							}
						}
					}
				}
			}
		}

		.Files__List__item {

		}
	}
}