.Intro {
	position:relative;
	width:100%;
	height:100%;
	background-image:url('assets/png/login-background.png');
	background-repeat:no-repeat;
	background-size:cover;
	background-position:center;

	.Intro__logo {
		position:absolute;
		top:50%;
		left:50%;
		transform:translate(-50%, -50%);
		width:100%;
		height:80px;
		text-align:center;
		background-color:white;
		transition:all 1s ease;

		&.up {
			top:40px;
		}

		img {
			position:absolute;
			top:50%;
			left:50%;
			transform:translate(-50%, -50%);
			transition:all 1s ease;

			&.left {
				left:85px;
			}
		}
	}
}